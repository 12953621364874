/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Container,
  FormLabel,
  forwardRef,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { motion, useAnimation } from 'framer-motion'
import React, {
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { AiFillSave, AiOutlineDelete } from 'react-icons/ai'
import api from '../../Services/api'
import { Document, Page, pdfjs } from 'react-pdf'
import { RiEye2Fill } from 'react-icons/ri'
import { AlertDialogCustom } from '../AlertDialog'

import { Loader } from '../../Components/Loader'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const first = {
  rest: {
    rotate: '-15deg',
    scale: 0.95,
    x: '-50%',
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    x: '-70%',
    scale: 1.1,
    rotate: '-20deg',
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut'
    }
  }
}

const second = {
  rest: {
    rotate: '15deg',
    scale: 0.95,
    x: '50%',
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    x: '70%',
    scale: 1.1,
    rotate: '20deg',
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut'
    }
  }
}

const third = {
  rest: {
    scale: 1.1,
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    scale: 1.3,
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut'
    }
  }
}

const PreviewImage = forwardRef<BoxProps, typeof Box>((props, ref) => {
  return (
    <Box
      bg="white"
      top="0"
      height="100%"
      width="100%"
      position="absolute"
      borderWidth="1px"
      borderStyle="solid"
      rounded="sm"
      borderColor="gray.400"
      as={motion.div}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundImage={'url("https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg")'}

      {...props}
      ref={ref}
    />
  )
})

interface Props {
  label?: string
  defaultCover?: string
  containerStyle?: any
  payload?: any
  id?: string | null
  saveFunction?: (data?: any) => any
  extraCleanFunction?: (data?: any) => any
}

export interface DragAndDropExportedProps {
  execute: (uri: string, cb?: any) => void
  selectedFile: any

}

const DragAndDropBase: ForwardRefRenderFunction<
DragAndDropExportedProps,
Props
> = ({ label, defaultCover, containerStyle, payload, id, saveFunction, extraCleanFunction }, ref) => {
  const controls = useAnimation()
  const startAnimation = async () => await controls.start('hover')
  const stopAnimation = () => controls.stop()
  const toast = useToast()
  const [file, setFile] = useState()
  const [thumb, setThumb] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState<string | undefined>(undefined)
  const [numPages, setNumPages] = useState(null)
  const [currentCover, setCurrentCover] = useState('')

  const [pdfFile, setPdfFile] = useState<any>(null)
  const [previewExtension, setPreviewExtension] = useState('')
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPercentage, setLoadingPercentage] = useState(0)

  const execute = useCallback(
    async (uri: string, cb?: any) => {
      try {
        if (!file) {
          cb()
          return
        }

        setLoading(true)

        const data = new FormData()
        data.append('file', file)

        if (payload) {
          Object.entries(payload).map((item: any) => {
            data.append(item[0], item[1])
          })
        }

        const response = await api.post(uri, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setLoadingPercentage(percentCompleted)
            console.log('percentCompleted: ', percentCompleted)
          }
        })

        console.log('response upload file: ', response)

        if (cb) {
          cb()
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'red'
        })
      } finally {
        setLoading(false)
      }
    },
    [file, payload, toast]
  )

  const handleChangeInput = useCallback(async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    const file = e.target.files[0]

    if (file && file.type === 'application/pdf') {
      try {
        // Convert the PDF to an image

        setPdfFile(file)
        setSelectedFile(undefined)
        setFile(file)
      } catch (error) {
        console.error('Error converting PDF to image:', error)
      }
    } else {
      setPdfFile(null)
      setFile(file)
      setSelectedFile(file)
    }

    // setThumb(e.target.value);
  }, [])

  useEffect(() => {
    if (!selectedFile && !currentCover) {
      setPreview(undefined)
      return
    }

    if (currentCover) {
      const lastDotIndex = currentCover.lastIndexOf('.')
      const extension = currentCover.substring(lastDotIndex + 1)

      setPreviewExtension(extension)

      if (extension === 'pdf') {
        setPdfFile(currentCover)
      }
    }

    console.log('selectedFile: ', selectedFile)

    const objectUrl = selectedFile
      ? URL.createObjectURL(selectedFile)
      : defaultCover

    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, defaultCover, currentCover])

  useImperativeHandle(ref, () => {
    return {
      execute,
      selectedFile: selectedFile ?? pdfFile
    }
  })

  const handleClean = useCallback(async () => {
    setSelectedFile(undefined)
    setPreview(undefined)
    setPdfFile(null)
    setShowModalConfirmation(false)
    setPreviewExtension('')
    setCurrentCover('')
    if (id) {
      // remover arquivo do banco
      console.log('deletar file banco: ', id)
      try {
        await api.delete(`/files/${id}`)

        if (extraCleanFunction) {
          extraCleanFunction()
        }
      } catch (error) {
        toast({
          title: error?.response?.data?.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'red'
        })
      }
    }
  }, [id, toast, extraCleanFunction])

  useEffect(() => {
    if (defaultCover) {
      setCurrentCover(defaultCover)
    }
  }, [defaultCover])

  return (
    <Container style={containerStyle}>
      {!!label && <FormLabel>{label}</FormLabel>}
      {loading && <Loader percentage={loadingPercentage} />}
      {showModalConfirmation && (
        <AlertDialogCustom
          title="Remover Arquivo"
          isOpen={showModalConfirmation}
          cancelFunction={() => setShowModalConfirmation(false)}
          nextFunction={handleClean}
        />
      )}

      {pdfFile && (
        <>
          <div style={{ overflow: 'hidden', width: 200, height: 200 }}><Document file={pdfFile}>
            <Page pageNumber={1} height={200} width={200} />
          </Document>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
        {currentCover && (
          <Button
          href={`${currentCover}`}
          target="_blank"
            as="a"
            size="sm"
            fontSize="sm"
          mr="2"
            bg="wine.200"
            colorScheme="wine"
            mt="5"
            width={150}
            leftIcon={
              <Icon as={RiEye2Fill} fontSize="16" />
            }
          >
          Visualizar
        </Button>
        )}

          <Button
            as="button"
            onClick={() => setShowModalConfirmation(true)}
            size="sm"
            fontSize="sm"
            colorScheme="red"
            mt="5"
            width={150}
            leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}
            mr={2}
          >
            Excluir
          </Button>

          {saveFunction && <Button
            as="button"
            onClick={saveFunction}
            size="sm"
            fontSize="sm"
            colorScheme="blue"
            mt="5"
            width={150}
            leftIcon={<Icon as={AiFillSave} fontSize="16" />}

          >
            Salvar
          </Button>}
          </div>
        </>
      )
      }

      {preview && previewExtension !== 'pdf' &&
        (
          <>

            <img src={preview} style={{ width: 200 }} />

            {currentCover && (
              <Button
                href={`${currentCover}`}
                target="_blank"
                as="a"
                size="sm"
                fontSize="sm"
                mr="2"
                bg="wine.200"
                colorScheme="wine"
                mt="5"
                width={150}
                leftIcon={
                  <Icon as={RiEye2Fill} fontSize="16" />
                }
              >
              Visualizar
              </Button>
            )}

            <Button
              as="button"
              onClick={() => setShowModalConfirmation(true)}
              size="sm"
              fontSize="sm"
              colorScheme="red"
              mt="5"
              width={150}
              leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}
              mr={2}
            >
              Excluir
            </Button>

            {saveFunction && <Button
            as="button"
            onClick={saveFunction}
            size="sm"
            fontSize="sm"
            colorScheme="blue"
            mt="5"
            width={150}
            leftIcon={<Icon as={AiFillSave} fontSize="16" />}

          >
            Salvar
          </Button>}
          </>
        )}

      {!preview && !pdfFile && (
        <AspectRatio width="64" ratio={1}>
         <Box
           borderColor="gray.300"
           borderStyle="dashed"
           borderWidth="2px"
           rounded="md"
           shadow="sm"
           role="group"
           transition="all 150ms ease-in-out"
           _hover={{
             shadow: 'md'
           }}
           as={motion.div}
           initial="rest"
           animate="rest"
           whileHover="hover"
           cursor="pointer"
         >
           <Box
             position="relative"
             height="100%"
             width="100%"
             cursor="pointer"
           >
             <Box
               position="absolute"
               top="0"
               left="0"
               height="100%"
               width="100%"
               display="flex"
               flexDirection="column"
             >
               <Stack
                 height="100%"
                 width="100%"
                 display="flex"
                 alignItems="center"
                 justify="center"
                 spacing="4"
               >
                 <Box height="16" width="12" position="relative">
                   <PreviewImage
                     variants={first}
                     backgroundImage="url('https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg')"
                   />
                   <PreviewImage
                     variants={second}
                     backgroundImage="url('https://images.unsplash.com/photo-1565299585323-38d6b0865b47?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2628&q=80')"
                   />
                   <PreviewImage
                     variants={third}
                     backgroundImage={'url("https://images.unsplash.com/photo-1563612116625-3012372fccce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2480&q=80")'}
                   />
                 </Box>
                 <Stack p="8" textAlign="center" spacing="1">
                   <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                     Arraste as imagens
                   </Heading>
                   <Text fontWeight="light">ou clique para carregar</Text>
                 </Stack>
               </Stack>
             </Box>
             <Input
               type="file"
               height="100%"
               width="100%"
               position="absolute"
               top="0"
               left="0"
               opacity="0"
               aria-hidden="true"
               // accept="image/*"
               onDragEnter={startAnimation}
               onDragLeave={stopAnimation}
               onChange={async (e) => await handleChangeInput(e)}
             />
           </Box>
         </Box>
        </AspectRatio>
      )}

    </Container>
  )
}

export const DragAndDrop = forwardRef(DragAndDropBase)
