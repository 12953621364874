import { ElementType } from "react"
import { Box, Icon, Text } from "@chakra-ui/react"

interface Props {
    title: string;
    description: string;
    value: string;
    icon: ElementType;
    iconStyle?: object;
    containerStyle?: object;
    titleStyle?: object;
    descriptionStyle?: object;
    valueStyle?: object;
}

const TilesBox = ({ title, value, icon, description, iconStyle, containerStyle, titleStyle, descriptionStyle, valueStyle }: Props) => (
    <Box p={["6","8"]} bg="white" borderRadius={8} display="flex" flexDirection="column" justifyContent="center" style={containerStyle}>
        <Icon as={icon} fontSize="50" color="gray.800" {...iconStyle} />
        <Text color="gray.500" fontSize="20px" mt="10px" style={titleStyle}>{title}</Text>
        <Text color="gray.500" fontSize="15px" mt="5px" mb="10px" style={descriptionStyle}>{description}</Text>
        <Text color="blue.500" fontSize="40px" fontWeight="bold" style={valueStyle}>{value}</Text>
    </Box>
)

export { TilesBox }