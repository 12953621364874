import React, { FC } from 'react'
import { AccordionMultiPayments } from '../AccordionMultiPayments'
import IPaymentProps from '../../Pages/BillsToPay/Interfaces/ProcessIntefaces'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

interface Props {
  sharedPaymentId: string
  data?: IPaymentProps[]
  refreshFunction?: () => Promise<void>
  onClose: () => void
}

const ModalMultiPayments: FC<Props> = ({ data, refreshFunction, sharedPaymentId, onClose }) => {
  return (
    <Modal isOpen size="6xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pagamento Compartilhado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <AccordionMultiPayments data={data} refreshFunction={refreshFunction} sharedPaymentId={sharedPaymentId}/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Fechar
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

  )
}

export { ModalMultiPayments }
