/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast
} from '@chakra-ui/react'
import queryString from 'query-string'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddLine, RiAlertLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { Document, Page, pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Can } from '../../Components/Can'
import { Checkbox } from '../../Components/Form/Checkbox'
import { Input } from '../../Components/Form/Input'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { Header } from '../../Components/Header'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { RefusePreProposalModal } from '../../Components/RefusePreProposalModal'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { NOTIFICATIONS_TYPE, PROPOSAL_STATUS } from '../../Services/contants'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { FormatMoney } from '../../utils/formatMoney'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { getProposalStatus } from '../../utils/getProposalStatus'
import { OptionsProps } from '../PreProposal/create'
import { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { TextArea } from '../../Components/Form/TextArea'
import IImmobileProps from '../Unities'
import { format } from 'date-fns'
import { useAuth } from '../../Hooks/Auth'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ItemClausesProps {
  id: string
  resume: string
  clause: string
}

interface ItemProposalProps {
  id: string
  installments: string | number
  ticket_value: string
  expenses_value: string
  company_value: string
  executor_value: string
  executor_value_formatted?: string
  ticket_value_formatted?: string
  expenses_value_formatted?: string
  totalValues?: string
  service: {
    id: string
    name: string
  }
  executor: {
    id: string
    name: string
  }
  obs?: string
  release_date?: string
}

export interface ProposalProps {
  id: string
  number: string
  status: string
  created_at: string
  company: string
  contact: {
    id: string
    name: string
    department: string
  }
  technical: {
    id: string
    name: string
  }
  financial: {
    id: string
    name: string
  }
  net: {
    id: string
    name: string
  }
  unit: {
    id?: string
    name?: string
    name_approvation?: string
  }
  immobile?: IImmobileProps
  items: ItemProposalProps[]
  clauses: ItemClausesProps[]
  boss_department_approval?: string
  collaborator_department: string
}

interface ItemClausesProps {
  id_control: string
  id: string
  resume: string
  clause: string
}

// let controlRender = false

const ProposalCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [proposalData, setProposalData] = useState<ProposalProps>({} as ProposalProps)
  const [bdiColors, setBdiColors] = useState<any>({} as any)
  const params = useParams()
  const toast = useToast()
  const [totalProposal, setTotal] = useState('R$ 0,00')
  const navigation = useNavigate()
  const [showModalProcess, setShowModalProcess] = useState(false)
  const [allServicesCheckedToProcess, setServicesAllCheckedToProcess] = useState<string[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const parsed = queryString.parse(location.search)
  const [itemsClauses, setItemsClauses] = useState<ItemClausesProps[]>([])
  const { user } = useAuth()
  const { register, handleSubmit, formState, setValue, getValues, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  // const [selectedApprovement, setSelectedApprovement] = useState(false)

  const [refuseApprovement, setRefuseApprovement] = useState(false)
  const [allClauses, setAllClauses] = useState<Array<Omit<ItemClausesProps, 'id_control'>>>([])
  const [clausesOptions, setClausesOptions] = useState<OptionsProps[]>([])
  const [files, setFiles] = useState<FilesProps[]>()
  const [fileProposal, setFileProposal] = useState({} as FilesProps)
  const [initialCoverProposalProviderAttachment, setInitialCoverProposalProviderAttachment] = useState<FilesProps>({} as FilesProps)

  const handleDeleteClauseOption = useCallback((id_control: string) => {
    const newArray = itemsClauses.filter(i => i.id_control !== id_control)
    unregister(`clause-${id_control}`)
    setItemsClauses([...newArray])
  }, [itemsClauses, unregister])

  const CalculateTotal = useCallback(() => {
    const values = getValues()

    const dados: any[] = FindKeyWithPrefix(values, 'name-item-total_value')

    const total = dados.reduce((acc, curr) => {
      if (!curr.value) return acc

      const currentValue = getCurrencyValue(curr.value)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return acc += Number(currentValue)
    }, 0)

    const formattedTotal = FormatMoney(total)
    console.log('formattedTotal: ', formattedTotal)

    setTotal(formattedTotal)
  }, [getValues])

  const CalculateBDI = useCallback((proposal_item: ItemProposalProps, value: string) => {
    const totalValue = getCurrencyValue(value)
    const executorValue = Number(proposal_item.executor_value)
    const expensesValue = Number(proposal_item.expenses_value)
    const ticketValue = Number(proposal_item.ticket_value)

    const serviceTotalValue = executorValue + expensesValue + ticketValue

    const bdi = (((totalValue * 0.83) - serviceTotalValue) / totalValue) * 100

    let bdiResult: any

    if (bdi >= 40) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - OK`,
        color: '#90c904'
      }
    } else if (bdi >= 30) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - BOM`,
        color: '#ffff00'
      }
    } else if (bdi >= 20) {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - RUIM`,
        color: '#D3000'
      }
    } else {
      bdiResult = {
        value: `(${bdi.toFixed(0)}%) - MUITO RUIM`,
        color: '#FF2400'
      }
    }

    setValue(`name-item-bdi-${proposal_item.id}`, bdiResult.value)

    setBdiColors((prev: any) => {
      const key = `name-item-bdi-${proposal_item.id}`
      const payload = {
        ...prev
      }

      payload[key] = bdiResult.color

      return payload
    })

    CalculateTotal()
  }, [setValue, CalculateTotal])

  const handleNewClause = useCallback((initialValues: any) => {
    const payload = {
      id_control: uuid(),
      id: initialValues?.id || '',
      resume: initialValues?.resume || '',
      clause: initialValues?.resume || ''
    }
    setItemsClauses(prev => {
      const current = [...prev, payload].filter(item => item)

      const ids = new Set() // Conjunto para armazenar IDs únicos
      return current.filter((item) => {
        // Se o ID ainda não estiver no conjunto, adiciona e mantém o item
        if (!ids.has(item.id)) {
          ids.add(item.id)
          return true
        }
        // Se o ID já estiver no conjunto, descarta o item
        return false
      })
    })
  }, [])

  const loadProposal = useCallback(async (id: string) => {
    // if (controlRender) return
    // controlRender = true
    try {
      setLoading(true)
      const { data } = await api.get(`/proposal/${id}`)

      const findProposalProviderAttachment = data?.files?.find((i: FilesProps) => i.type === 'proposta-prestador')

      if (findProposalProviderAttachment) {
        setInitialCoverProposalProviderAttachment(findProposalProviderAttachment)
      }

      const normalizeData = {
        ...data,
        items: data?.items?.map((i: any) => {
          return {
            ...i,
            release_date: i?.release_date ? format(new Date(i?.release_date), 'dd/MM/yyyy') : 'Não informado'
          }
        })
      }

      setProposalData(normalizeData)
      const findFile = data?.files?.find((i: FilesProps) => i.type === 'proposta-pdf')

      if (findFile) {
        setFileProposal(findFile)
      }

      data?.items?.forEach((i: any) => {
        if (!i.company_value) return

        CalculateBDI(i, i.company_value)
      })

      console.log('load proposal: ', data)

      data?.clauses?.map((i: any) => {
        handleNewClause(i)
      })
      setFiles(data.files)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, CalculateBDI, handleNewClause])

  const handleCreatePDF = useCallback(async () => {
    try {
      setLoading(true)
      await api.post(`/proposal/${params?.id}`)

      toast({
        title: 'Proposta em PDF gerada.',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })

      // controlRender = false

      if (params?.id) {
        loadProposal(params.id)
      }
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [params, toast, loadProposal])

  const loadClauses = useCallback(async () => {
    try {
      const { data } = await api.get('/clauses', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.clause
        }
      })

      setAllClauses(data?.data)
      setClausesOptions(normalize)
      console.log('carregou clauses')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      try {
        const dados = FindKeyWithPrefix(values, 'name-item-total_value')
        console.log('dados: ', dados)
        const isValid = dados.every((i: any) => i.value)
        console.log('isValid: ', isValid)
        if (!isValid) {
          toast({
            title: 'Preencha todos os valores',
            position: 'top',
            isClosable: true,
            status: 'warning',
            variant: 'solid'
          })

          return
        }

        const items = dados.map((i: any) => {
          const getId = i.key.split('name-item-total_value-')
          return {
            item_id: getId[1],
            company_value: getCurrencyValue(i.value)
          }
        })

        const payload = {
          status: 1,
          items,
          clauses: itemsClauses.map(i => i.id)
        }
        console.log('payload: ', payload)

        const { id } = params

        await api.patch(`/proposal/${id}`, payload)

        toast({
          title: 'Alterado com sucesso',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        // controlRender = false

        if (id) {
          loadProposal(id)
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, loadProposal, itemsClauses]
  )

  const updateNotificationStatus = useCallback(async (notification_id: string) => {
    try {
      await api.patch(`/notifications/${notification_id}`)
      console.log('notificação alterada')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast])

  useEffect(() => {
    if (params?.id) {
      loadProposal(params?.id)
      loadClauses()
    }
  }, [params])

  useEffect(() => {
    console.log('parsed: ', parsed)
    if (parsed?.notification_id) {
      updateNotificationStatus(String(parsed?.notification_id))
    }
  }, [parsed])

  const handleSelectClause = useCallback((clause_id: string, control_id: string) => {
    const clause = allClauses.find(i => i.id === clause_id)

    if (clause) {
      const exists = itemsClauses.find(p => p.id === clause_id)

      if (exists) {
        toast({
          title: 'Você já cadastrou essa cláusula',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      } else {
        const filterItemsClauses = itemsClauses.filter(p => p.id_control !== control_id)

        const payload = {
          id_control: control_id,
          id: clause_id,
          resume: clause.resume,
          clause: clause.clause

        }
        setItemsClauses([...filterItemsClauses, payload])
      }
    }
  }, [allClauses, itemsClauses, toast])

  const ProposalStatus = useMemo(() => {
    if (!proposalData?.status) return PROPOSAL_STATUS.CREATED

    return getProposalStatus(Number(proposalData.status))
  }, [proposalData])

  const handleCreateProcess = useCallback(async (values: any) => {
    try {
      const dados: any[] = FindKeyWithPrefix(values, 'name-item-service_selected')
      console.log('dados: ', dados)

      const filtered = dados.filter(item => item.value === true)

      const items = filtered.map((i: any) => {
        const getId = i.key.split('name-item-service_selected-')
        return getId[1]
      })

      console.log('items: ', items)

      if (!items?.length) {
        toast({
          title: 'Selecione pelo menos 1 item da proposta para gerar o processo',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          duration: 7000
        })
        return
      }

      const payload = {
        proposal_id: proposalData?.id,
        net_id: proposalData?.net?.id,
        unit_id: proposalData?.unit?.id,
        items
      }

      await api.post('/process', payload)

      toast({
        title: 'Processo gerado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      navigation('/process')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [proposalData, toast, navigation])

  const handleSelecetAllServices = useCallback((e: any) => {
    const checked = e?.target?.checked

    const fields = getValues()
    const dados = FindKeyWithPrefix(fields, 'name-item-service_selected')

    if (checked) {
      const selected = dados.map((i: any) => {
        setValue(i.key, true)
        return i.key
      })
      setAllSelected(true)

      setServicesAllCheckedToProcess(selected)
    } else {
      dados.forEach((i: any) => {
        setValue(i.key, false)
      })
      setValue('name-item-all_service_selected', false)
      setAllSelected(false)
      setServicesAllCheckedToProcess([])
    }
  }, [getValues, setValue])

  const handleSelecetOneService = useCallback((e: any, id: string) => {
    const checked = e?.target?.checked
    if (checked) {
      setValue(id, true)
      setServicesAllCheckedToProcess(prev => [...prev, id])
    } else {
      const filtered = allServicesCheckedToProcess.filter((i: string) => i !== id)
      setValue(id, false)
      setServicesAllCheckedToProcess(filtered)
      setValue('name-item-all_service_selected', false)
      setAllSelected(false)
    }
  }, [setValue, allServicesCheckedToProcess])

  // const handleApproveProposal = useCallback(async () => {
  //   try {
  //     setLoading(true)
  //     await api.patch(`/proposal-approvement/${params?.id}`, {
  //       approved: 'yes'
  //     })

  //     toast({
  //       title: 'Pré-proposta aprovada',
  //       position: 'top',
  //       isClosable: true,
  //       status: 'info',
  //       variant: 'solid'
  //     })

  //     setSelectedApprovement(false)
  //   } catch (error) {
  //     toast({
  //       title: error.response.data.message,
  //       position: 'top',
  //       isClosable: true,
  //       status: 'error',
  //       variant: 'solid'
  //     })
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [toast, params])

  // const handleRefuseProposal = useCallback(async (message?: string) => {
  //   try {
  //     setLoading(true)
  //     await api.patch(`/proposal-approvement/${params?.id}`, {
  //       approved: 'no',
  //       message
  //     })

  //     toast({
  //       title: 'Pré-proposta recusada',
  //       position: 'top',
  //       isClosable: true,
  //       status: 'info',
  //       variant: 'solid'
  //     })

  //     setRefuseApprovement(false)
  //   } catch (error) {
  //     toast({
  //       title: error.response.data.message,
  //       position: 'top',
  //       isClosable: true,
  //       status: 'error',
  //       variant: 'solid'
  //     })
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [toast, params])

  const handleSearchClauses = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/clauses', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.resume
        }
      })

      // setAllClauses(data?.data)
      setClausesOptions(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const sameDepartment = useMemo(() => {
    return String(proposalData.collaborator_department) === String(user.department)
  }, [proposalData, user])

  const isBoss = useMemo(() => {
    return user.boss === 1
  }, [user])

  return (
    <Can permissions={['proposal.create', 'proposal.all']} page>
    <Box>

    {/* {!!selectedApprovement && (
        <AlertDialogCustom
          title="Você tem certeza?"
          isOpen={!!selectedApprovement}
          cancelFunction={() => setSelectedApprovement(false)}
          nextFunction={handleApproveProposal}

        />
    )}

    {!!refuseApprovement && (
        <RefusePreProposalModal
          title="Recusar Pré-proposta"
          isOpen={!!refuseApprovement}
          cancelFunction={() => setRefuseApprovement(false)}
          nextFunction={handleRefuseProposal}

        />
    )} */}

      <Modal isOpen={showModalProcess} onClose={() => setShowModalProcess(false)} size="2xl">
      <Box
           as="form"
           onSubmit={handleSubmit(handleCreateProcess)}
           flex="1"
           bg="white"
           p="8"
           w="100%"
         >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione os itens para a Ordem de Serviço</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th px={['4', '4', '6']} color="gray.300" w="8">
                      {allSelected ? <Checkbox key={Math.random()} colorScheme="pink" {...register('name-item-all_service_selected')} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelecetAllServices(e)} /> : <Checkbox key={Math.random()} colorScheme="pink" {...register('name-item-all_service_selected')} onChange={(e: any) => handleSelecetAllServices(e)} />}

                      </Th>
                    <Th>Serviços da Proposta</Th>

                    <Th>Valor Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {proposalData?.items?.map((i) => (
                    <Tr key={i.id}>
                      <Td px={['4', '4', '6']}>
                        {allServicesCheckedToProcess.includes(`name-item-service_selected-${i.id}`) ? <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${i.id}`)} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelecetOneService(e, `name-item-service_selected-${i.id}`)}/> : <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${i.id}`)} onChange={(e: any) => handleSelecetOneService(e, `name-item-service_selected-${i.id}`)} /> }

                      </Td>
                      <Td>
                        <Box>

                            <Text fontWeight="bold">{i?.service?.name || 'Serviço não encontrado.'}</Text>

                        </Box>
                      </Td>
                      <Td>
                        <Text fontWeight="bold">{FormatMoney(Number(i?.company_value))}</Text>
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => setShowModalProcess(false)}>
              Cancelar
            </Button>

            <Button
                 type="submit"
                 bg="wine.primary"
                 _hover={{ bgColor: 'gray.500' }}
                 color="white"
                 isLoading={formState.isSubmitting}
               >Gerar Ordem de Serviço (Processo)</Button>

          </ModalFooter>
        </ModalContent>
        </Box>
      </Modal>

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <VStack w="100%" borderRadius={8} bg="white" p="8">

          <Flex color="wine.primary" display="flex" justifyContent="space-between" w="100%">
            <Text color="wine.primary" fontWeight="bold" fontSize="24px">Proposta</Text>
            <Text color="wine.primary" fontWeight="bold" fontSize="24px">Nº {proposalData?.number}</Text>

          </Flex>

        {loading && <Loader /> }
          <Box
           as="form"
           onSubmit={handleSubmit(handleSubmitForm)}
           flex="1"
           bg="white"
           p="6"
           w="100%"
         >

           <VStack spacing={['12', '12']}>

           <Flex w="100%" >
            <Text color="gray.500" fontWeight="bold" fontSize="24px">Dados Cadastrais</Text>

           </Flex>

             <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Empresa Executante</Text>
                   <Text align="center">{proposalData?.company?.toUpperCase() || 'Não definido'}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                   <Text align="center">{proposalData?.technical?.name}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                   <Text align="center">{proposalData?.financial?.name}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                   <Text align="center">{proposalData?.net?.name}</Text>
               </Box>

             </SimpleGrid>

             <Divider />

             <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
             <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Responsável</Text>
                   <Text align="center">{proposalData?.contact?.name}</Text>
               </Box>

               <Box>
                   <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                   <Text align="center">{proposalData?.unit?.id ? proposalData?.unit?.name || proposalData?.unit?.name_approvation || 'Nome da unidade não informado' : 'Unidade removida'}</Text>
               </Box>

                <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Total</Text>
                  <Text align="center">{proposalData?.immobile?.area_ground || '--'}</Text>
                </Box>

                <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Construída</Text>
                  <Text align="center">{proposalData?.immobile?.area_building || '--'}</Text>
                </Box>

             </SimpleGrid>

             <Divider />

           </VStack>

           <Heading size="md" fontWeight="bold" color="gray.500" my="8">
           Itens da Proposta
           </Heading>

           {!proposalData?.items?.length && <Text mb="10" color="wine.primary">Você não possui nenhum item de proposta cadastrado.</Text>}

           {proposalData?.items?.map((item, indexItem: number) => (
               <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                   <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                         <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                         <Text align="center" color="white.primary">{item?.executor?.name || 'Executante removido'}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Serviço</Text>
                         <Text align="center" color="white.primary">{item?.service?.name || 'Serviço não encontrado'}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                         <Text align="center" color="white.primary">{item?.installments}x</Text>
                       </Box>

                   </SimpleGrid>

                   <Divider color="white" />

                   <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Valor do Prestador</Text>
                         <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Locomoção/Passagem</Text>
                         <Text align="center" color="white.primary">{item?.ticket_value_formatted}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Despesas</Text>
                         <Text align="center" color="white.primary">{item?.expenses_value_formatted}</Text>
                       </Box>

                       <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Previsão de Entrega</Text>
                         <Text align="center" color="white.primary">{item?.release_date}</Text>
                       </Box>
                   </SimpleGrid>

                   <Divider />

                      <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                        <Text align="left" color="white.primary" fontSize="21">{`Valor total do item: ${String(item?.totalValues)}`}</Text>
                      </SimpleGrid>
                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                    <Box>
                          <Text color="white.primary" fontWeight={500} align="left">Observações</Text>
                          <Text align="left" color="white.primary">{item?.obs || '--'}</Text>
                        </Box>
                    </SimpleGrid>

                   {parsed?.action !== NOTIFICATIONS_TYPE['pre-proposal-approvement-boss-sector'] && proposalData?.boss_department_approval && (
                    <>
                      <Divider color="white" />
                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                    <MoneyInput
                      labelStyle={{ color: '#fff' }}
                      label="Valor Total"
                      placeholder="Preencha o valor"
                      extraFunction={(e: any) => CalculateBDI(item, e)}
                      // errors={errors.name}
                      defaultValue={item?.company_value ? Number(item?.company_value) : null}
                      {...register(`name-item-total_value-${item.id}`)}

                    />

                    <Input
                          labelStyle={{ color: '#fff' }}
                          bg={bdiColors[`name-item-bdi-${item.id}`] || '#fff'}
                          fontWeight={700}
                          label='BDI'
                          isReadOnly

                          {...register(`name-item-bdi-${item.id}`)}
                    />
                    </SimpleGrid>

                  </>
                   )}

               </VStack>
           ))}

            {/* {parsed?.action !== NOTIFICATIONS_TYPE['pre-proposal-approvement-boss-sector'] && (
                <>

                      <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                        Cláusulas da Proposta
                      </Heading>

                      {!proposalData?.clauses?.length && <Text mb="10" color="wine.primary">Você não possui nenhuma cláusula cadastrada.</Text>}

                      {proposalData?.clauses?.map((item) => (
                          <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                                    <Box>
                                    <Text color="white.primary" fontWeight={500} align="left">Cláusula</Text>
                                    <Text align="left" color="white.primary">{item?.clause || 'Cláusula não encontrado.'}</Text>
                                  </Box>

                              </SimpleGrid>

                          </VStack>
                      ))}
                      </>
            )} */}

        {parsed?.action !== NOTIFICATIONS_TYPE['pre-proposal-approvement-boss-sector'] && proposalData?.boss_department_approval && (
          <>
            <Heading size="md" fontWeight="bold" color="gray.500" my="8">
              Cláusulas da Proposta
            </Heading>

            {!itemsClauses?.length && <Text mb="10" color="wine.primary">Você ainda não possui nenhuma cláusula de proposta cadastrada.</Text>}

            {[...itemsClauses]?.map((item) => (
                <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                    <InputModalSearch title="Cláusula de Proposta" searchFunction={handleSearchClauses} data={clausesOptions} extraFunction={(e: any) => handleSelectClause(e, item.id_control)} placeholderButton={ item?.id === '' ? 'Selecionar Cláusula' : 'Trocar Cláusula'} labelStyle={{ color: 'white' }} btnStyle={{ backgroundColor: '#F765a3' }} />
                    <Input {...register(`clause-${item.id}`)} value={allClauses?.find(i => i.id === item.id)?.clause} isReadOnly visibility="hidden" />

                        {allClauses?.find(i => i.id === item.id) && (
                          <Box>
                          <Text color="white.primary" fontWeight={500} align="center">Cláusula</Text>
                          <Text align="center" color="white.primary">{allClauses?.find(i => i.id === item.id)?.clause}</Text>
                        </Box>
                        )}

                    </SimpleGrid>

                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                  <Button w="250px"

                            as="button"
                            size="sm"
                            fontSize="md"
                            ml="auto"
                            bg="teal"
                            height="47px"
                            _hover={{ bgColor: 'gray.500' }}
                            color="white"
                            leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                            onClick={() => handleDeleteClauseOption(item.id_control)}
                            >
                              Excluir
                          </Button>

                  </SimpleGrid>

                  {/* <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                  <Input
                    labelStyle={{ color: '#fff' }}
                      label="Item Proposta"
                      placeholder="Preencha o Item"
                      // errors={errors.name}
                      {...register(`name-item-${item.id}`)}
                    />

                      <Input
                      labelStyle={{ color: '#fff' }}
                        label="Valor Prestador"
                        placeholder="Preencha o valor"
                        // errors={errors.name}
                        {...register(`value-item-${item.id}`)}
                      />

                      <Select
                    labelStyle={{ color: '#fff' }}
                      label="Forma de pagamento"
                      placeholder="Selecionar forma"
                      // errors={errors.gender}
                      {...register(`executante-item-${item.id}`)}
                      options={optionsUnits}
                      />
                  </SimpleGrid> */}

                  {/* <Button
                    width="100%"
                    as="button"
                    size="lg"
                    fontSize="sm"
                    bg="wine.200"
                    mb="10"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white.primary"
                    leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                    onClick={() => handleNewOption(item.id)}
                    >
                    Adicionar Opção
                  </Button> */}

                  {/* <SimpleGrid spacing={['12', '12']} w="100%" >

                    {item?.items?.map((option) => (
                      <Flex key={option.id} alignItems="flex-end" w="100%">
                        <Input
                          label="Opção"
                          placeholder="Preencha a opção (Ex: 50kg)"
                          // errors={errors.name}
                          {...register(`name-option-${option.id}-item-${option.item_id}`)}

                        />
                        <Button
                            ml="5"
                            as="button"
                            size="sm"
                            fontSize="md"
                            bg="black.primary"
                            height="47px"
                            _hover={{ bgColor: 'gray.500' }}
                            color="white"
                            leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                            onClick={() => handleDeleteOption(option)}
                            >
                              Excluir
                          </Button>
                        </Flex>
                    ))}
                  </SimpleGrid> */}
                </VStack>
            ))}

            <Button
              as="button"
              size="lg"
              fontSize="sm"
              bg="wine.primary"
              mb="10"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              onClick={handleNewClause}
              >
              Adicionar Cláusula
            </Button>
          </>
        )}

        {!!(fileProposal?.id || initialCoverProposalProviderAttachment?.id) && (
          <>
          <Divider />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Anexos
          </Heading>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

              {files?.find(i => i.type === 'proposta-prestador')?.extension === 'pdf'
                ? (
                      <a href={initialCoverProposalProviderAttachment?.link} target="_blank" rel="noreferrer">
                        <Text color="wine.200" mb="10px">Proposta do Prestador</Text>
                        <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                          <Document file={initialCoverProposalProviderAttachment?.link}>
                            <Page pageNumber={1} height={200} width={200} />
                          </Document>
                        </div>
                      </a>
                  )
                : <Box w={250}>
                    <Text color="wine.200" mb="10px">Proposta do Prestador</Text>
                        <a href={initialCoverProposalProviderAttachment?.link} target="_blank" rel="noreferrer">
                        <Image src={initialCoverProposalProviderAttachment?.link} width={250} mb={8}/></a>
                  </Box>
              }

              {fileProposal?.id && (
                fileProposal?.extension === 'pdf'
                  ? (
                  <a href={fileProposal?.link} target="_blank" rel="noreferrer" style={{ width: 200 }}>
                    <Text color="wine.200" mb="10px">Proposta final em PDF</Text>
                    <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                      <Document file={fileProposal.link}>
                        <Page pageNumber={1} height={200} width={200} />
                      </Document>
                    </div>
                  </a>
                    )
                  : <Box w={250}>
                  <Text color="wine.200" mb="10px">Proposta final em PDF</Text>
                    <a href={fileProposal?.link} target="_blank" rel="noreferrer">
                    <Image src={fileProposal?.link} width={250} mb={8}/></a>
                  </Box>
              )}

            </SimpleGrid>
          </>
        )}

          {proposalData?.boss_department_approval && (
            <Flex mt={['6', '8']} justify="flex-end">
                      <HStack>
                          <Text color="wine.primary" fontSize="19px" fontWeight="bold">Total: {totalProposal}</Text>
                      </HStack>
                    </Flex>
          )}

           {((!proposalData?.boss_department_approval && !parsed?.action) || (sameDepartment && isBoss)) && (
             <Flex bg="orange.300" py="5" px="8" borderRadius="10px" mt="10" alignItems="center">
             <Icon as={RiAlertLine} fontSize="35" color="#FFF" mr="3" />
             <Flex flexDirection="column" alignItems="center" justifyContent="center" flex="1" >
             <Text color="white.primary" fontSize="19px" fontWeight="bold" align="center">{'Essa pré-proposta ainda não foi aprovada pelo chefe do setor.'}</Text>

             {sameDepartment && isBoss && <Button
                    as="a"
                    href={`/pre-proposal/create/${proposalData.id}?action=pre-proposal-approvement-boss-sector`}
                    bg="wine.primary"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    marginTop={5}
                    isLoading={formState.isSubmitting}
                  >
                    Verificar Aprovação
                  </Button>}
                  {!sameDepartment && !isBoss && <Text color="white.primary" fontSize="19px" fontWeight="bold" align="center">{'Você só poderá seguir adiante após a aprovação do chefe do setor.'}</Text>}
             </Flex>

             </Flex>
           ) }

           {proposalData?.boss_department_approval &&
              <Flex mt={['6', '8']} justify="flex-end">

                  <HStack>
                  {(ProposalStatus === 'CREATED' || ProposalStatus === 'APPROVED_BY_COMPANY') && <Button
                    type="submit"
                    bg="wine.primary"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                  >
                    Salvar Alterações
                  </Button>}

                  {ProposalStatus !== 'CREATED' && <Button
                    onClick={handleCreatePDF}
                    bg="blue"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                  >
                    Gerar Proposta (PDF)
                  </Button>}

                  {(ProposalStatus === 'APPROVED_BY_COMPANY' || ProposalStatus === 'APPROVED_BY_CUSTOMER') && (
                      <Button
                      as="button"
                      bg="pink.500"
                      _hover={{ bgColor: 'gray.500' }}
                      color="white"
                      onClick={() => setShowModalProcess(true)}
                      >
                      Gerar Ordem de Serviço (processo)
                      </Button>
                  )}

                </HStack>

              </Flex>
          }

         </Box>

        </VStack>

      </Flex>
    </Box>
    </Can>
  )
}

export { ProposalCreate }
