import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { Can } from '../../Components/Can'

interface createUserProps {
  cod: string
  resume: string
  clause: string
}

interface ClauseProposal {
  id: string
  cod: string
  resume: string
  clause: string
}

interface OptionsProps {
  label: string
  value: string
}

interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createFormSchema = Yup.object().shape({
  resume: Yup.string().required('Resumo da Cláusula é obrigatório'),
  clause: Yup.string().required('Cláusula Oficial é obrigatório')

})

const ClauseProposalCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      const {
        resume, clause
      } = values

      const payload = {
        resume, clause
      }

      const { id } = params

      try {
        id
          ? await api.put(`/clauses/${id}`, payload)
          : await api.post('/clauses', payload)

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigate('/clause-proposal')
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigate]
  )

  const loadItem = useCallback(async () => {
    try {
      const { id } = params
      const resp = await api.get(`/clauses/${id}`)
      const data: ClauseProposal = resp.data

      setValue('cod', data.cod)
      setValue('resume', data.resume)

      // setValue('gender', data.gender)
      // setValue('password', data.password)
      setValue('clause', data.clause)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  useEffect(() => {
    if (params?.id) {
      loadItem()
    }
  }, [params])

  return (
    <Can permissions={['clauses.create', 'clauses.all']} page>
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar nova Cláusula de Proposta
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            {/* <Input
                label="Código da Cláusula"
                placeholder="Preencha o código"
                // errors={errors.name}
                {...register('cod')}
              /> */}

              <Input
                label="Resumo da Cláusula"
                placeholder="Preencha o resumo"
                errors={errors.resume}
                {...register('resume')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <TextArea
                label="Cláusula Oficial da Proposta"
                placeholder="Preencha"
                errors={errors.clause}
                {...register('clause')}
              />

            </SimpleGrid>

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ClauseProposalCreate }
