/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, RefObject, useCallback, useMemo, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  FormLabel,

  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'
import { DragAndDrop, DragAndDropExportedProps } from '../Form/DragAndDrop'
import { FilesProps } from '../../Pages/Process/Interfaces/ProcessIntefaces'

interface Props {

  cancelFunction: (data?: any) => void

  title: string
  payment_shared_id: string
  cbFunction?: (data?: any) => Promise<any>
}

const ResetPassSchema = Yup.object().shape({
  password: Yup.string().required('Preencha a senha'),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ).required('Preencha a confirmação de senha')
})

const ReceiptSharedBillModal: FC<Props> = ({
  payment_shared_id,
  cancelFunction,
  title,
  cbFunction

}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)
  const [files, setFiles] = useState<FilesProps[]>()
  const { register, handleSubmit, formState } = useForm({
    // resolver: yupResolver(ResetPassSchema)
  })
  const toast = useToast()

  const DragRef = useRef<DragAndDropExportedProps>(null)
  const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async () => {
      setLoading(true)

      try {
        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: 'Anexo obrigatório.',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? async () => {
              // navigate('/collaborators')

              toast({
                title: 'Comprovante enviado',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })

              if (cbFunction != null) {
                await cbFunction()
                setLoading(false)
              }
            }
            : () => {}
          inputDrag?.current?.execute(`/files/${payment_shared_id}`, cb)
        }))
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [cbFunction, toast, allFiles, payment_shared_id]
  )

  return (
    <AlertDialog
      isOpen
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="4xl"
    >
        {loading && <Loader />}
      <AlertDialogOverlay>

      <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          p="8"
        >

        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Anexe o comprovante de pagamento:</Text>
          <VStack spacing={['6', '8']} mt="10">
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                    label="Comprovante de Pagamento"
                    ref={DragRef}
                    defaultCover={initialCover}
                    payload={{
                      name: 'Comprovante de Pagamento',
                      type: 'comprovante-pagamento'
                    }}
                    id={files?.length ? files.find(i => i.type === 'comprovante-pagamento')?.id : null}
                  />

                  <Box />

            </SimpleGrid></VStack>
          </AlertDialogBody>

          <AlertDialogFooter>

            <Button
            type='submit'
              colorScheme={'pink'}

              ml={3}
            >
             Enviar Comprovante
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { ReceiptSharedBillModal }
