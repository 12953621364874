import {
  Box,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
  Icon
} from '@chakra-ui/react'
import { NavSidebar } from './NavSidebar'
import { useSideBar } from '../../Hooks/SideBarContext'
import { RiAddLine, RiMenu2Fill } from 'react-icons/ri'

interface Props {
  showDrawer?: boolean
}
export function Sidebar ({ showDrawer = false }: Props) {
  const { isOpen, onClose, onToggle } = useSideBar()
  // const showDrawer = useBreakpointValue({
  //   base: true,
  //   lg: false,
  // });

  if (showDrawer) {
    return (
      <>
        <Button
              as="button"
              width="100px"
              onClick={onToggle}
              size="sm"
              fontSize="sm"
              bg="wine.primary"
              mb="20px"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiMenu2Fill} fontSize="20" />}
            >
              Menu
            </Button>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="wine.primary">
            <DrawerHeader color="white">Navegação</DrawerHeader>
            <DrawerCloseButton mt="2" color="white" />

            <DrawerBody>
              <NavSidebar />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      </>

    )
  }

  return (
    <Box as="aside" w="64" mr="8">
      <NavSidebar />
    </Box>
  )
}
