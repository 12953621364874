/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  FormLabel,

  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'
import { DragAndDrop, DragAndDropExportedProps } from '../Form/DragAndDrop'
import { FilesProps, IEachPaymentProps, IPaymentStatus } from '../../Pages/Process/Interfaces/ProcessIntefaces'
import { TextArea } from '../Form/TextArea'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  type: IPaymentStatus
  cbFunction?: (data?: any) => Promise<any>
  payment: IEachPaymentProps
}

const PaymentRequestModal: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  cbFunction,
  type,
  payment

}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)
  const [files, setFiles] = useState<FilesProps[]>()

  const { register, handleSubmit, formState, getValues, setValue } = useForm({
    // resolver: yupResolver(ResetPassSchema)
  })
  const toast = useToast()
  const { user, updateGlobalUser } = useAuth()

  const { errors } = formState

  const DragRef = useRef<DragAndDropExportedProps>(null)
  const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async () => {
      setLoading(true)

      try {
        const values = getValues()
        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: 'Anexo obrigatório.',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          setLoading(false)
          return
        }

        const { payment_id, wasAgended } = await nextFunction(values?.obs)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? async () => {
              // navigate('/collaborators')

              toast({
                title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })

              if (cbFunction != null) {
                await cbFunction()
                setLoading(false)
              }
            }
            : () => {}
          inputDrag?.current?.execute(`/files/${payment_id}`, cb)
        }))
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [nextFunction, toast, allFiles, cbFunction, getValues]
  )

  const loadPayment = useCallback(async () => {
    if (type !== 'REQUESTED') return

    try {
      const { data } = await api.get(`/payments/${payment?.id}`)
      console.log('data: ', data)
      const findReceipt = data?.files?.find((i: FilesProps) => i.type === 'comprovante-execucao-servico')
      if (findReceipt) {
        setInitialCover(findReceipt?.link)
      }
      setFiles(data.files)

      if (data?.obs) {
        setValue('obs', data?.obs)
      }
    } catch (error) {

    }
  }, [payment?.id, type, setValue])

  useEffect(() => {
    loadPayment()
  }, [])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
      size="4xl"
    >
        {loading && <Loader />}
      <AlertDialogOverlay>

      <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          flex="1"
          p="8"
        >

        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Para solicitar um pagamento, você precisa anexar a Nota Fiscal ou Comprovante de Execução do Serviço:</Text>
          <VStack spacing={['6', '8']} mt="10">
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                label="Nota Fiscal ou Comprovante de Execução"
                ref={DragRef}
                defaultCover={initialCover}
                payload={{
                  name: 'Nota Fiscal ou Comprovante de Execução',
                  type: 'comprovante-execucao-servico'
                }}
                id={files?.length ? files.find(i => i.type === 'comprovante-execucao-servico')?.id : null}

              />

              <Box />

            </SimpleGrid>

            <TextArea
              label="Observação"
              placeholder="Preencha uma observação para o financeiro se houver"
              {...register('obs')}
            />

            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>

            <Button
              type='submit'
              colorScheme={'pink'}

              ml={3}
            >
            {type === 'REQUESTED' ? 'Atualizar Pagamento' : 'Solicitar Pagamento' }
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { PaymentRequestModal }
