/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Text,
  Icon
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { TextArea } from '../../Components/Form/TextArea'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import { RiAddLine, RiCloseFill } from 'react-icons/ri'
import { removeNonNumeric } from '../../utils/removeNonNumeric'
import { Loader } from '../../Components/Loader'
import { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { Can } from '../../Components/Can'

interface contactProps {
  name: string
  email: string
  phone: string
  department: string
}

interface ItemProps {
  id: string
  name: string
  email: string
  phone: string
  department: string
}

let controlRender = false

interface Nets {
  cnpj: number
  name: string
  cod: string
  contract_number: string
  internal_code: string
  obs: string
  razao_social: string
  contacts: contactProps[]
  created_at: string
  files: FilesProps[]

}

const NetsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const [items, setItems] = useState<ItemProps[]>([])
  const params = useParams()
  const [initialCover, setInitialCover] = useState<undefined | string>(undefined)
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const toast = useToast()
  const navigation = useNavigate()
  const [files, setFiles] = useState<FilesProps[]>()
  console.log('params: ', params)

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values): Promise<void> => {
      setLoading(true)
      const {
        name,
        cnpj, contract_number, internal_code, obs, razao_social
      } = values

      const contacsByName = FindKeyWithPrefix(values, 'name-item-name')
      const contacsByDepartment = FindKeyWithPrefix(values, 'name-item-department')
      const isValidDepartment = contacsByDepartment.every((i: any) => i.value !== '')
      const isValid = contacsByName.every((i: any) => i.value !== '')

      if (!items?.length) {
        toast({
          title: 'Preencha pelo menos um contato na Rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      if (!isValid) {
        toast({
          title: 'Preencha o nome de cada um dos contatos na Rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      if (!isValidDepartment) {
        toast({
          title: 'Preencha o departamento de cada um dos contatos na Rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      const normalizeCNPJ = removeNonNumeric(cnpj)

      if (normalizeCNPJ.length < 14) {
        toast({
          title: 'CNPJ Inválido',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })
        setLoading(false)

        return
      }

      const payload = {
        name,
        cnpj: normalizeCNPJ,
        contract_number,
        internal_code,
        obs,
        razao_social,
        contacts: items.map(i => {
          return {
            name: values[`name-item-name-${i.id}`] || '',
            email: values[`name-item-email-${i.id}`] || '',
            phone: values[`name-item-phone-${i.id}`] || '',
            department: values[`name-item-department-${i.id}`] || ''
          }
        })
      }
      console.log('payload: ', payload)

      let id = params?.id

      try {
        if (id) {
          await api.put(`/nets/${id}`, payload)
        } else {
          const { data } = await api.post('/nets', payload)
          id = data?.net?.id
        }

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        const currentFile = DragRef?.current?.selectedFile
        console.log('currentFile: ', currentFile)
        if (currentFile) {
          DragRef?.current?.execute(`/files/${id}`, () => {
            navigation('/nets')
            setLoading(false)
          })
        } else {
          navigation('/nets')
          setLoading(false)
        }
      } catch (error) {
        console.log('error: ', error)
        setLoading(false)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params, navigation, items]
  )

  const loadItem = useCallback(async () => {
    if (controlRender) return
    controlRender = true
    try {
      const { id } = params
      const resp = await api.get(`/nets/${id}`)
      const data: Nets = resp.data

      setValue('name', data.name)
      setValue('cod', data.cod)

      setValue('razao_social', data.razao_social)

      setValue('contract_number', data.contract_number)
      setValue('internal_code', data.internal_code)
      setValue('cnpj', data.cnpj)
      setValue('obs', data.obs)

      data?.contacts?.forEach((i: any) => {
        console.log('i:', i)
        const payload = {
          id: i.id,
          name: i.name,
          email: i.email,
          phone: i.phone,
          department: i.department
        }

        setItems(prev => [...prev, payload])
      })

      console.log('cover: ', data.files[0].link)

      // como temos apenas 1 file na rede por enquanto, podemos pegar dessa forma
      setInitialCover(data.files[0].link)

      setFiles(data.files)

      const creationDate = format(new Date(data.created_at), 'dd/MM/yyyy')
      setCreatedAt(creationDate)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params?.id) {
      loadItem()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleNewItem = useCallback(() => {
    const payload = {
      id: uuid(),
      name: '',
      email: '',
      phone: '',
      department: ''
    }
    setItems(prev => [...prev, payload])
  }, [])

  const handleDeleteItem = useCallback((id: string) => {
    const newArray = items.filter(i => i.id !== id)
    unregister(`name-item-${id}`)
    setItems([...newArray])
  }, [items, unregister])

  return (
    <Can permissions={['nets.create', 'nets.all']} page>
    <Box>
      <Header />
    {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar nova Rede
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais

            {createdAt && <Text size="sm" fontSize="14">Data de inclusão: {createdAt}</Text> }
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              {/* <Input
                label="Código Cliente"
                placeholder="Preencha o código"
                {...register('cod')}
                type="text"
                errors={errors?.cod}

              /> */}

              <Input
                label="Nome"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

              <Input
                label="Razão Social"
                placeholder="Preencha a Razão Social"

                errors={errors.razao_social}
                {...register('razao_social')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              {/* <Input
                  label="Número do Contrato"
                  placeholder="Preencha o Número do Contrato"
                  errors={errors.contract_number}
                  {...register('contract_number')}
                /> */}

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <InputMaskCustom
                type="text"
                label="CNPJ"
                errors={errors.cnpj}
                {...register('cnpj')}
                placeholder="Digite o cnpj"
                mask="99.999.999/9999-99"
              />

<Input
                label="Código de Prefixo"
                placeholder="Ex: RD"
                errors={errors.internal_code}
                {...register('internal_code')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <DragAndDrop label="Anexo do Contrato" ref={DragRef} payload={{
                name: 'comprovante do fulano Anexo do Contrato',
                type: 'contrato-anexo'
              }}
                defaultCover={initialCover}
                  id={files?.length ? files[0]?.id : null}

                />

                <Text></Text>
                <Text></Text>
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <TextArea
                {...register('obs')}
                label="Observações sobre o Cliente"
              />
            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
           Contatos na Rede
          </Heading>

          {!items?.length && <Text mb="10" color="wine.primary">Você ainda não possui nenhum contato nesta rede.</Text>}

          {items.map((item, indexItem: number) => (
              <VStack spacing={['9', '9']} mb="5" bg="wine.primary" alignItems="center" p="10" key={item.id} borderRadius="8px">
                <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                  <Input
                  labelStyle={{ color: '#fff' }}
                    label="Nome"
                    placeholder="Preencha o nome"
                    // errors={errors.name}
                    {...register(`name-item-name-${item.id}`)}
                    defaultValue={item.name}
                  />

                  <Input
                  labelStyle={{ color: '#fff' }}
                    label="E-mail"
                    placeholder="Preencha o e-mail"
                    // errors={errors.name}
                    {...register(`name-item-email-${item.id}`)}
                    defaultValue={item.email}
                  />

                  <InputMaskCustom
                    mask="(99)99999-9999"

                    label="Telefone"
                    placeholder="Preencha o telefone"
                    // errors={errors.name}
                    {...register(`name-item-phone-${item.id}`)}
                    defaultValue={item.phone}
                  />

                <Input
                  labelStyle={{ color: '#fff' }}
                    label="Setor/Departamento"
                    placeholder="Preencha o setor"
                    // errors={errors.name}
                    {...register(`name-item-department-${item.id}`)}
                    defaultValue={item.department}
                  />

                </SimpleGrid>
                <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                <Button
                      onClick={() => handleDeleteItem(item.id)}
                      style={{ width: 90 }}
                      size="sm"
                      fontSize="sm"
                      mr="2"
                      bg="pink.500"
                      colorScheme="wine"
                      color="white"
                      leftIcon={
                        <Icon as={RiCloseFill} fontSize="16" />
                      }
                    >Deletar</Button>

                </SimpleGrid>

              </VStack>
          ))}

          <Button
            as="button"
            size="lg"
            fontSize="sm"
            bg="wine.primary"
            mb="10"
            _hover={{ bgColor: 'wine.200' }}
            color="white"
            leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            onClick={handleNewItem}
            >
            Adicionar novo contato
          </Button>

          <VStack spacing={['6', '8']}>

            {loading && (
              <Flex justify="center">
                <Spinner />
              </Flex>
            )}

            {/* {showAddressComponent && (
              <>
                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Input
                    label="Rua"
                    placeholder="Preencha a rua"
                    // errors={errors.street}
                    {...register('street')}
                  />

                  <Input
                    label="Número"
                    placeholder="Preencha o número"
                    // errors={errors.number}
                    {...register('number')}
                  />
                </SimpleGrid>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Input
                    label="Complemento"
                    placeholder="Preencha o Complemento"
                    // errors={errors.complement}
                    {...register('complement')}
                  />

                  <Input
                    label="Bairro"
                    placeholder="Preencha o bairro"
                    // errors={errors.neighborhood}
                    {...register('neighborhood')}
                  />
                </SimpleGrid>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Input
                    label="Cidade"
                    placeholder="Preencha a cidade"
                    // errors={errors.city}
                    {...register('city')}
                  />

                  <Input
                    label="Estado"
                    placeholder="Preencha o estado"
                    maxLength={2}
                    // errors={errors.state}
                    {...register('state')}
                  />
                </SimpleGrid>
              </>
            )} */}
          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { NetsCreate }
