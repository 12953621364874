/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IPaymentProps from '../../Pages/BillsToPay/Interfaces/ProcessIntefaces'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Text, Box, Heading, SimpleGrid, AccordionPanel, Divider, Icon, Flex, Image, useToast } from '@chakra-ui/react'
import { FiCheck, FiX } from 'react-icons/fi'
import { FilesProps } from '../../Pages/Process/Interfaces/ProcessIntefaces'
import { statusOptions, typePaymentsOptions } from '../../Pages/BillsToPay/create'
import { Checkbox } from '../Form/Checkbox'
import { useForm } from 'react-hook-form'
import { Select } from '../Form/Select'
import { Document, Page, pdfjs } from 'react-pdf'
import { AlertDialogCustom } from '../AlertDialog'
import { FindKeyWithPrefix } from '../../utils/findKeyWithPrefix'
import api from '../../Services/api'
import { ReceiptSharedBillModal } from '../ReceiptSharedBillModal'
import { v4 as uuid } from 'uuid'
import { DragAndDrop, DragAndDropExportedProps } from '../Form/DragAndDrop'
import { Loader } from '../Loader'
import { FormatMoney } from '../../utils/formatMoney'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  data?: IPaymentProps[]
  refreshFunction?: () => Promise<void>
  sharedPaymentId?: string
}

const actionStatusOptions = [
  {
    value: 'REQUESTED',
    label: 'Selecionar'
  },
  {
    value: 'PAID',
    label: 'Efetuar Pagamento de todas selecionadas'
  }
]

interface CustomFileProps extends FilesProps {
  payment_id: string

}

const AccordionMultiPayments: FC<Props> = ({ data: dataFromProps, refreshFunction, sharedPaymentId }) => {
  const [receiptService, setReceiptService] = useState({} as FilesProps)
  const [allBillsCheckedToProcess, setAllBillsCheckedToProcess] = useState<string[]>([])
  const [showPaidModal, setShowPaidModal] = useState(false)
  const [showRefuseModal, setShowRefuseModal] = useState(false)
  const [receipts, setReceipts] = useState<CustomFileProps[]>([])
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const toast = useToast()
  const [paymentSharedId, setPaymentSharedId] = useState('')
  const ReceiptPaymentRef = useRef<DragAndDropExportedProps>(null)
  const [initialCoverReceiptPayment, setInitialCoverReceiptPayment] = useState(undefined)
  const [files, setFiles] = useState<FilesProps[]>()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })

  console.log('shared: ', dataFromProps)

  const [list, setList] = useState<IPaymentProps[]>([])

  useEffect(() => {
    if (dataFromProps != null) {
      setList(dataFromProps)
    }
  }, [dataFromProps])

  const handleSelectBill = useCallback((e: any, id: string) => {
    setValue('status', 'REQUESTED')
    const checked = e?.target?.checked
    if (checked) {
      setValue(id, true)
      setAllBillsCheckedToProcess(prev => [...prev, id])
    } else {
      const filtered = allBillsCheckedToProcess.filter((i: string) => i !== id)
      setValue(id, false)
      setAllBillsCheckedToProcess(filtered)
      setValue('name-item-all_service_selected', false)
    //   setAllSelected(false)
    }
  }, [setValue, allBillsCheckedToProcess])

  const MakeColor = (i: 'REQUESTED' | 'PAID' | 'REFUSED' | undefined) => {
    const colors = {
      REQUESTED: 'green',
      PAID: 'wine.500',
      REFUSED: 'red'
    }

    return i ? colors[i] : 'orange'
  }

  const handleChangePaymentStatus = useCallback((e: any) => {
    const selectValue = e?.target?.value

    if (selectValue === 'PAID') {
      setShowPaidModal(true)
    }
  }, [])

  const closeModal = useCallback(() => {
    setShowPaidModal(false)
  }, [])

  useEffect(() => {
    const makeFiles: CustomFileProps[] = []

    list?.forEach(item => {
      item?.files?.forEach(i => {
        const payload = {
          ...i,
          payment_id: item?.id
        }

        makeFiles.push(payload)
      })
    })

    setReceipts(makeFiles)
  }, [list])

  const payBill = useCallback(async (payment_id: string, sharedPaymentId: string) => {
    try {
      await api.put(`/payments/${payment_id}`, {
        status: 'PAID',
        payment_shared_id: sharedPaymentId
      })

      setShowReceiptModal(true)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleConfirmPayment = useCallback(async () => {
    try {
      // setLoading(true)
      const values = getValues()

      const dados: any[] = FindKeyWithPrefix(values, 'name-item-service_selected')

      const filtered = dados.filter(item => item.value === true)

      const items = filtered.map((i: any) => {
        const getId = i.key.split('name-item-service_selected-')
        return getId[1]
      })

      console.log('items: ', items)

      if (!items?.length) {
        toast({
          title: 'Selecione pelo menos 1 pagamento.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          duration: 7000
        })
        return
      }

      const id = uuid()
      setPaymentSharedId(id)

      await Promise.all(items?.map(async i => await payBill(i, id)))

      toast({
        title: 'Pagamento Efetuado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setShowPaidModal(false)
      // setLoading(false)
    }
  }, [toast, getValues, payBill])

  const handleCloseModal = useCallback(async () => {
    setShowReceiptModal(false)

    if (refreshFunction != null) {
      await refreshFunction()
    }
  }, [refreshFunction])

  const handleSubmitReceiptPayment = useCallback(async () => {
    try {
      if (!ReceiptPaymentRef?.current?.selectedFile) {
        toast({
          title: 'Anexo não encontrado',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [ReceiptPaymentRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Comprovante salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })

              if (refreshFunction != null) {
                refreshFunction()
              }

              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${sharedPaymentId}`, cb)
      }))
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })

      setLoading(false)
    }
  }, [toast, sharedPaymentId, refreshFunction])

  const load = useCallback(async () => {
    try {
      const { data: dados } = await api.get(`/payments/shared/${sharedPaymentId}`)

      const findReceipt = dados[0]?.files?.find((i: FilesProps) => i.type === 'comprovante-execucao-servico')
      const findReceiptPayment = dados[0]?.files?.find((i: FilesProps) => i.type === 'comprovante-pagamento')

      // if (findReceipt) {
      //   setReceiptService(findReceipt)
      // }

      if (findReceiptPayment) {
        setInitialCoverReceiptPayment(findReceiptPayment?.link)
      }

      setList(dados)

      // setPaymentData(data)
      setFiles(dados[0].files)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, sharedPaymentId])

  useEffect(() => {
    if (sharedPaymentId) {
      load()
    }
  }, [sharedPaymentId])

  const totalSum = useMemo(() => {
    if ((dataFromProps != null) && !dataFromProps?.length) {
      return 0
    }

    const sum = dataFromProps?.reduce((accumulator, current) => {
      return Number(accumulator) + Number(current.value)
    }, 0)

    return FormatMoney(sum || 0)
  }, [dataFromProps])

  return (

    <>
      {!sharedPaymentId && <Heading size="md" fontWeight="bold" color="gray.500" my={sharedPaymentId ? 0 : '8'}>Dados do Pagamento</Heading> }
      {loading && <Loader />}
    {showReceiptModal && <ReceiptSharedBillModal cancelFunction={() => setShowReceiptModal(false)} title="Anexo de comprovante de pagamento - Multi Pagamentos" cbFunction={handleCloseModal} payment_shared_id={paymentSharedId} />}
        {showPaidModal && (
        <AlertDialogCustom
          title="Confirmar Pagamento"
          isOpen={showPaidModal}
          cancelFunction={closeModal}
          nextFunction={handleConfirmPayment}
        />
        )}

        {!sharedPaymentId && (
          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mb="15">

          {list[0]?.bank?.pix && <Box>
              <Text color="wine.primary" fontWeight={500} align="center">Chave Pix</Text>
              <Text align="center">{list[0]?.bank?.pix}</Text>
          </Box>}

          {list[0]?.bank?.bank && (
            <>
              <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Banco</Text>
                <Text align="center">{list[0]?.bank?.bank}</Text>
              </Box>

              <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Agência</Text>
                <Text align="center">{list[0]?.bank?.agency}</Text>
              </Box>

              <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Conta</Text>
                <Text align="center">{list[0]?.bank?.account}</Text>
              </Box>

            </>
          )}

          </SimpleGrid>
        )}

        <Heading size="md" fontWeight="bold" color="gray.500" my={sharedPaymentId ? 5 : '10'}>{sharedPaymentId ? 'Todos os pagamentos efetuados:' : 'Todos os pagamentos em abertos:'}</Heading>

        <Accordion allowMultiple>
            {list?.map(item => (
                <AccordionItem key={item.id}>
                <Flex alignItems="center" flex={1}>
                  {!sharedPaymentId && (
                    <Box>
                    {allBillsCheckedToProcess.includes(`name-item-service_selected-${item.id}`) ? <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${item.id}`)} defaultChecked={true} isChecked={true} onChange={(e: any) => handleSelectBill(e, `name-item-service_selected-${item.id}`)}/> : <Checkbox key={Math.random()} colorScheme="pink" {...register(`name-item-service_selected-${item.id}`)} onChange={(e: any) => handleSelectBill(e, `name-item-service_selected-${item.id}`)} /> }
                    </Box>
                  )}

                  <AccordionButton flex={1}>
                    <Box as="span" flex={1} textAlign='left'>
                      {item.valueFormatted} - {item?.service?.name || typePaymentsOptions[item?.typeLabel]}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Flex>

                <AccordionPanel>
                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Dados Operacionais
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nº Processo/OS</Text>
                    <Text align="center">{item?.process?.cod_prefecture}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nº Proposta</Text>
                    <Text align="center">{item?.proposal?.number}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                    <Text align="center">{item?.net?.name}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                    <Text align="center">{item?.unit?.name}</Text>
                </Box>

              </SimpleGrid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Tipo</Text>
                  <Text align="center">{typePaymentsOptions[item?.typeLabel]}</Text>
              </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Serviço</Text>
                    <Text align="center">{item?.service?.name}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Valor</Text>
                    <Text align="center" fontWeight="bold">{item?.valueFormatted}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nome</Text>
                    <Text align="center">{item?.provider?.name}</Text>
                </Box>

                {/* <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Verificado</Text>
                    <Text align="center">{item?.provider?.active === 0 ? <Icon as={FiCheck} fontSize="24" color="#03ac13" /> : <Icon as={FiX} fontSize="24" color="#FF0000" />}</Text>
                </Box> */}

              </SimpleGrid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

              <Box />
              <Box />
              <Box />
              </SimpleGrid>

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Nota Fiscal / Comprovante de Execução
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                  {receipts?.find(i => i?.payment_id === item?.id)?.extension === 'pdf'
                    ? (
                      <a href={receiptService?.link} target="_blank" rel="noreferrer">
                        <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                          <Document file={receipts?.find(i => i?.payment_id === item?.id)?.link}>
                            <Page pageNumber={1} height={200} width={200} />
                          </Document>
                        </div>
                      </a>
                      )
                    : <Box w={250}>
                        <a href={receipts?.find(i => i?.payment_id === item?.id)?.link} target="_blank" rel="noreferrer">
                        <Image src={receipts?.find(i => i?.payment_id === item?.id)?.link} width={250} mb={8}/></a>
                      </Box>}

              </SimpleGrid>
                </AccordionPanel>

              </AccordionItem>
            ))}

            <Text color="wine.200" fontSize="18px" fontWeight="bold" marginTop="20px">Somatório Total: {totalSum}</Text>

        </Accordion>

        <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mt="80px" >

        <Box>
            <Text color="wine.primary" fontWeight={500} >Estado atual</Text>
            <Text fontWeight={700} fontSize={20} color={MakeColor(list[0]?.statusLabel || undefined)}>{statusOptions?.find(i => i.value === list[0]?.statusLabel)?.label }</Text>
        </Box>

        {list[0]?.statusLabel === 'REQUESTED' && <Select label='Ação de Pagamento' {...register('status')} options= {actionStatusOptions} defaultValue={list[0]?.statusLabel} onChange={handleChangePaymentStatus} />
        }

        </SimpleGrid>

        {sharedPaymentId && (
          <Box>
          <DragAndDrop
              ref={ReceiptPaymentRef}
              label="Comprovante de pagamento"

              payload={{
                name: 'Comprovante de pagamento',
                type: 'comprovante-pagamento'
              }}
              defaultCover={initialCoverReceiptPayment}
              id={files?.length ? files.find(i => i.type === 'comprovante-pagamento')?.id : null}
              saveFunction={initialCoverReceiptPayment ? undefined : async () => await handleSubmitReceiptPayment()}
              extraCleanFunction={() => setInitialCoverReceiptPayment(undefined)}
            />

          </Box>
        )}
      </>
  )
}

export { AccordionMultiPayments }
