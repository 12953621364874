/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'
import queryString from 'query-string'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AiFillEye, AiOutlineDelete } from 'react-icons/ai'
import { RiAddLine } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Can } from '../../Components/Can'
import { Input } from '../../Components/Form/Input'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { PaymentRefuseModal } from '../../Components/PaymentRefuseModal'
import { PaymentRequestModal } from '../../Components/PaymentRequestModal'
import { ReceiptPaymentModal } from '../../Components/ReceiptPaymentModal'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { PaymentsType } from '../BillsToPay'
import IProcessProps, { IEachPaymentProps, IPaymentStatus } from './Interfaces/ProcessIntefaces'
import { ModalNewBill } from './ModalNewBill'
import { useAuth } from '../../Hooks/Auth'
import { validateUserPermissions } from '../../utils/validateUserPermissions'
import { AlertDialogCustom } from '../../Components/AlertDialog'

const optionsPrefectures = [
  {
    value: 'SIMPROC',
    label: 'SIMPROC'
  },
  {
    value: 'SEI',
    label: 'SEI'
  }
]

interface ItemProposalProps {
  id: string
  executor_value: string
  executor_id: string
  service_id: string
  payment_service_id: string
  ticket_value: string
  expenses_value: string
}

export const allowedReceiptValues = ['PAID', 'REQUESTED', 'REFUSED', 'WAITING_PERMISSION']

const ProcessCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const [showAddressComponent, setShowAddressComponent] = useState(false)
  // const [items, setItems] = useState<ItemProps[]>([])
  const { user } = useAuth()
  const params = useParams()
  const toast = useToast()
  const navigation = useNavigate()
  const [processData, setProcessData] = useState<IProcessProps>({} as IProcessProps)
  const [selectedPayment, setSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [receiptSelectedPayment, setReceiptSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [refusedPayment, setRefusedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [selectedItem, setSelectedItem] = useState({} as IEachPaymentProps)
  const [showNewBillModal, setShowNewBillModal] = useState(false)
  const [billId, setBillId] = useState('')
  const location = useLocation()
  const parsed = queryString.parse(location.search)

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {

    },
    []
  )

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/process/${params?.id}`, {
        params: {
          paymentKind: 0
        }
      })

      const normalize = {
        ...data,
        payments_extra: data?.payments_extra?.map((i: any) => {
          return {
            ...i,
            created_at: format(new Date(i.created_at), 'dd/MM/yyyy HH:mm')
          }
        }),
        items: data?.items?.map((i: any) => {
          return {
            ...i,
            release_date: i?.release_date ? format(new Date(i?.release_date), 'dd/MM/yyyy') : 'Não informado'
          }
        })
      }

      setProcessData(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, params])

  useEffect(() => {
    load()
  }, [])

  const handlePayment = useCallback(async (obs?: string) => {
    const { id } = selectedPayment
    setLoading(true)
    try {
      const { data } = await api.patch(`/payments/${id}?kind=0`, { obs })

      const wasAgended = data?.payment?.allowed_at
      const payment_id = data?.payment?.id

      setSelectedPayment({} as IEachPaymentProps)

      return { payment_id, wasAgended }
    } catch (error) {
      setLoading(false)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedPayment({} as IEachPaymentProps)
    }
  }, [selectedPayment, toast])

  const closeModal = useCallback(() => {
    setSelectedPayment({} as IEachPaymentProps)
  }, [])

  const makePaymentButtonColor = useCallback((statusLabel: IPaymentStatus, removed?: boolean) => {
    if (removed) {
      return 'black'
    }

    if (statusLabel === 'CREATED') {
      return 'pink.500'
    } else if (statusLabel === 'REQUESTED') {
      return 'teal.500'
    } else if (statusLabel === 'REFUSED') {
      return 'red.500'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return 'yellow.500'
    } else {
      return 'gray.500'
    }
  }, [])

  const makeLabelPaymentTaxOrRefund = useCallback((statusLabel: PaymentsType, date: string | null) => {
    if (statusLabel === 'TAX_NOT_REFUNDABLE') {
      return 'Taxa NÃO reembolsável'
    } else if (statusLabel === 'TAX_REFUNDABLE') {
      return 'Taxa reembonsável'
    } else if (statusLabel === 'REFUND_NOT_REFUNDABLE') {
      return 'Despesas Prestador NÃO reembolsável'
    } else {
      // REFUND_REFUNDABLE
      return 'Despesas Prestador reembolsável'
    }
  }, [])

  const makeLabelPayment = useCallback((statusLabel: Omit<IPaymentStatus, 'HONORARY'>, date: string | null, removed?: boolean) => {
    if (removed) {
      return 'Item Cancelado'
    }

    if (statusLabel === 'CREATED') {
      return 'Solicitar Pagamento'
    } else if (statusLabel === 'REQUESTED') {
      return 'Pagamento Solicitado'
    } else if (statusLabel === 'REFUSED') {
      return 'Pagamento Recusado'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return `Agendado: ${date ?? ''}`
    } else {
      return 'Pagamento efetuado'
    }
  }, [])

  const makeButtonFunction = useCallback((payment: IEachPaymentProps, type: 'tax-or-refund' | 'honorary', removed?: boolean) => {
    if (removed) {
      return () => setSelectedItem(payment)
    }

    if (payment.statusLabel === 'CREATED') {
      if (type === 'honorary') {
        return () => setSelectedPayment(payment)
      } else {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      }
    }

    if (payment.statusLabel === 'REFUSED') {
      return () => setRefusedPayment(payment)
    }

    if (payment.statusLabel === 'REQUESTED') {
      if (type === 'honorary') {
        return () => setSelectedPayment(payment)
      } else {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      }
    }

    if (payment.statusLabel === 'PAID') {
      const hasPermission = validateUserPermissions({ user, permissions: ['payments_edit.all'] })
      if (hasPermission) {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      } else {
        return () => {}
      }
    }

    return () => {}
  }, [params, navigation, user])

  const handleReprocessPayment = useCallback(async () => {
    try {
      setLoading(true)

      await api.put(`/payments/${refusedPayment?.id}`, {
        status: 'CREATED'
      })

      toast({
        title: 'Pagamento Resetado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setRefusedPayment({} as IEachPaymentProps)
      setLoading(false)
    }
  }, [toast, load, refusedPayment])

  const handleOpenTaxOrRefund = useCallback((bill_id: string) => {
    setBillId(bill_id)
    setShowNewBillModal(true)
  }, [])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/payments/${id}`, {
        params: {
          revert: selectedItem.deleted_at ? true : null
        }
      })

      toast({
        title: selectedItem.deleted_at ? 'Reativado com sucesso' : 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as IEachPaymentProps)
      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as IEachPaymentProps)
    }
  }, [selectedItem, toast, load])

  const closeModalDeletePayment = useCallback(() => {
    setSelectedItem({} as IEachPaymentProps)
  }, [])

  return (
    <Can permissions={['proccess.list', 'proccess.create', 'proccess.all']} page>
       {showNewBillModal && <ModalNewBill isOpen={showNewBillModal} id={billId} title="Solicitação de Pagamento Avulso" cancelFunction={() => {
         setBillId('')
         setShowNewBillModal(false)
       }} nextFunction={() => {
         setShowNewBillModal(false)
         load()
       }} />}
    <Box>
      {loading && <Loader />}
      <Header />

      {!!selectedItem?.id && (
        <AlertDialogCustom
          title={selectedItem.deleted_at ? 'Reativar Pagamento' : 'Cancelar Pagamento'}
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModalDeletePayment}
          nextFunction={hanleDelete}
        />
      )}

      {
        !!selectedPayment?.id && (
        <PaymentRequestModal
          title={selectedPayment?.statusLabel === 'REQUESTED' ? 'Atualizar Solicitação' : 'Solicitar Pagamento'}
          isOpen={!!selectedPayment?.id}
          cancelFunction={closeModal}
          nextFunction={handlePayment}
          cbFunction={async () => {
            await load()
            setLoading(false)
          }}
          type={selectedPayment?.statusLabel}
          payment={selectedPayment}
        />
        )
      }

      {
        !!receiptSelectedPayment?.id && (
          <ReceiptPaymentModal
          title="Recibos"
          isOpen={!!receiptSelectedPayment?.id}
          cancelFunction={() => setReceiptSelectedPayment({} as IEachPaymentProps)}
          nextFunction={() => {}}
          cbFunction={async () => await load()}
          files={receiptSelectedPayment.files}
          />
        )
      }

      {!!refusedPayment?.id && (
        <PaymentRefuseModal
          title="Pagamento Recusado"
          isOpen={!!refusedPayment?.id}
          cancelFunction={() => setRefusedPayment({} as IEachPaymentProps)}
          nextFunction={handleReprocessPayment}
          refused
          initialRefusedReason={refusedPayment?.refused_reason}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Processos
          </Heading>

          <Tabs variant='enclosed' mt="10" defaultIndex={Number(parsed?.tab) || 0}>
            <TabList>
              <Tab>Proposta e Pagamentos</Tab>
              <Tab>Taxas e Reembolsos</Tab>
              <Tab>Processo</Tab>
            </TabList>
            <TabPanels>

              <TabPanel>

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Dados Cadastrais
                </Heading>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                      <Text align="center">{processData?.technical?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                      <Text align="center">{processData?.financial?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                      <Text align="center">{processData?.net?.name}</Text>
                  </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                      <Text align="center">{processData?.unit?.name}</Text>
                  </Box>

                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Total</Text>
                  <Text align="center">{processData?.immobile?.area_ground || '--'}</Text>
                </Box>

                <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Construída</Text>
                  <Text align="center">{processData?.immobile?.area_building || '--'}</Text>
                </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Itens da Proposta
                </Heading>

                {processData?.items?.map((item, indexItem: number) => (
                    <VStack spacing={['9', '9']} mb="5" bg={'wine.primary'} p="10" key={item.id} borderRadius="8px">

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">
                          <Box>
                            <Text color="white.primary" fontWeight={500} align="left">Serviço</Text>
                            <Text align="left" color="white.primary">{item?.service?.name}</Text>
                          </Box>
                      </SimpleGrid>

                      <Divider color="white" />

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                            <Text align="center" color="white.primary">{item?.executor?.name}</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                            <Text align="center" color="white.primary">{item?.installments}x</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Valor Total</Text>
                            <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                          </Box>

                          <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Previsão de Entrega</Text>
                         <Text align="center" color="white.primary">{item?.release_date}</Text>
                       </Box>

                      </SimpleGrid>

                      <Divider />
                        <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                        <Box>
                              <Text color="white.primary" fontWeight={500} align="left">Observações</Text>
                              <Text align="left" color="white.primary">{item?.obs || '--'}</Text>
                            </Box>

                      </SimpleGrid>

                        <Divider color="white" />

                        {processData?.payments[item.id]?.map(payment => (
                          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" key={payment.id}>

                            <Box>
                              <Text color="white.primary" fontWeight={500} align="center">Parcela {payment.installment}: {payment.provider_value_installment_formatted}</Text>

                            </Box>

                            {allowedReceiptValues.includes(payment.statusLabel) && !payment.deleted_at
                              ? (
                              <Button
                                type="button"
                                onClick={() => setReceiptSelectedPayment(payment)}
                                bg="blue.400"
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"
                                leftIcon={<Icon as={AiFillEye} fontSize="19" />}

                              >
                                Recibos
                            </Button>
                                )
                              : <Box />}

                            <Flex>
                              <Button
                                  type="button"
                                  onClick={makeButtonFunction(payment, 'honorary', !!payment.deleted_at)}
                                  bg={makePaymentButtonColor(payment.statusLabel, !!payment.deleted_at)}
                                  _hover={{ bgColor: 'gray.500' }}
                                  color="white"
                                  minWidth="250px"
                                  marginRight="10px"

                                >
                                  {makeLabelPayment(payment.statusLabel, payment.allowed_at_formatted, !!payment.deleted_at)}
                              </Button>
                              {!payment.deleted_at && (
                                <IconButton
                                colorScheme="red"
                                aria-label="Edit Content"
                                icon={<Icon as={AiOutlineDelete} size="30" />}
                                onClick={() => setSelectedItem(payment)}

                              />
                              )}

                            </Flex>
                          </SimpleGrid>
                        ))}

                    </VStack>
                ))}
              </TabPanel>

              <TabPanel>
                <Flex justifyContent="flex-end">
                <Button
                    // onClick={() => setShowNewBillModal(true)}

                    as="a"
                    size="sm"
                    fontSize="sm"
                                href={`/tax-or-refund/create/${params?.id}`}
                    bg="wine.200"
                    colorScheme="wine"
                    ml="auto"
                    leftIcon={
                      <Icon as={RiAddLine} fontSize="16" />
                    }
                  >
                  Adicionar nova conta avulsa
                </Button>
                </Flex>

                <Table colorScheme="blue" mt="25px">
                <Thead>
                  <Tr>

                    <Th textAlign="center" fontSize={12}>Tipo</Th>
                    <Th textAlign="center" fontSize={12}>Valor</Th>

                    <Th textAlign="center" fontSize={12}>Data</Th>
                    <Th textAlign="center" fontSize={12}>Ações</Th>
                    {/* <Th textAlign="center" fontSize={12}>Status</Th> */}

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {processData?.payments_extra?.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize={14}>
                            <Text fontWeight="bold">{makeLabelPaymentTaxOrRefund(i.typeLabel, i.allowed_at)}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize={14}>
                            <Text fontWeight="bold">{i.fee_refund_value_formatted}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" fontSize={14}>
                            <Text fontWeight="bold" textAlign="center">{i?.created_at}</Text>
                          </Link>
                      </Td>

                      <Td>

                            {allowedReceiptValues.includes(i.statusLabel)
                              ? (
                              <Button
                                type="button"
                                onClick={() => setReceiptSelectedPayment(i)}
                                bg="blue.400"
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"
                                leftIcon={<Icon as={AiFillEye} fontSize="19" />}
                                style={{ marginRight: 10 }}

                              >
                                Recibos
                            </Button>
                                )
                              : <Box />}

                            <Button
                                type="button"
                                 onClick={makeButtonFunction(i, 'tax-or-refund')}
                                // onClick={() => console.log('item: ', i)}
                                bg={makePaymentButtonColor(i.statusLabel)}
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"

                              >
                                {makeLabelPayment(i.statusLabel, i.allowed_at_formatted)}
                            </Button>

                              {/* <Button

                                as="button"
                                size="sm"
                                fontSize="sm"
                                onClick={() => handleOpenTaxOrRefund(i?.id)}
                                bg="wine.200"
                                colorScheme="wine"
                                leftIcon={
                                  <Icon as={RiEye2Fill} fontSize="16" />
                                }
                              >
                              Visualizar
                            </Button> */}
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>

              </TabPanel>

              <TabPanel>
                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="10">
                        <Input

                                  label="Número do processo na Prefeitura"
                                  placeholder="Preenchar o número"
                                  // errors={errors.name}
                                  {...register('name-item-')}
                                />

                        <Select

                                  label="Selecionar Prefeitura"
                                  placeholder="Selecionar"
                                  // errors={errors.gender}
                                  {...register('executante-item-')}
                                  options={optionsPrefectures}
                                  />
                  </SimpleGrid>

                  <Button
                  type="submit"
                  bg="wine.primary"
                  _hover={{ bgColor: 'gray.500' }}
                  color="white"
                  isLoading={formState.isSubmitting}
                  mt="10"
                  >
                    Salvar
                  </Button>
              </TabPanel>

            </TabPanels>
          </Tabs>

        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ProcessCreate }
