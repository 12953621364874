import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { FaUmbrellaBeach } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { CollaboratorProps } from '../Collaborators'
import { departmentOptions } from '../Collaborators/create'
import { Input } from '../../Components/Form/Input'

const VacationsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CollaboratorProps>({} as CollaboratorProps)
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const params = useParams()
  const [type, setType] = useState<'department' | 'person' | undefined>(undefined)
  const [collaboratorsSelected, setCollaboratorSelected] = useState<any>()
  const toast = useToast()
  const [confirmation, setConfirmation] = useState(false)

  const { register, getValues, formState, setValue } = useForm({

  })

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/collaborators/${params?.collaborator_id}`)

      setData(data)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [params])

  const loadAllCollaborators = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setCollaborators(normalize)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load()
    loadAllCollaborators()
  }, [])

  const handleChoice = useCallback((e: any) => {
    console.log('e: ', e)
    setType(e?.target?.value)
  }, [])

  const handleSelectPerson = useCallback((e: any) => {
    const getCollaborator = collaborators?.find(i => i.value === e)

    if (getCollaborator) {
      setCollaboratorSelected(getCollaborator)
    }
  }, [collaborators])

  const handleVacations = useCallback(async () => {
    setLoading(true)
    try {
      if (!type) {
        toast({
          title: 'Selecione uma opção de quem deverá ficar com os processos deste colaborador.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      const values = getValues()
      console.log('values: ', values)

      if (type === 'department' && !values?.department) {
        toast({
          title: 'Selecione o departamento que deverá ficar com os processos deste colaborador.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      if (type === 'person' && !collaboratorsSelected?.value) {
        toast({
          title: 'Selecione o colaborador que deverá ficar com os processos.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      await api.patch(`/collaborators/vacations/${params?.collaborator_id}`, {
        type,
        target: type === 'person' ? collaboratorsSelected?.value : values?.department
      })

      setConfirmation(false)

      toast({
        title: 'Férias aplicadas.',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })
    } catch (error) {
      console.log('erro ao aplicar as férias: ', error)
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [params, toast, type, getValues, collaboratorsSelected])

  const handleSearchCollaborators = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setCollaborators(normalize)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    }
  }, [])

  return (
    <Box>

{!!confirmation && (
        <AlertDialogCustom
          title="Executar Férias"
          isOpen={!!confirmation}
          cancelFunction={() => setConfirmation(false)}
          nextFunction={handleVacations}
        />
)}

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Férias
            </Heading>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Loader />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar</Text>
            </Flex>
                )
              : (
            <>
              <Box>
                <Text>{`Durante o período de férias, todos os processos de ${data?.name} deverão ser executados por: `}</Text>

                <Select
                  label=""
                  title="Selecionar"
                  my="8"
                  options={[
                    {
                      value: '',
                      label: 'Selectionar uma opção'
                    },
                    {
                      value: 'person',
                      label: 'Por uma pessoa específica'
                    }, {
                      value: 'department',
                      label: 'Por um departamento'
                    }]}

                  {...register('option')}
                  onChange={e => handleChoice(e)}

                />

                {type === 'department' && (
                  <Select
                  label="Departamento"
                  title="Selecionar Departamento"
                  options={[{
                    label: 'Escolher',
                    value: ''
                  }, ...departmentOptions]}

                  {...register('department')}

                />
                )}

                {type === 'person' && (
                  <>
                  <InputModalSearch title="Colaborador" searchFunction={handleSearchCollaborators} data={collaborators} extraFunction={handleSelectPerson} placeholderButton={collaboratorsSelected?.value ? 'Trocar' : 'Selecionar'} />
                  <Input {...register('person')} isReadOnly value={collaboratorsSelected?.value} visibility="hidden" />
                  </>

                )}

                {collaboratorsSelected?.value && type === 'person' && (
                  <Box mt="8">
                    <Text fontWeight="bold" color="wine.primary">Colaborador Selecionado:</Text>
                    <Text>{collaboratorsSelected?.label}</Text>
                  </Box>
                )}

              </Box>

            </>
                )}

              <Button
              my="8"
                width={200}
                type="button"
                size="sm"
                fontSize="sm"
                  onClick={() => setConfirmation(true)}
                colorScheme="blue"
                leftIcon={
                  <Icon as={FaUmbrellaBeach} fontSize="16" />
                }
              >
               Executar Férias
              </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export { VacationsCreate }
