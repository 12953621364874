import React, { FC, useState, useEffect } from 'react'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import { Flex, SimpleGrid, Box, Text, theme } from '@chakra-ui/react'

import { useAuth } from '../../Hooks/Auth'
import { TilesBox } from '../../Components/Tiles/TilesBox'
import { FiUsers } from 'react-icons/fi'
import { MdVideoCall, MdAttachMoney, MdRemoveRedEye, MdOutlineBusinessCenter } from 'react-icons/md'
import api from '../../Services/api'
import { BsNewspaper } from 'react-icons/bs'

interface DataProps {
  users: number
  videos: number
}

const Dashboard: FC = () => {
  const [data, setData] = useState({} as DataProps)

  useEffect(() => {
    api.get('/overview').then(resp => {
      const dados = resp.data

      setData(dados)
    }).catch(error => console.log('error useEffect AuthProvider: ', error))
  }, [])

  return (
    <Flex direction="column" h="100vh" bg="wine.primary">
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <SimpleGrid
          flex="1"
          gap="4"
          minChildWidth="320px"
          alignItems="flex-start"
        >
          <TilesBox
            title="Prestadores"
            description="Total de prestadores na plataforma."
            icon={FiUsers}
            value="2879"
          />

          <TilesBox
            title="Colaboradores"
            description="Total de colaboradores na plataforma."
            icon={FiUsers}
            value="2879"
          />

          <TilesBox title="Redes" description="Total de Redes na plataforma." icon={MdOutlineBusinessCenter} value="90" iconStyle={{ fontSize: 60 }} />

          <TilesBox title="Unidades" description="Total de Unidades na plataforma." icon={BsNewspaper} value="260" />

          <TilesBox
            title="Serviços"
            description="Total de tipos de serviços."
            icon={MdOutlineBusinessCenter}
            value="45"
          />

<TilesBox
            title="Pré-Propostas"
            description="Total de pré-propostas na plataforma."
            icon={MdAttachMoney}
            value="490"
          />
{/*
          <TilesBox
            title="Ofertas"
            description="Total de Ofertas"
            icon={MdRemoveRedEye}
            value="175"
          />

          <TilesBox
            title="Hoje"
            description="Total de faturamento Hoje."
            icon={MdAttachMoney}
            value="980,00"
          />

          <TilesBox
            title="Mês"
            description="Total de faturamento este mês."
            icon={MdAttachMoney}
            value="22.000,00"
          />

          <TilesBox
            title="Ano"
            description="Total de faturamento Ano."
            icon={MdAttachMoney}
            value="180.000,00"
          /> */}

          {/* <Box
                        p={["6","8"]}
                        bg="gray.800"
                        borderRadius={8}
                        pb="4"
                    >
                        <Text fontSize="lg" mb="4">
                            Inscritos da semana
                        </Text>

                        <Chart options={options} series={series} type="area" height={160} />
                    </Box>

                    <Box
                        p={["6","8"]}
                        bg="gray.800"
                        borderRadius={8}
                        pb="4"
                    >
                        <Text fontSize="lg" mb="4">
                            Taxa de abertura
                        </Text>
                        <Chart options={options} series={series} type="area" height={160} />
                    </Box> */}
        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

export { Dashboard }
