const maskCNPJ = (cnpj: string): string => {
  if (!cnpj) return ''

  cnpj = cnpj.replace(/\D/g, '')

  // Adiciona os separadores e formata o CNPJ
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2')
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2')
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2')

  return cnpj
}

export { maskCNPJ }
