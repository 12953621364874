import { Flex, Box, Avatar, Text } from '@chakra-ui/react'
import { useAuth } from '../../Hooks/Auth'
import { adminVersion } from '../../Services/api'

interface ProfileProps {
  showProfileData?: boolean
}
export function Profile ({ showProfileData = true }: ProfileProps) {
  const { user } = useAuth()

  return (
        <Flex align="center">
            {showProfileData && (
                 <Box
                 mr="4" textAlign="right">
                 <Text color="white">{user.name}</Text>
                 <Text color="white" fontSize="small">
                     {user.email}
                 </Text>
                 <Text color="white" fontSize="small">Versão: {adminVersion}</Text>
             </Box>
            )}

        <Avatar size="md" name="Diego Hennrich"/>
    </Flex>
  )
}
