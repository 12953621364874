import React, { FC, useCallback, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  SimpleGrid,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'
import { Checkbox } from '../Form/Checkbox'
import NotificationsProps from '../../Pages/Notifications/Interfaces/NotificationsProps'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATIONS_TYPE_LABEL } from '../../Pages/Notifications'

interface Props {
  isOpen: boolean
  nextFunction: (data?: any) => any
  notification: NotificationsProps
}

const NotificationModal: FC<Props> = ({
  isOpen,
  nextFunction,
  notification
}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const { register, handleSubmit, formState } = useForm({

  })
  const navigate = useNavigate()
  const toast = useToast()
  const { user, updateGlobalUser } = useAuth()

  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)

      try {
        // await api.patch('/collaborators/terms')

        // await updateGlobalUser({ terms: 1 })
        setLoading(false)

        if (notification?.type === 'provider-create-boss-sector') {
          navigate('/notifications')
        }

        if (notification?.type === 'pre-proposal-approvement-boss-sector') {
          navigate(`/pre-proposal/create/${notification?.params?.proposal_id}?notification_id=${notification?.id}&action=${notification?.type}`)
        }

        if (notification?.type === 'pre-proposal-updated-boss-sector') {
          navigate(`/pre-proposal/create/${notification?.params?.proposal_id}?notification_id=${notification?.id}&action=${notification?.type}`)
        }

        nextFunction()
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [nextFunction, toast, navigate, notification]
  )

  const handleSelectTerms = useCallback((e: any) => {
    const checked = e?.target?.checked

    if (checked) {
      setTermsChecked(true)
    } else {
      setTermsChecked(false)
    }
  }, [])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
    onClose={() => {}}
      isCentered
      size={'6xl'}
    >
        {loading && <Loader />}
      <AlertDialogOverlay>
      <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          p="8"
        >
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" >
           <Text fontSize="40px" fontWeight="bold" color="red">
           Aviso Importante!
            </Text>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize="18px" fontWeight="bold">
            {notification?.message}
            </Text>

          </AlertDialogBody>

          <AlertDialogFooter>

            <Button
            type='submit'
              colorScheme={'blue'}

              ml={3}
            >
             Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { NotificationModal }
