/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  InputGroup,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Can } from '../../Components/Can'
import { Checkbox } from '../../Components/Form/Checkbox'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { TextArea } from '../../Components/Form/TextArea'
import { Header } from '../../Components/Header'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { maskCNPJ } from '../../utils/maskCNPJ'
import { removeNonNumeric } from '../../utils/removeNonNumeric'

interface INetProps {

  'id': string
  'name': string
  'razao_social': string
  'internal_code': string
  'cnpj': string
}
interface contactProps {
  'name': string
  'email': string
  'phone': string
  'department': string
}

interface OptionsProps {
  label: string
  value: string
}

interface CepProps {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade?: string
  uf: string
}

const createUnitFormSchema = Yup.object().shape({
  name: Yup.string(),
  name_approvation: Yup.string(),
  cod: Yup.string().required('Código da unidade é obrigatório'),

  cep: Yup.string().required('Cep é obrigatório'),
  street: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a Rua')
    }),
  number: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o número')
    }),
  neighborhood: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a bairro')
    }),
  city: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha a cidade')
    }),
  state: Yup.string()
    .ensure()
    .when('cep', {
      is: (exists: any) => exists,
      then: Yup.string().required('Preencha o Estado')
    })
})

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  login: Yup.string().required('Login é obrigatório'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ),
  gym_id: Yup.string().required('Selecione uma unidade')
})

interface HandleSubmitFormProps {
  byPassAddress: boolean
  byPassName: boolean
}

const UnitiesCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsNets, setOptionsNets] = useState<OptionsProps[]>([])
  const [selectedNet, setSelectedNet] = useState<INetProps>({} as INetProps)
  const [allNets, setAllNets] = useState<INetProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const [nextCode, setNextCode] = useState(0)
  const [alertExistingAddress, setAlertExistingAddress] = useState('')
  const [alertExistingName, setAlertExistingName] = useState('')

  const SubPrefecturesOptions = [
    {
      value: 'none',
      label: 'Não se aplica'
    },
    {
      value: 'AF - Aricanduva',
      label: 'AF - Aricanduva'
    },
    {
      value: 'BT - Butantã',
      label: 'BT - Butantã'
    },
    {
      value: 'CL - Campo Limpo',
      label: 'CL - Campo Limpo'
    },
    {
      value: 'CS - Capela do Socorro',
      label: 'CS - Capela do Socorro'
    },
    {
      value: 'CV - Casa Verde',
      label: 'CV - Casa Verde'
    },
    {
      value: 'AD - Cidade Ademar',
      label: 'AD - Cidade Ademar'
    },
    {
      value: 'CT - Cidade Tiradentes',
      label: 'CT - Cidade Tiradentes'
    },
    {
      value: 'EM - Ermelino Matarazzo',
      label: 'EM - Ermelino Matarazzo'
    },
    {
      value: 'FO - Freguesia/Brasilândia',
      label: 'FO - Freguesia/Brasilândia'
    },
    {
      value: 'RG - Guaianases',
      label: 'RG - Guaianases'
    },
    {
      value: 'IP - Ipiranga',
      label: 'IP - Ipiranga'
    },
    {
      value: 'IT - Itaim Paulista',
      label: 'IT - Itaim Paulista'
    },
    {
      value: 'IQ - Itaquera',
      label: 'IQ - Itaquera'
    },
    {
      value: 'JA - Jabaquara',
      label: 'JA - Jabaquara'
    },
    {
      value: 'JT - Jaçanã / Tremembé',
      label: 'JT - Jaçanã / Tremembé'
    },
    {
      value: 'LA - Lapa',
      label: 'LA - Lapa'
    },
    {
      value: "MB - M'Boi Mirim",
      label: "MB - M'Boi Mirim"
    },
    {
      value: 'MO - Mooca',
      label: 'MO - Mooca'
    },
    {
      value: 'PA - Parelheiros',
      label: 'PA - Parelheiros'
    },
    {
      value: 'PE - Penha',
      label: 'PE - Penha'
    },
    {
      value: 'PR - Perus',
      label: 'PR - Perus'
    },
    {
      value: 'PI - Pinheiros',
      label: 'PI - Pinheiros'
    },
    {
      value: 'PJ - Pirituba / Jaraguá',
      label: 'PJ - Pirituba / Jaraguá'
    },
    {
      value: 'ST - Santana / Tucuruvi',
      label: 'ST - Santana / Tucuruvi'
    },
    {
      value: 'SA - Santo Amaro',
      label: 'SA - Santo Amaro'
    },
    {
      value: 'SP - Sapopemba',
      label: 'SP - Sapopemba'
    },
    {
      value: 'SM - São Mateus',
      label: 'SM - São Mateus'
    },
    {
      value: 'MP - São Miguel Paulista',
      label: 'MP - São Miguel Paulista'
    },
    {
      value: 'SE - Sé',
      label: 'SE - Sé'
    },
    {
      value: 'MG - Vila Maria / Vila Guilherme',
      label: 'MG - Vila Maria / Vila Guilherme'
    },
    {
      value: 'VM - Vila Mariana',
      label: 'VM - Vila Mariana'
    },
    {
      value: 'VP - Vila Prudente',
      label: 'VP - Vila Prudente'
    }
  ]

  const [fakeCep, setFakeCep] = useState('')
  const [isSP, setIsSP] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    resolver: yupResolver(createUnitFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm = useCallback(
    async () => {
      const values = getValues()

      if (!selectedNet?.id) {
        toast({
          title: 'Selecione uma Empresa(rede)',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const {
        name,
        name_approvation,
        cod,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        cnpj,
        obs,
        area_ground,
        area_building,
        iptu,
        zoning,
        sub_prefecture
      } = values

      if (state && state === 'SP' && !sub_prefecture) {
        toast({
          title: 'Selecione a Subprefeitura',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        net_id: selectedNet.id,
        name,
        name_approvation,
        cod,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state,
        cnpj,
        obs,
        area_ground,
        area_building,
        iptu,
        zoning,
        sub_prefecture

      }

      const id = params?.unit_id

      try {
        id
          ? await api.put(`/units/${id}`, payload)
          : await api.post('/units', payload)

        toast({
          title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
        navigate('/unities')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, params?.unit_id, navigate, selectedNet, getValues]
  )

  const HandleValidationsForm: SubmitHandler<any> = useCallback(
    async ({ byPassAddress, byPassName }: HandleSubmitFormProps) => {
      if (byPassAddress) {
        setAlertExistingAddress('')
      }

      if (byPassName) {
        setAlertExistingName('')
      }
      const values = getValues()

      if (!selectedNet?.id) {
        toast({
          title: 'Selecione uma Empresa(rede)',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const {
        name,
        street,
        cep,
        number,
        complement,
        neighborhood,
        city,
        state

      } = values

      const payload = {
        name,
        net_id: selectedNet?.id,
        address: {
          street,
          cep,
          number,
          complement,
          neighborhood,
          city,
          state
        }

      }
      console.log('payload: ', payload)

      try {
        const id = params?.unit_id

        if (!byPassAddress) {
          const resp = id ? await api.post(`/check/units?data=address&unit_id=${id}`, payload) : await api.post('/check/units?data=address', payload)
          console.log('retorno check address: ', resp.data)
          if (resp.data?.exists) {
            setAlertExistingAddress(`Já existe um endereço cadastrado em: ${street}, ${number}, ${complement}, ${neighborhood} - ${city}/${state}`)
            return
          }
        }

        if (!byPassName) {
          const respCheckName = id ? await api.post(`/check/units?data=name&unit_id=${id}`, payload) : await api.post('/check/units?data=name', payload)
          console.log('retorno check name: ', respCheckName.data)
          if (respCheckName.data?.exists) {
            setAlertExistingName(`Já existe uma unidade nesta rede com o nome: ${name} .`)
            return
          }
        }

        await handleSubmitForm()
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      }
    },
    [toast, selectedNet, handleSubmitForm, params, getValues]
  )

  const loadItem = useCallback(async (unit_id: string) => {
    try {
      const { data } = await api.get(`/units/${unit_id}`)

      const { net } = data

      console.log('data: ', data)
      setValue('name', data?.name || '')
      setValue('name_approvation', data?.name_approvation || '')
      setValue('cod', data?.cod || '')
      setValue('cnpj', data?.cnpj || '')

      if (data?.address?.cep) {
        const { address } = data
        const { cep, street, number, complement, neighborhood, city, state } = address
        setShowAddressComponent(true)
        setValue(
          'cep',
          String(`${cep}`.replace(/([^0-9]+)/g, ''))
        )
        setValue('street', street)
        setValue('number', number)
        if (complement) {
          setValue('complement', complement)
        }
        setValue('neighborhood', neighborhood)
        setValue('city', city)
        setValue('state', state)

        if (address?.sub_prefecture) {
          setValue('sub_prefecture', address?.sub_prefecture)

          setIsSP(true)
        }
      }

      setValue('obs', data?.obs || '')

      if (data?.immobile) {
        const { immobile } = data
        const { area_ground, area_building, iptu, zoning } = immobile
        setValue('area_ground', area_ground || '')
        setValue('area_building', area_building || '')
        setValue('iptu', iptu || '')
        setValue('zoning', zoning || '')
      }

      const makeNet: INetProps = {
        id: net.id,
        name: net.name,
        razao_social: net.razao_social,
        cnpj: net.cnpj,
        internal_code: net.internal_code
      }

      setSelectedNet(makeNet)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  const loadNets = useCallback(async () => {
    try {
      const resp = await api.get('/nets', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setAllNets(resp.data.data)
      setOptionsNets(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const CheckLastCode = useCallback(async (net_id: string) => {
    try {
      const { data } = await api.patch(`/units/${net_id}`)

      // setNextCode(data.nextUnity)
      setValue('cod', data.nextUnity)
    } catch (error) {
      console.log('error CheckLastCode: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue])

  useEffect(() => {
    void loadNets()
  }, [])

  useEffect(() => {
    if (params?.unit_id) {
      loadItem(params?.unit_id)
    }
  }, [params])

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = removeNonNumeric(e?.target?.value)

      if (Number(cep.length) === 8) {
        setLoading(true)

        try {
          const response = await api.get(`/cep/${cep}`)
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data

          if (uf === 'SP') {
            setIsSP(true)
          }

          setShowAddressComponent(true)

          setValue('street', logradouro)
          setValue('cep', cep)
          setValue('neighborhood', bairro)
          setValue('state', uf)
          setValue('city', localidade)

          if (complemento) {
            setValue('complement', complemento)
          }
        } catch (error) {
          console.log('error users cep: ', error)
          toast({
            title: error.response.data.message,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [setValue, toast]
  )

  const handleSelectNet = useCallback(async (net_id: string) => {
    const findNet = allNets.find(i => i.id === net_id)

    if (findNet != null) {
      setSelectedNet(findNet)
      setValue('cnpj', findNet?.cnpj || '')
      await CheckLastCode(findNet?.id)
    }
  }, [allNets, CheckLastCode, setValue])

  const handleTypeAddress = useCallback((e: any) => {
    console.log('e: ', e.target.checked)

    if (e.target.checked) {
      setIsSP(true)
    } else {
      setIsSP(false)
    }
  }, [])

  const handleSearchNets = useCallback(async (e: string) => {
    try {
      const resp = await api.get('/nets', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })
      const data: any[] = resp.data.data

      const options = data.map((i: any) => ({
        label: i.name,
        value: i.id
      }))

      setOptionsNets(options)
    } catch (error) {
      console.log('error gymm: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  return (
    <Can permissions={['units.create', 'units.all']} page>
    <Box>
      <Header />
      {loading && <Loader />}

      {alertExistingAddress && <AlertDialogCustom isOpen={!!alertExistingAddress} title="ATENÇÃO!" description={alertExistingAddress} cancelFunction={() => setAlertExistingAddress('')} nextFunction={async () => await HandleValidationsForm({
        byPassAddress: true

      })} nextText="Prosseguir mesmo assim" nextScheme='blue' />}

      {alertExistingName && <AlertDialogCustom isOpen={!!alertExistingName} title="ATENÇÃO!" description={alertExistingName} cancelFunction={() => setAlertExistingName('')} nextFunction={async () => await HandleValidationsForm({
        byPassName: true,
        byPassAddress: true
      })} nextText="Prosseguir mesmo assim" nextScheme='blue' />}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
            onSubmit={handleSubmit(HandleValidationsForm)}
          // onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar nova Unidade
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais

            <Text size="sm" fontSize="14">Data de inclusão: {format(new Date(), 'dd/MM/yyyy ')}</Text>
          </Heading>

          <VStack spacing={['12', '12']}>
            <SimpleGrid spacing={['3', '3']} w="100%" minChildWidth="240px">
            <InputModalSearch title="Empresa" searchFunction={handleSearchNets} data={optionsNets} extraFunction={handleSelectNet} placeholderButton={selectedNet?.id ? 'Trocar Empresa' : 'Selecionar Empresa'} />
            <Input {...register('net_id')} value={selectedNet?.id} isReadOnly visibility="hidden" />

              {selectedNet?.id && (
                <>
                <Heading size="md" fontWeight="normal" color="wine.primary">
                  Cliente:
                  <Text size="sm" fontWeight="bold" fontSize="16">{selectedNet?.name}</Text>
                </Heading>

                <Heading size="md" fontWeight="normal" color="wine.primary">
                  CNPJ:
                  <Text size="sm" fontWeight="bold" fontSize="16">{maskCNPJ(selectedNet?.cnpj)}</Text>
                </Heading>
                </>
              )}

            </SimpleGrid>

            <SimpleGrid spacing={['6', '6']} w="100%" minChildWidth="240px">

            <InputGroup>

              <Input
                label="Código Unidade"
                placeholder="Preencha o código"
                errors={errors.cod}
                leftAddon={!!selectedNet?.internal_code}
                leftAddonText={selectedNet?.internal_code ? `${selectedNet?.internal_code}-` : ''}
                {...register('cod')}
              />

              </InputGroup>

              <Input
                label="Nome da Unidade - Viabilidade"
                placeholder="Preencha o nome"
                errors={errors.name}
                {...register('name')}
              />

<Input
                label="Nome da Unidade - Aprovação"
                placeholder="Preencha o nome"
                errors={errors.name_approvation}
                {...register('name_approvation')}
              />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados de Endereço
          </Heading>

          <VStack spacing={['6', '8']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              {/* <Select name="gender" label="Gênero" placeholder="Selecionar Gênero" errors={errors.gender} {...register('gender')} options={optionsGender} /> */}

              <InputMaskCustom
                type="text"
                label="CEP"
                errors={errors.cep}
                {...register('cep')}
                placeholder="Digite o CEP"
                mask="99999-999"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
               onChange={handleSearchCep}
              />

              <Input
                label="Endereço"
                placeholder="Preencha o Endereço"
                errors={errors.street}
                {...register('street')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="Número"
                placeholder="Preencha o Número"
                errors={errors.number}
                {...register('number')}
              />

              <Input
                label="Complemento"
                placeholder="Preencha o complemento"
                errors={errors.complement}
                {...register('complement')}
              />

              <Input
                label="Bairro"
                placeholder="Preencha o bairro"
                errors={errors.neighborhood}
                {...register('neighborhood')}
              />

              <Input
                label="Cidade"
                placeholder="Preencha a Cidade"
                 errors={errors.city}
                {...register('city')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="UF"
                placeholder="Preencha o UF"
                errors={errors.state}
                {...register('state')}
              />

              <InputMaskCustom
                type="text"
                label="CNPJ"
                errors={errors.cnpj}
                {...register('cnpj')}
                placeholder="Digite o CNPJ"
                mask="99.999.999/9999-99"
              />

            { isSP && <Select
                label="Sub. Prefeitura"
                placeholder="Selecionar Sub. Prefeitura"
                errors={errors.sub_prefecture}
                {...register('sub_prefecture')}
                options={SubPrefecturesOptions}
              />}

            </SimpleGrid>

            <Checkbox name='manual' label='Preencher Manualmente a Sub. Prefeitura' onChange={handleTypeAddress} style={{ marginLeft: 'auto' }} />
          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados adicionais
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <TextArea

                label="Observações sobre o Imóvel"
                {...register('obs')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

<Input
                label="Área do Terreno"
                placeholder="Preencha a área"
                errors={errors.area_ground}
                {...register('area_ground')}
              />

<Input
                label="Área construída"
                placeholder="Preencha a área"
                errors={errors.area_building}
                {...register('area_building')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="IPTU"
                placeholder="Preencha o IPTU"
                errors={errors.iptu}
                {...register('iptu')}
              />

<Input
                label="Zoneamento"
                placeholder="Preencha o zoneamento"
                errors={errors.zoning}
                {...register('zoning')}
              />

            </SimpleGrid>

            {loading && (
              <Flex justify="center">
                <Spinner />
              </Flex>
            )}

          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { UnitiesCreate }
