import React, { FC } from 'react'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

interface Props {
  percentage?: number
}

const Loader: FC<Props> = ({ percentage }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      zIndex={9999}
    >
      <Flex alignItems='center' justifyContent='center'>
        <Spinner color="white" size="xl" />
        {!(percentage == null) && <Box>
          <Text color="white" fontSize={18} marginLeft={5}>Fazendo upload de arquivo, por favor aguarde...</Text>
         <Text color="white" fontSize={18} marginLeft={5}>{percentage}%</Text>
        </Box>
}

      </Flex>

    </Box>
  )
}

export { Loader }
