/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box, Button, Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Text, VStack, useToast
} from '@chakra-ui/react'
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'

import { format } from 'date-fns'
import queryString from 'query-string'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Can } from '../../Components/Can'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { Select } from '../../Components/Form/Select'
import { TextArea } from '../../Components/Form/TextArea'
import { Header } from '../../Components/Header'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { ReceiptPreview } from '../../Components/ReceiptPreview'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { PaymentsType } from '../BillsToPay'
import IPaymentProps from '../BillsToPay/Interfaces/ProcessIntefaces'
import { OptionsProps } from '../PreProposal/create'
import IProcessProps, { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { useAuth } from '../../Hooks/Auth'
import { validateUserPermissions } from '../../utils/validateUserPermissions'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface BankProps {

  bank: string | null
  agency: string | null
  account: string | null
  pix: string | null
}

type TypeSelectedProps = PaymentsType | undefined

export const PAYMENTS_TYPE_OPTIONS = [
  {
    label: 'Taxa NÃO reembonsável',
    value: 'TAX_NOT_REFUNDABLE'
  },
  {
    label: 'Taxa reembonsável',
    value: 'TAX_REFUNDABLE'
  },
  {
    label: 'Despesa Prestador NÃO reembonsável',
    value: 'REFUND_NOT_REFUNDABLE'
  },
  {
    label: 'Despesa Prestador reembonsável',
    value: 'REFUND_REFUNDABLE'
  }
]

export const SERVICE_OPTIONS = [
  {
    label: 'Taxa',
    value: '1'
  },
  {
    label: 'Despesa de Processo',
    value: '2'
  },
  {
    label: 'Despesa de Alimentação',
    value: '3'
  },
  {
    label: 'Despesa de Hospedagem',
    value: '4'
  },
  {
    label: 'Despesa de Transporte',
    value: '5'
  },
  {
    label: 'Despesa de KM',
    value: '6'
  }
]

interface ItemsSelected {

  label: string
  value: string
}

const TaxOfRefundCreate: FC = () => {
  const [loading, setLoading] = useState(true)

  const params = useParams()

  const toast = useToast()
  const navigation = useNavigate()
  const [paymentData, setPaymentData] = useState<IPaymentProps>({} as IPaymentProps)
  const [proccessData, setProccessData] = useState<IProcessProps>({} as IProcessProps)
  const [showPaidModal, setShowPaidModal] = useState(false)
  const [showRefuseModal, setShowRefuseModal] = useState(false)
  const [initialCoverReceiptPayment, setInitialCoverReceiptPayment] = useState(undefined)
  const [receiptService, setReceiptService] = useState({} as FilesProps)
  const [showSharedModal, setShowSharedModal] = useState(false)
  const [files, setFiles] = useState<FilesProps[]>()
  const cancelRef = React.useRef<any>()
  const [providers, setProviders] = useState<OptionsProps[]>([])
  const [providerSelected, setProviderSelected] = useState({} as OptionsProps)
  const [bankData, setBankData] = useState<BankProps>({} as BankProps)
  const [typeSelected, setTypeSelected] = useState<TypeSelectedProps>(undefined)
  const DragRef = useRef<DragAndDropExportedProps>(null)

  const [servicesSelected, setServicesSelected] = useState<ItemsSelected>({} as ItemsSelected)
  const [services, setServices] = useState<OptionsProps[]>([])

  const [initialCoverTax, setInitialCoverTax] = useState(undefined)
  const [initialCoverRefund, setInitialCoverRefund] = useState(undefined)
  const location = useLocation()
  const parsed = queryString.parse(location.search)
  const { register, handleSubmit, formState, setValue, watch } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { user } = useAuth()
  const { errors } = formState

  const ReceiptPaymentRef = useRef<DragAndDropExportedProps>(null)

  const hasPermissionToEditPayment = useMemo(() => {
    const hasPermission = validateUserPermissions({ user, permissions: ['payments_edit.all'] })
    return !!hasPermission
  }, [user])

  console.log('hasPermissionToEditPayment: ', hasPermissionToEditPayment)

  const handleSubmitForm = useCallback(async (values: any) => {
    try {
      const currentFile = DragRef?.current?.selectedFile

      if (!servicesSelected?.value) {
        toast({
          title: 'Selecione o serviço',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!currentFile && !receiptService?.link) {
        toast({
          title: 'Anexo é Obrigatório.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const { bar_code, obs, value, type, reason, tax_validate } = values

      if ((type === 'REFUND_NOT_REFUNDABLE' || type === 'REFUND_REFUNDABLE') && !providerSelected?.value) {
        toast({
          title: 'Selecione o Prestador',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      setLoading(true)

      const payload = {
        bar_code,
        obs,
        provider_id: providerSelected?.value,
        process_id: params?.process_id,
        value: Number(getCurrencyValue(String(value))),
        type,
        reason,
        tax_validate,
        service_id: servicesSelected?.value
      }

      console.log('payload: ', payload)

      let payment_id: string = ''

      if (parsed?.id) {
        await api.put(`/payments/tax-or-refund/${String(parsed?.id)}`, payload)
        payment_id = String(parsed?.id)

        toast({
          title: 'Pagamento Atualizado.',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } else {
        const { data } = await api.post('/payments', payload)
        console.log('payment response: ', data)
        payment_id = data?.payment?.id
        const wasAgended = data?.payment?.allowed_at
        toast({
          title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      }

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i?.current?.selectedFile)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              navigation(`/process/create/${params?.process_id}?tab=1`)
              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${payment_id}`, cb)
      }))

      if (!normalizeInputsWithContent?.length) {
        navigation(`/process/create/${params?.process_id}?tab=1`)
        setLoading(false)
      }

      // nextFunction()
    } catch (error) {
      console.log('error handleSubmit: ', error)
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    }
  }, [toast, params, providerSelected, parsed, navigation, receiptService, servicesSelected])

  const handleSubmitReceiptPayment = useCallback(async () => {
    try {
      if (!DragRef?.current?.selectedFile) {
        toast({
          title: 'Anexo não encontrado',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Comprovante salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              // load()
              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${params?.id}`, cb)
      }))
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })

      setLoading(false)
    }
  }, [toast, params])

  const loadShowProvider = useCallback(async (provider_id: string) => {
    try {
      const { data } = await api.get(`/providers/${provider_id}`)

      console.log('data loadShowProvider: ', data)

      setBankData(data?.bank)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectProvider = useCallback((provider_id: any) => {
    console.log('provider selecionado: ', provider_id)
    loadShowProvider(provider_id)
    const provider = providers.find(i => i.value === provider_id)

    if (provider != null) {
      setProviderSelected(provider)
    }
  }, [loadShowProvider, providers])

  const loadProviders = useCallback(async () => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0 // somente prestador ativo
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    loadProviders()
  }, [])

  const handleSelectType = useCallback((e: any) => {
    setTypeSelected(e?.target?.value)
  }, [])

  const typeFile = useMemo(() => {
    return typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE' ? 'comprovante-reembolso' : 'comprovante-taxa'
  }, [typeSelected])

  const loadPayment = useCallback(async (payment_id: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/payments/${payment_id}`)
      console.log('loadPayment: ', data)

      const servicesTypes = ['comprovante-taxa', 'comprovante-reembolso']
      const findReceipt = data?.files?.find((i: FilesProps) => servicesTypes.includes(i.type))
      const findReceiptPayment = data?.files?.find((i: FilesProps) => i.type === 'comprovante-pagamento')

      setTypeSelected(data?.typeLabel)

      if (findReceipt) {
        setReceiptService(findReceipt)
      }

      if (findReceiptPayment) {
        setInitialCoverReceiptPayment(findReceiptPayment?.link)
      }

      setPaymentData(data)
      setFiles(data.files)

      if (data?.typeLabel) {
        setValue('type', data?.typeLabel)
      }

      if (data?.reason) {
        setValue('reason', data?.reason)
      }

      if (data?.value) {
        setValue('value', String(Number(data?.value).toFixed(0)))
      }

      if (data?.obs) {
        setValue('obs', data?.obs)
      }

      if (data?.provider?.id) {
        setProviderSelected({
          value: data?.provider?.id,
          label: data?.provider?.name
        })

        loadShowProvider(data?.provider?.id)
      }

      if (data?.tax_validate) {
        const bornNormalized = format(new Date(data?.tax_validate), 'dd/MM/yyyy')
        setValue('tax_validate', data.tax_validate ? bornNormalized : '')
      }

      if (data?.bar_code) {
        setValue('bar_code', data?.bar_code)
      }

      setServicesSelected({
        label: data?.service?.name,
        value: data?.service?.id
      })

      setPaymentData(data)

      setLoading(false)
    } catch (error) {
      console.log('error loadPayments ModalBill: ', error?.response?.data?.message)
      toast({
        title: 'Erro ao buscar conta avulsa',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    }
  }, [toast, setValue, loadShowProvider])

  const loadProccess = useCallback(async (process_id: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/process/${process_id}`)
      console.log('loadProccess: ', data)

      setProccessData(data)
      setLoading(false)
    } catch (error) {
      console.log('error loadPayments ModalBill: ', error?.response?.data?.message)
      toast({
        title: 'Erro ao buscar processo',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    }
  }, [toast])

  useEffect(() => {
    if (params?.process_id) {
      loadProccess(String(params?.process_id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.process_id])

  useEffect(() => {
    if (parsed?.id) {
      loadPayment(String(parsed?.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsed?.id])

  const handleSearchProvider = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0, // somente prestador ativo
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSearchServices = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/services', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setServices(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectService = useCallback((service_id: string) => {
    const service = services.find(i => i.value === service_id)

    if (service) {
      // setServiceSelected(service)
      //  const filtered = servicesSelected.filter(p => p.id !== item_id)

      const payload = {
        label: service.label,
        value: service.value

      }
      setServicesSelected(payload)
    }

    // alterando as opções de pagamentos

    // pegando todas as opcoes deste servico
    // const serviceWithAllPayments = servicesWithPayments.find(i => i.id === service_id)

    // if (serviceWithAllPayments) {
    //   // normatizando os dados para o select
    //   const normalize = []

    //   for (let i = 1; i <= serviceWithAllPayments.installments; i++) {
    //     normalize.push({
    //       value: String(i),
    //       label: String(i)
    //     })
    //   }

    //   const payload = {
    //     id: item_id,
    //     options: normalize
    //   }

    //   const filtered = paymentsOptions.filter(p => p.id !== item_id)

    //   setPaymentsOptions([...filtered, payload])
    // }
  }, [services])

  return (
    <Can permissions={['proccess.create', 'proccess.all']} page>
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        {loading && <Loader /> }
        <Box
        as="form"
        onSubmit={handleSubmit(handleSubmitForm)}

        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
      >
        <Heading size="lg" fontWeight="normal" color="wine.primary">
          Conta Avulsa
        </Heading>
        <Divider my="6" borderColor="wine.primary" />

        <Heading size="md" fontWeight="bold" color="gray.500" my="8">
          Processo

          <Text size="sm" fontSize="14">{proccessData?.cod}</Text>
        </Heading>

        <VStack>
              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mb="20px">

                {paymentData?.statusLabel === 'PAID' ? (
                  <Box>
                    <Text color="wine.500">Tipo da conta</Text>
                    <Text>{PAYMENTS_TYPE_OPTIONS.find(i => i.value === paymentData?.typeLabel)?.label}</Text>
                  </Box>
                ) : (
                  <Select
                    label="Tipo da Conta"
                    placeholder="Selecionar Tipo"
                    // errors={errors.gender}
                    {...register('type')}
                    options={PAYMENTS_TYPE_OPTIONS}
                    onChange={handleSelectType}
                  />
                )}

                {paymentData?.statusLabel === 'PAID' ? (
                  <Box>
                    <Text color="wine.500">Origem da cobrança</Text>
                    <Text>{SERVICE_OPTIONS.find(i => i.value === paymentData?.reason)?.label}</Text>
                  </Box>
                ) : (
                  <Select
                    label="Origem da cobrança"
                    placeholder="Selecionar"
                    // errors={errors.gender}
                    {...register('reason')}
                    options={SERVICE_OPTIONS}
                  />
                )}

                {paymentData?.statusLabel === 'PAID' && !hasPermissionToEditPayment ? (
                  <Box>
                  <Text color="wine.500">Valor Nominal</Text>
                  <Text>{paymentData?.valueFormatted}</Text>
                  </Box>
                ) : (
                  // <Input label='Valor Nominal' placeholder="Preencha o valor" {...register('value')} />
                  <MoneyInput
                    label="Valor Nominal"
                    placeholder="Preencha o valor"
                    // errors={errors.name}
                  value={watch('value')}
                  {...register('value')}
                  />
                )}

              </SimpleGrid>

              {(typeSelected === 'TAX_REFUNDABLE' || typeSelected === 'TAX_NOT_REFUNDABLE') && (
                <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                <InputMaskCustom
                    type="text"
                    label="Data de Vencimento"
                    // errors={errors.born}
                    {...register('tax_validate')}
                    placeholder="Digite data de vencimento"
                    mask="99/99/9999"
                  />

                  <Input
                  label='Código de Barras / Pix Copia e Cola'
                    {...register('bar_code')}
                  />

                </SimpleGrid>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </>
              )}

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
               <InputModalSearch title="Serviço" searchFunction={handleSearchServices} data={services} extraFunction={(e: any) => handleSelectService(e)} placeholderButton={servicesSelected?.value ? 'Trocar Serviço' : 'Selecionar Serviço'} labelStyle={{ color: 'wine.500' }} btnStyle={{ backgroundColor: '#F765a3' }} />
               <Input {...register('service_id')} value={servicesSelected?.value} isReadOnly visibility="hidden" />

               {servicesSelected?.value && (
                 <Box>
                 <Text color="wine.500" fontWeight={500} align="center">Serviço</Text>
                 <Text align="center" color="wine.500">{servicesSelected?.label}</Text>
               </Box>
               )}

            </SimpleGrid>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                {paymentData?.statusLabel === 'PAID' && receiptService?.link ? <ReceiptPreview label="Anexo" defaultCover={receiptService?.link} /> : (
                  <DragAndDrop
                  label="Anexo"
                  ref={DragRef}
                  containerStyle={{ margin: 'left' }}
                  payload={{
                    name: 'Comprovante',
                    type: (typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE') ? 'comprovante-reembolso' : 'comprovante-taxa'
                  }}
                  defaultCover={receiptService?.link}
                  id={files?.length ? files.find(i => i.type === typeFile)?.id : null}
                  // id={files?.length ? files.find(i => i.type === 'comprovante-pagamento')?.id : null}
                  saveFunction={receiptService ? undefined : async () => await handleSubmitReceiptPayment()}
                  extraCleanFunction={() => setReceiptService({} as FilesProps)}

                />
                )}

                    {paymentData?.statusLabel === 'PAID' && initialCoverReceiptPayment ? <ReceiptPreview label="Comprovante de pagamento" defaultCover={initialCoverReceiptPayment} /> : <Box />}

              </SimpleGrid>

              {(typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE') && (
                <>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="150px">

                  <InputModalSearch title="Prestador" searchFunction={handleSearchProvider} data={providers} extraFunction={handleSelectProvider} placeholderButton={providerSelected?.value ? 'Trocar Prestador' : 'Selecionar Prestador'} btnStyle={{ zIndex: 99 }} />
                  <Input {...register('provider')} isReadOnly value={providerSelected?.value} visibility="hidden" />

                    {providerSelected && (
                      <Box>
                        <Text color="wine.primary" fontWeight={500} align="center">Nome</Text>
                        <Text align="center">{providerSelected?.label}</Text>
                      </Box>
                    )}

                    {bankData?.pix && (
                      <Box>
                        <Text color="wine.primary" fontWeight={500} align="center">Chave Pix</Text>
                        <Text align="center">{bankData?.pix}</Text>
                      </Box>
                    )}

                    {bankData?.bank && (
                      <>
                        <Box>
                          <Text color="wine.primary" fontWeight={500} align="center">Banco</Text>
                          <Text align="center">{bankData?.bank}</Text>
                        </Box>

                        <Box>
                          <Text color="wine.primary" fontWeight={500} align="center">Agência</Text>
                          <Text align="center">{bankData?.agency}</Text>
                        </Box>

                        <Box>
                          <Text color="wine.primary" fontWeight={500} align="center">Conta</Text>
                          <Text align="center">{bankData?.account}</Text>
                        </Box>
                      </>
                    )}

                  </SimpleGrid>
                </>
              )}

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                {paymentData?.statusLabel === 'PAID'
                  ? (
                  <Box>
                  <Text color="wine.500">Observação</Text>
                  <Text>{paymentData?.obs || 'Nenhuma observação encontrada.'}</Text>
                  </Box>

                    )
                  : (
                  <TextArea
                  label="Observação"
                  placeholder="Preencha a observação"
                  {...register('obs')}
                />
                    )}

              </SimpleGrid>

        </VStack>

        <Flex mt={['6', '8']} justify="flex-end">
          <HStack>
            <Button
              as="a"
              bg="pink.500"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              bg="wine.primary"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Flex>
      </Box>

      </Flex>
    </Box>
    </Can>
  )
}

export { TaxOfRefundCreate }
