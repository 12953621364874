import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { Can } from '../../Components/Can'
import { format } from 'date-fns'
import { ProposalLabel } from '../Proposal'

interface PreProposal {
  id: string
  number: string
  net: {
    name: string

  }
  unit: {
    id?: string
    name?: string
    name_approvation?: string
  }
  statusLabel: ProposalLabel
  created_at: string
  collaborator?: {
    id: string
    name: string
  }
}

const PreProposalList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<PreProposal[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as PreProposal)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/proposal', {
        params: {
          pag
        }
      })

      const normalize = data.data.map((i: any) => {
        // const regExpPhone = new RegExp(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/);
        return {
          ...i,
          created_at: new Date(i.created_at).toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        }
      })

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar administradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/proposal/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as PreProposal)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as PreProposal)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as PreProposal)
  }, [])

  const handleFilterList = useCallback(async (e: any) => {
    setLoading(true)

    try {
      const { data } = await api.get('/proposal', {
        params: {
          pag: 1,
          search: e?.target?.value
        }
      })

      const normalize = await Promise.all(data.data.map(async (i: any) => {
        return {
          ...i,
          created_at: format(new Date(i.created_at), 'dd/MM/yyyy')
        }
      }))

      console.log('normalize: ', normalize)

      // setPage(1)
      setData([...normalize])
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const makeBg = useCallback((status: ProposalLabel) => {
    switch (status) {
      case status = 'CREATED':
        return '#f8de7e'

      case status = 'APPROVED_BY_COMPANY':
        return '#5dbb63'

      case status = 'PROCESSED':
        return '#528fde'

      default:
        return '#f8de7e'
    }
  }, [])

  const makeColor = useCallback((status: ProposalLabel) => {
    switch (status) {
      case status = 'CREATED':
        return '#993b58'

      case status = 'APPROVED_BY_COMPANY':
        return '#fff'

      case status = 'PROCESSED':
        return '#fff'

      default:
        return '#fff'
    }
  }, [])

  return (
    <Can permissions={['pre_proposal.list', 'pre_proposal.create', 'pre_proposal.all']} page>
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Pré-proposta"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Pré-Propostas
              <Flex alignItems="center" gap="18px">
            <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#f8de7e" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Propostas novas</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#5dbb63" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Valores já preenchidos</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#528fde" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Processos já criados</Text>
                </Flex>
          </Flex>
            </Heading>

            <Flex direction="column" alignItems="flex-end">
              <SearchBox placeholder="Buscar..." onChangeFunction={handleFilterList} />

              <Button
                as="a"
                href="/pre-proposal/create"
                size="sm"
                fontSize="sm"
                bg="wine.primary"
                _hover={{ bgColor: 'wine.200' }}
                color="white"
                leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                marginTop="15px"
              >
                Criar nova pré-proposta
              </Button>
            </Flex>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de pré-propostas</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center">Cliente</Th>
                    <Th textAlign="center">Unidade</Th>
                    <Th textAlign="center">Proposta</Th>
                    <Th textAlign="center">Requisitante</Th>
                    <Th textAlign="center">Data de Solicitação</Th>
                    {/* <Th textAlign="center">Status</Th> */}

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id} backgroundColor={makeBg(i.statusLabel)}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i?.net?.name || 'Rede removida'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i?.unit?.id ? i?.unit?.name || i?.unit?.name_approvation || 'Nome da unidade não informado' : 'Unidade removida'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel)}>{i.number}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" color={makeColor(i.statusLabel)}>{i?.collaborator?.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" color={makeColor(i.statusLabel)}>{i?.created_at}</Text>
                          </Link>
                      </Td>

                      {/* <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center">""</Text>
                          </Link>
                      </Td> */}

                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

                          {isWideScreen
                            ? (
                            <Button
                            as="a"
                            href={`/pre-proposal/create/${i?.id}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="wine.200"
                              colorScheme="wine"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { PreProposalList }
