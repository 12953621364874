import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,

  SimpleGrid,
  Text,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useState } from 'react'

import { useForm } from 'react-hook-form'
import { FiSend } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { AlertDialogCustom } from '../../../Components/AlertDialog'
import { Input } from '../../../Components/Form/Input'
import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { Sidebar } from '../../../Components/Sidebar'
import api from '../../../Services/api'
import { CollaboratorProps } from '../../Collaborators'
import { Select } from '../../../Components/Form/Select'

export const statusOptions = [
  {
    value: '1',
    label: 'Em Aberto'
  },
  {
    value: '2',
    label: 'Pagos'
  },
  {
    value: '3',
    label: 'Cancelado'
  },
  {
    value: 'all',
    label: 'Todos'
  }
]

export const typeOptions = [
  {
    value: 'all',
    label: 'Todos os tipos'
  },
  {
    value: '0',
    label: 'Honorário'
  },
  {
    value: '1',
    label: 'Taxa NÃO reembonsável'
  },
  {
    value: '2',
    label: 'Reembolso NÃO reembonsável'
  },
  {
    value: '3',
    label: 'Taxa reembonsável'
  },
  {
    value: '4',
    label: 'Reembolso reembonsável'
  }
]

const PaymentsByPeriod: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CollaboratorProps>({} as CollaboratorProps)
  const [list, setList] = useState<CollaboratorProps[]>([])
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const params = useParams()
  const [type, setType] = useState<'department' | 'person' | undefined>(undefined)
  const [collaboratorsSelected, setCollaboratorSelected] = useState<CollaboratorProps>({} as CollaboratorProps)
  const toast = useToast()
  const [confirmation, setConfirmation] = useState(false)
  const [filterName, setFilterName] = useState('')

  const { register, getValues, formState, setValue, watch } = useForm({

  })

  const { errors } = formState

  const handleSubmit = useCallback(async () => {
    const values = getValues()

    if (!values?.status) {
      toast({
        title: 'Selecione o status dos pagamentos',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      return
    }

    if (!values?.initialDate) {
      toast({
        title: 'Selecione a data inicial do relatório',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      return
    }

    if (!values?.finalDate) {
      toast({
        title: 'Selecione a data final do relatório',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      return
    }

    setLoading(true)
    try {
      const { data } = await api.get('/reports/payments-by-period', {
        responseType: 'arraybuffer',
        params: {
          initialDate: values?.initialDate,
          finalDate: values?.finalDate,
          status: values?.status,
          type: values?.type
        }
      })

      // Criar um Blob a partir dos dados recebidos
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      // Criar um URL temporário para o Blob
      const url = URL.createObjectURL(blob)

      // Criar um link de download
      const link = document.createElement('a')
      link.href = url
      link.download = 'pagamentos.xlsx'

      // Adicionar o link ao DOM e simular um clique para iniciar o download
      document.body.appendChild(link)
      link.click()

      // Remover o link do DOM
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [getValues, toast])

  // useEffect(() => {
  //   load()
  //   loadAllCollaborators()
  // }, [])

  const handleFilterList = useCallback(async (value: string) => {
    setLoading(true)
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          name: value
        }
      })

      console.log('data: ', data)

      setList(data.data)

      // setPage(1)
      // setData(data.data)
      // setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Box>

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Relatórios - Pagamentos por Período
            </Heading>

          </Flex>

          {loading && <Loader /> }

              <Box>
                <Text>Selecione o período desejado</Text>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="5">

                <Select
                  label="Status"
                  placeholder="Selecionar Status"
                  errors={errors.status}
                  {...register('status')}
                  options={statusOptions}
                  />

<Select
                  label="Tipo de contas"
                  placeholder="Selecionar Tipo"
                  errors={errors.type}
                  {...register('type')}
                  options={typeOptions}
                  />
                </SimpleGrid>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="5">
                  <Input label="Data inicial"
                  errors={errors.initialDate}
                  {...register('initialDate')}
                  type="date" />

                  <Input label="Data final"
                  errors={errors.finalDate}
                  {...register('finalDate')}
                  type="date" />

                  </SimpleGrid>

              </Box>

              <Button
              my="8"
                width={200}
                type="button"
                size="sm"
                fontSize="sm"
                onClick={handleSubmit}
                colorScheme="blue"
                leftIcon={
                  <Icon as={FiSend} fontSize="16" />
                }
              >
               Gerar Relatório
              </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export { PaymentsByPeriod }
