import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAuth } from '../Hooks/Auth'
import { ClauseProposalList } from '../Pages/ClauseProposal'
import { ClauseProposalCreate } from '../Pages/ClauseProposal/create'
import { CollaboratorsList } from '../Pages/Collaborators'
import { CollaboratorsCreate } from '../Pages/Collaborators/create'
import { Dashboard } from '../Pages/Dashboard'
import { Reports } from '../Pages/Reports'
import { BillsToPayList } from '../Pages/BillsToPay'
import { BillsToPayCreate } from '../Pages/BillsToPay/create'
import { NetsList } from '../Pages/Nets'
import { NetsCreate } from '../Pages/Nets/create'
import { NotFoundPage } from '../Pages/NotFound'
import { NotificationsList } from '../Pages/Notifications'
import { PreProposalList } from '../Pages/PreProposal'
import { PreProposalCreate } from '../Pages/PreProposal/create'
import { ProcessList } from '../Pages/Process'
import { ProcessCreate } from '../Pages/Process/create'
import { ProductsList } from '../Pages/Products'
import { ProductsCreate } from '../Pages/Products/create'
import { ProposalList } from '../Pages/Proposal'
import { ProposalCreate } from '../Pages/Proposal/create'
import { ProvidersList } from '../Pages/Providers'
import { ProvidersCreate } from '../Pages/Providers/create'
import { ServicesList } from '../Pages/Services'
import { ServicesCreate } from '../Pages/Services/create'
import { SignIn } from '../Pages/SignIn'
import { UnitiesList } from '../Pages/Unities'
import { UnitiesCreate } from '../Pages/Unities/create'
import { UsersList } from '../Pages/Users'
import { VideosList } from '../Pages/Videos'
import { VideosCreate } from '../Pages/Videos/create'
import { BillsToReceiveList } from '../Pages/BillsToReceive'
import { BillsToReceiveCreate } from '../Pages/BillsToReceive/create'
import { VacationsCreate } from '../Pages/Vacations'
import { PaymentsByProvider } from '../Pages/Reports/payments-by-provider'
import { PaymentsByPeriod } from '../Pages/Reports/payments-by-period'
import { TaxOfRefundCreate } from '../Pages/TaxOrRefund/create'

const AppRoutes: FC = () => {
  const { user } = useAuth()

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/unities" element={<UnitiesList />} />
      <Route path="/unities/create" element={<UnitiesCreate />} />
      <Route
        path="/unities/create/:unit_id"
        element={<UnitiesCreate />}
      />

      <Route path="/providers" element={<ProvidersList />} />
      <Route path="/providers/create" element={<ProvidersCreate />} />
      <Route
        path="/providers/create/:provider_id"
        element={<ProvidersCreate />}
      />

      <Route path="/bills-pay" element={<BillsToPayList />} />
      <Route path="/bills-pay/create" element={<BillsToPayCreate />} />
      <Route
        path="/bills-pay/create/:id"
        element={<BillsToPayCreate />}
      />

      <Route path="/bills-receive" element={<BillsToReceiveList />} />
      <Route path="/bills-receive/create" element={<BillsToReceiveCreate />} />
      <Route
        path="/bills-receive/create/:id"
        element={<BillsToReceiveCreate />}
      />

      <Route path="/notifications" element={<NotificationsList />} />

      <Route path="/vacations/create/:collaborator_id" element={<VacationsCreate />} />

      <Route path="/pre-proposal" element={<PreProposalList />} />
      <Route path="/pre-proposal/create" element={<PreProposalCreate />} />
      <Route
        path="/pre-proposal/create/:proposal_id"
        element={<PreProposalCreate />}
      />

      <Route path="/process" element={<ProcessList />} />
      <Route path="/process/create" element={<ProcessCreate />} />
      <Route
        path="/process/create/:id"
        element={<ProcessCreate />}
      />

  <Route
        path="/tax-or-refund/create/:process_id"
        element={<TaxOfRefundCreate />}
      />

      <Route path="/proposal" element={<ProposalList />} />
      <Route path="/proposal/create" element={<ProposalCreate />} />
      <Route
        path="/proposal/create/:id"
        element={<ProposalCreate />}
      />

      <Route path="/collaborators" element={<CollaboratorsList />} />
      <Route path="/collaborators/create" element={<CollaboratorsCreate />} />
      <Route
        path="/collaborators/create/:collaborator_id"
        element={<CollaboratorsCreate />}
      />

      <Route path="/services" element={<ServicesList />} />
      <Route path="/services/create" element={<ServicesCreate />} />
      <Route
        path="/services/create/:service_id"
        element={<ServicesCreate />}
      />

      <Route path="/clause-proposal" element={<ClauseProposalList />} />
      <Route path="/clause-proposal/create" element={<ClauseProposalCreate />} />
      <Route
        path="/clause-proposal/create/:id"
        element={<ClauseProposalCreate />}
      />

      <Route path="/nets" element={<NetsList />} />
      <Route path="/nets/create" element={<NetsCreate />} />
      <Route
        path="/nets/create/:id"
        element={<NetsCreate />}
      />

      <Route path="/videos" element={<VideosList />} />
      <Route path="/videos/create" element={<VideosCreate />} />
      <Route path="/videos/create/:slug" element={<VideosCreate />} />

      <Route path="/products" element={<ProductsList />} />
      <Route path="/products/create" element={<ProductsCreate />} />
      <Route
        path="/products/create/:slug"
        element={<ProductsCreate />}
      />

      <Route path="/users" element={<UsersList />} />
      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<NotFoundPage />} />

      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/payments-by-provider" element={<PaymentsByProvider />} />
      <Route path="/reports/payments-by-period" element={<PaymentsByPeriod/>} />
    </Routes>
  )
}

export default AppRoutes
