import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,

  SimpleGrid,
  Text,
  useToast
} from '@chakra-ui/react'
import { FC, useCallback, useState } from 'react'

import { useForm } from 'react-hook-form'
import { FiSend } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { AlertDialogCustom } from '../../../Components/AlertDialog'
import { Input } from '../../../Components/Form/Input'
import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { Sidebar } from '../../../Components/Sidebar'
import api from '../../../Services/api'
import { CollaboratorProps } from '../../Collaborators'
import { typeOptions } from '../payments-by-period'
import { Select } from '../../../Components/Form/Select'

const PaymentsByProvider: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CollaboratorProps>({} as CollaboratorProps)
  const [list, setList] = useState<CollaboratorProps[]>([])
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const params = useParams()
  const [type, setType] = useState<'department' | 'person' | undefined>(undefined)
  const [collaboratorsSelected, setCollaboratorSelected] = useState<CollaboratorProps>({} as CollaboratorProps)
  const toast = useToast()
  const [confirmation, setConfirmation] = useState(false)
  const [filterName, setFilterName] = useState('')

  const { register, getValues, formState, setValue, watch } = useForm({

  })

  const { errors } = formState

  const handleSubmit = useCallback(async () => {
    const values = getValues()

    setLoading(true)
    try {
      const { data } = await api.get('/reports/payments-by-provider', {
        responseType: 'arraybuffer',
        params: {
          provider_id: collaboratorsSelected?.id,
          initialDate: values?.initialDate,
          finalDate: values?.finalDate
        }
      })

      // Criar um Blob a partir dos dados recebidos
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      // Criar um URL temporário para o Blob
      const url = URL.createObjectURL(blob)

      // Criar um link de download
      const link = document.createElement('a')
      link.href = url
      link.download = 'pagamentos.xlsx'

      // Adicionar o link ao DOM e simular um clique para iniciar o download
      document.body.appendChild(link)
      link.click()

      // Remover o link do DOM
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [getValues, collaboratorsSelected])

  const loadAllCollaborators = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setCollaborators(normalize)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  // useEffect(() => {
  //   load()
  //   loadAllCollaborators()
  // }, [])

  const handleChoice = useCallback((e: any) => {
    console.log('e: ', e)
    setType(e?.target?.value)
  }, [])

  const handleSelectPerson = useCallback((e: any) => {
    const getCollaborator = collaborators?.find(i => i.value === e)

    if (getCollaborator) {
      setCollaboratorSelected(getCollaborator)
    }
  }, [collaborators])

  const handleVacations = useCallback(async () => {
    setLoading(true)
    try {
      if (!type) {
        toast({
          title: 'Selecione uma opção de quem deverá ficar com os processos deste colaborador.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      const values = getValues()
      console.log('values: ', values)

      // if (type === 'department' && !values?.department) {
      //   toast({
      //     title: 'Selecione o departamento que deverá ficar com os processos deste colaborador.',
      //     position: 'top',
      //     isClosable: true,
      //     status: 'error',
      //     variant: 'solid'
      //   })

      //   return
      // }

      // if (type === 'person' && !collaboratorsSelected?.value) {
      //   toast({
      //     title: 'Selecione o colaborador que deverá ficar com os processos.',
      //     position: 'top',
      //     isClosable: true,
      //     status: 'error',
      //     variant: 'solid'
      //   })

      //   return
      // }

      // await api.patch(`/collaborators/vacations/${params?.collaborator_id}`, {
      //   type,
      //   target: type === 'person' ? collaboratorsSelected?.value : values?.department
      // })

      setConfirmation(false)

      toast({
        title: 'Férias aplicadas.',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })
    } catch (error) {
      console.log('erro ao aplicar as férias: ', error)
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [params, toast, type, getValues, collaboratorsSelected])

  const handleFilterList = useCallback(async (value: string) => {
    setLoading(true)
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          name: value
        }
      })

      console.log('data: ', data)

      setList(data.data)

      // setPage(1)
      // setData(data.data)
      // setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const validateFilter = useCallback(async (e: any) => {
    setFilterName(e?.target?.value)
    if (e?.target?.value?.length >= 3) {
      await handleFilterList(e?.target?.value)
    } else {
      setList([])
    }
  }, [handleFilterList])

  const handleSelectCollaborator = useCallback((collaborator: CollaboratorProps) => {
    setCollaboratorSelected(collaborator)
    setList([])
  }, [])

  return (
    <Box>

{!!confirmation && (
        <AlertDialogCustom
          title="Executar Férias"
          isOpen={!!confirmation}
          cancelFunction={() => setConfirmation(false)}
          nextFunction={handleVacations}
        />
)}

      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Relatórios - Pagamentos por Prestador
            </Heading>

          </Flex>

          {loading && <Loader /> }

              <Box>
                <Text>Selecione o período desejado</Text>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="5">
                  <Input label="Data inicial"
                  errors={errors.initialDate}
                  {...register('initialDate')}
                  type="date" />

                  <Input label="Data final"
                  errors={errors.finalDate}
                  {...register('finalDate')}
                  type="date" />

                  </SimpleGrid>
                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="5">
                    <Select
                      label="Tipo de contas"
                      placeholder="Selecionar Tipo"
                      errors={errors.type}
                      {...register('type')}
                      options={typeOptions}
                    />
                  </SimpleGrid>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="5">
                  <Input label="Digite o nome do Prestador"
                    errors={errors.name}
                    value={filterName}
                    {...register('collaborator')}
                    onChange={validateFilter}

                     />

                  </SimpleGrid>

                <Flex>
                  {list.map(collaborator => (
                    <Flex justifyContent="space-between" alignItems="center" w="100%" borderBottom="1px" borderBottomColor="#ccc"><Text>{collaborator.name}</Text><Button w="200px" my="3" variant="outline" colorScheme='teal' onClick={() => handleSelectCollaborator(collaborator)}>Selecionar</Button></Flex>

                  ))}
                </Flex>

              </Box>

              {collaboratorsSelected?.id && (
                <Box marginTop="15px">
                <Text fontWeight="bold">Prestador Selecionado</Text>
                <Text color="wine.500">{collaboratorsSelected?.name}</Text>
                </Box>
              )}

              <Button
              my="8"
                width={200}
                type="button"
                size="sm"
                fontSize="sm"
                onClick={handleSubmit}
                colorScheme="blue"
                leftIcon={
                  <Icon as={FiSend} fontSize="16" />
                }
              >
               Gerar Relatório
              </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export { PaymentsByProvider }
