/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  SimpleGrid,
  VStack,
  Text,
  Flex,
  useToast
} from '@chakra-ui/react'
import { Select } from '../../../Components/Form/Select'
import { useForm } from 'react-hook-form'
import { Input } from '../../../Components/Form/Input'
import { MoneyInput } from '../../../Components/Form/MoneyInput'
import { SelectWithSearch } from '../../../Components/Form/SelectWithSearch'
import { OptionsProps } from '../../PreProposal/create'
import api from '../../../Services/api'
import { TextArea } from '../../../Components/Form/TextArea'
import { DragAndDrop, DragAndDropExportedProps } from '../../../Components/Form/DragAndDrop'
import { InputMaskCustom } from '../../../Components/Form/InputMask'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../Components/Loader'
import { FilesProps, IEachPaymentProps } from '../Interfaces/ProcessIntefaces'
import { getCurrencyValue } from '../../../utils/getCurrencyValue'
import { PaymentsType } from '../../BillsToPay'
import { ReceiptPreview } from '../../../Components/ReceiptPreview'
import { format } from 'date-fns'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
  id: string
}

interface BankProps {

  bank: string | null
  agency: string | null
  account: string | null
  pix: string | null
}

type TypeSelectedProps = PaymentsType | undefined

export const PAYMENTS_TYPE_OPTIONS = [
  {
    label: 'Taxa NÃO reembonsável',
    value: 'TAX_NOT_REFUNDABLE'
  },
  {
    label: 'Taxa reembonsável',
    value: 'TAX_REFUNDABLE'
  },
  {
    label: 'Despesa Prestador NÃO reembonsável',
    value: 'REFUND_NOT_REFUNDABLE'
  },
  {
    label: 'Despesa Prestador reembonsável',
    value: 'REFUND_REFUNDABLE'
  }
]

const ModalNewBill: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  description,
  cancelText,
  nextText,
  cancelScheme,
  nextScheme,
  thirdButton,
  thirdButtonText,
  thirdButtonFunction,
  id
}) => {
  const [loading, setLoading] = useState(false)
  const cancelRef = React.useRef<any>()
  const [providers, setProviders] = useState<OptionsProps[]>([])
  const [providerSelected, setProviderSelected] = useState({} as OptionsProps)
  const [bankData, setBankData] = useState<BankProps>({} as BankProps)
  const [typeSelected, setTypeSelected] = useState<TypeSelectedProps>(undefined)
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const [files, setFiles] = useState<FilesProps[]>()
  const [paymentData, setPaymentData] = useState<any>({} as any)

  const [receiptService, setReceiptService] = useState<FilesProps>({} as FilesProps)
  const [initialCoverReceiptPayment, setInitialCoverReceiptPayment] = useState(undefined)

  const toast = useToast()
  const params = useParams()
  console.log('modal bill id: ', id)

  const SERVICE_OPTIONS = [
    {
      label: 'Taxa',
      value: '1'
    },
    {
      label: 'Despesa de Processo',
      value: '2'
    },
    {
      label: 'Despesa de Alimentação',
      value: '3'
    },
    {
      label: 'Despesa de Hospedagem',
      value: '4'
    },
    {
      label: 'Despesa de Transporte',
      value: '5'
    },
    {
      label: 'Despesa de KM',
      value: '6'
    }
  ]

  const { register, handleSubmit, setValue } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })

  const handleSubmitForm = useCallback(async (values: any) => {
    try {
      console.log('values: ', values)

      const currentFile = DragRef?.current?.selectedFile

      if (!currentFile) {
        toast({
          title: 'Anexo é Obrigatório.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const { bar_code, obs, value, type, reason, tax_validate } = values

      if ((type === 'REFUND_NOT_REFUNDABLE' || type === 'REFUND_REFUNDABLE') && !providerSelected?.value) {
        toast({
          title: 'Selecione o Prestador',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        bar_code,
        obs,
        provider_id: providerSelected?.value,
        process_id: params?.process_id,
        value: Number(getCurrencyValue(String(value))),
        type,
        reason,
        tax_validate
      }

      console.log('payload: ', payload)

      let payment_id: string = ''

      if (id) {
        await api.put(`/payments/tax-or-refund/${id}`, payload)
        payment_id = id

        toast({
          title: 'Pagamento Atualizado.',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } else {
        const { data } = await api.post('/payments', payload)
        console.log('payment response: ', data)
        payment_id = data?.payment?.id
        const wasAgended = data?.payment?.allowed_at
        toast({
          title: wasAgended ? 'Pagamento Agendado' : 'Pagamento solicitado',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      }

      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i?.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              nextFunction()
              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${payment_id}`, cb)
      }))

      setLoading(false)
      // nextFunction()
    } catch (error) {
      console.log('error handleSubmit: ', error)
      toast({
        title: error?.response?.data?.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    }
  }, [toast, nextFunction, params, providerSelected, id])

  const handleSubmitReceiptPayment = useCallback(async () => {
    try {
      if (!DragRef?.current?.selectedFile) {
        toast({
          title: 'Anexo não encontrado',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Comprovante salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              // load()
              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${params?.process_id}`, cb)
      }))
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })

      setLoading(false)
    }
  }, [toast, params])

  const loadShowProvider = useCallback(async (provider_id: string) => {
    try {
      const { data } = await api.get(`/providers/${provider_id}`)

      console.log('data loadShowProvider: ', data)

      setBankData(data?.bank)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectProvider = useCallback((provider_id: any) => {
    console.log('provider selecionado: ', provider_id)
    loadShowProvider(provider_id)
    const provider = providers.find(i => i.value === provider_id)

    if (provider != null) {
      setProviderSelected(provider)
    }
  }, [loadShowProvider, providers])

  const loadProviders = useCallback(async () => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0 // somente prestador ativo
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    loadProviders()
  }, [])

  const handleSelectType = useCallback((e: any) => {
    setTypeSelected(e?.target?.value)
  }, [])

  const typeFile = useMemo(() => {
    return typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE' ? 'comprovante-reembolso' : 'comprovante-taxa'
  }, [typeSelected])

  const loadPayment = useCallback(async (payment_id: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/payments/${payment_id}`)
      console.log('loadPayment: ', data)

      const servicesTypes = ['comprovante-taxa', 'comprovante-reembolso']
      const findReceipt = data?.files?.find((i: FilesProps) => servicesTypes.includes(i.type))
      const findReceiptPayment = data?.files?.find((i: FilesProps) => i.type === 'comprovante-pagamento')

      setTypeSelected(data?.typeLabel)

      if (findReceipt) {
        setReceiptService(findReceipt)
      }

      if (findReceiptPayment) {
        setInitialCoverReceiptPayment(findReceiptPayment?.link)
      }

      setPaymentData(data)
      setFiles(data.files)

      if (data?.typeLabel) {
        setValue('type', data?.typeLabel)
      }

      if (data?.reason) {
        setValue('reason', data?.reason)
      }

      if (data?.value) {
        setValue('value', Number(data?.value))
      }

      if (data?.obs) {
        setValue('obs', data?.obs)
      }

      if (data?.provider?.id) {
        setProviderSelected({
          value: data?.provider?.id,
          label: data?.provider?.name
        })

        loadShowProvider(data?.provider?.id)
      }

      if (data?.tax_validate) {
        const bornNormalized = format(new Date(data?.tax_validate), 'dd/MM/yyyy')
        setValue('tax_validate', data.tax_validate ? bornNormalized : '')
      }

      if (data?.bar_code) {
        setValue('bar_code', data?.bar_code)
      }

      setPaymentData(data)
      setLoading(false)
    } catch (error) {
      console.log('error loadPayments ModalBill: ', error?.response?.data?.message)
      toast({
        title: 'Erro ao buscar conta avulsa',
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setLoading(false)
    }
  }, [toast, setValue, loadShowProvider])

  useEffect(() => {
    if (id) {
      loadPayment(id)
    }
  }, [id])

  console.log('paymentData: ', paymentData)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}

      size="6xl"
      scrollBehavior='outside'
    >

      <AlertDialogOverlay>

        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          {loading ? <Loader /> : (

            <Box
            as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

            borderRadius={8}
            bg="white"
            p="8"
            >
            <AlertDialogBody>
                <VStack>
                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mb="20px">

                  {paymentData?.statusLabel === 'PAID' ? (
                    <Box>
                      <Text color="wine.500">Tipo da conta</Text>
                      <Text>{PAYMENTS_TYPE_OPTIONS.find(i => i.value === paymentData?.typeLabel)?.label}</Text>
                    </Box>
                  ) : (
                    <Select
                      label="Tipo da Conta"
                      placeholder="Selecionar Tipo"
                      // errors={errors.gender}
                      {...register('type')}
                      options={PAYMENTS_TYPE_OPTIONS}
                      onChange={handleSelectType}
                    />
                  )}

                  {paymentData?.statusLabel === 'PAID' ? (
                    <Box>
                      <Text color="wine.500">Origem da cobrança</Text>
                      <Text>{SERVICE_OPTIONS.find(i => i.value === paymentData?.reason)?.label}</Text>
                    </Box>
                  ) : (
                    <Select
                      label="Origem da cobrança"
                      placeholder="Selecionar"
                      // errors={errors.gender}
                      {...register('reason')}
                      options={SERVICE_OPTIONS}
                    />
                  )}

                  {paymentData?.statusLabel === 'PAID' ? (
                    <Box>
                    <Text color="wine.500">Valor Nominal</Text>
                    <Text>{paymentData?.valueFormatted}</Text>
                    </Box>
                  ) : (
                    <MoneyInput
                      label="Valor Nominal"
                      placeholder="Preencha o valor"
                      // errors={errors.name}
                      defaultValue={paymentData?.value ? Number(paymentData?.value) : null}
                      {...register('value')}
                      />
                  )}

                </SimpleGrid>

                {(typeSelected === 'TAX_REFUNDABLE' || typeSelected === 'TAX_NOT_REFUNDABLE') && (
                  <>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <InputMaskCustom
                      type="text"
                      label="Data de Vencimento"
                      // errors={errors.born}
                      {...register('tax_validate')}
                      placeholder="Digite data de vencimento"
                      mask="99/99/9999"
                    />

                    <Input
                    label='Código de Barras / Pix Copia e Cola'
                      {...register('bar_code')}
                    />

                  </SimpleGrid>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  </>
                )}

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  {paymentData?.statusLabel === 'PAID' && receiptService?.link ? <ReceiptPreview label="Anexo" defaultCover={receiptService?.link} /> : (
                    <DragAndDrop
                    label="Anexo"
                    ref={DragRef}
                    containerStyle={{ margin: 'left' }}
                    payload={{
                      name: 'Comprovante',
                      type: (typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE') ? 'comprovante-reembolso' : 'comprovante-taxa'
                    }}
                    defaultCover={receiptService?.link}
                    id={files?.length ? files.find(i => i.type === typeFile)?.id : null}
                    // id={files?.length ? files.find(i => i.type === 'comprovante-pagamento')?.id : null}
                    saveFunction={receiptService ? undefined : async () => await handleSubmitReceiptPayment()}
                    extraCleanFunction={() => setInitialCoverReceiptPayment(undefined)}

                  />
                  )}

                      {paymentData?.statusLabel === 'PAID' && initialCoverReceiptPayment ? <ReceiptPreview label="Comprovante de pagamento" defaultCover={initialCoverReceiptPayment} /> : <Box />}

                </SimpleGrid>

                {(typeSelected === 'REFUND_NOT_REFUNDABLE' || typeSelected === 'REFUND_REFUNDABLE') && (
                  <>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="150px">
                      <SelectWithSearch
                          label="Prestador"
                          title={providerSelected?.value ? 'Trocar Prestador' : 'Selecionar Prestador'}
                          options={providers}
                          extraFunction={(e: any) => handleSelectProvider(e)}
                          {...register('provider')}
                      />

                      {providerSelected && (
                        <Box>
                          <Text color="wine.primary" fontWeight={500} align="center">Nome</Text>
                          <Text align="center">{providerSelected?.label}</Text>
                        </Box>
                      )}

                      {bankData?.pix && (
                        <Box>
                          <Text color="wine.primary" fontWeight={500} align="center">Chave Pix</Text>
                          <Text align="center">{bankData?.pix}</Text>
                        </Box>
                      )}

                      {bankData?.bank && (
                        <>
                          <Box>
                            <Text color="wine.primary" fontWeight={500} align="center">Banco</Text>
                            <Text align="center">{bankData?.bank}</Text>
                          </Box>

                          <Box>
                            <Text color="wine.primary" fontWeight={500} align="center">Agência</Text>
                            <Text align="center">{bankData?.agency}</Text>
                          </Box>

                          <Box>
                            <Text color="wine.primary" fontWeight={500} align="center">Conta</Text>
                            <Text align="center">{bankData?.account}</Text>
                          </Box>
                        </>
                      )}

                    </SimpleGrid>
                  </>
                )}

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  {paymentData?.statusLabel === 'PAID' ? (
                    <Box>
                    <Text color="wine.500">Observação</Text>
                    <Text>{paymentData?.obs || 'Nenhuma observação encontrada.'}</Text>
                    </Box>

                  ) : (
                    <TextArea
                    label="Observação"
                    placeholder="Preencha a observação"
                    {...register('obs')}
                  />
                  )}

                </SimpleGrid>

                </VStack>

            </AlertDialogBody>
            <AlertDialogFooter>
            {thirdButton && (
              <Button onClick={thirdButtonFunction}>
                {'Cancelar'}
              </Button>
            )}

            <Button colorScheme={cancelScheme} onClick={cancelFunction}>
              {id ? 'Fechar' : 'Cancelar'}
            </Button>

            {paymentData?.statusLabel !== 'PAID' && (
              <Button
                type='submit'
                colorScheme="blue"
                ml={3}
              >
                {id ? 'Atualizar Pagamento' : 'Solicitar Pagamento'}
              </Button>
            )}

            </AlertDialogFooter>
          </Box>
          )}

        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { ModalNewBill }
