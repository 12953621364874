import axios from 'axios'

// http://localhost:21333/api
// https://www.ctrlprojecto.eng.br/api
const api = axios.create({
  baseURL: 'https://www.ctrlprojecto.eng.br/api'
})

export const adminVersion = '1.0.13'

export default api
