import React, { FC, useState, useEffect } from 'react'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import { Flex, SimpleGrid, Box, Text, theme } from '@chakra-ui/react'

import { useAuth } from '../../Hooks/Auth'
import { TilesBox } from '../../Components/Tiles/TilesBox'
import { FiClock, FiUsers } from 'react-icons/fi'
import { MdVideoCall, MdAttachMoney, MdRemoveRedEye, MdOutlineBusinessCenter } from 'react-icons/md'
import api from '../../Services/api'
import { BsNewspaper } from 'react-icons/bs'

interface DataProps {
  users: number
  videos: number
}

const Reports: FC = () => {
  const [data, setData] = useState({} as DataProps)

  useEffect(() => {
    api.get('/overview').then(resp => {
      const dados = resp.data

      setData(dados)
    }).catch(error => console.log('error useEffect AuthProvider: ', error))
  }, [])

  return (
    <Flex direction="column" h="100vh" bg="wine.primary">
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <SimpleGrid
          flex="1"
          gap="4"
          minChildWidth="320px"
          alignItems="flex-start"
        >
          <Box
                // width={200}
                as="a"
                href={'/reports/payments-by-provider'}

                fontSize="sm"

              >
          <TilesBox
            title="Pagamento por Prestador"
            description="Exporte relatórios do quanto foi pago para cada prestador"
            icon={FiUsers}
            value=""
          />
          </Box>

          <Box
                // width={200}
                as="a"
                href={'/reports/payments-by-period'}

                fontSize="sm"

              >
          <TilesBox
            title="Pagamento por Data"
            description="Exporte relatórios do quanto foi pago por período"
            icon={FiClock}
            value=""
          />
          </Box>

        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

export { Reports }
