/* eslint-disable react/react-in-jsx-scope */
import {
  FormControl, FormErrorMessage,
  FormHelperText, FormLabel, Input as ChakraInput, InputGroup, InputLeftAddon, InputProps as ChakraInputProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useCallback, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import InputMask from 'react-input-mask'
import { Input } from './Input'

interface Props extends ChakraInputProps {
  label?: string
  errors?: FieldError
  helperText?: string
  labelStyle?: any
  extraFunction?: (data?: any) => void
}

const BaseMoneyInput: ForwardRefRenderFunction<Props, any> = (
  { label, errors = null, helperText, labelStyle = {}, extraFunction, ...rest },
  ref
) => {
  const handleExtra = useCallback((e: any) => {
    const inputValue = e?.target?.value

    if ((Boolean(inputValue.length)) && (Boolean(extraFunction))) {
      extraFunction(inputValue)
    }
  }, [extraFunction])

  return (
    <FormControl isInvalid={!(errors == null)}>
      {!(label == null) && <FormLabel color="wine.primary" style={labelStyle}>{label}</FormLabel>}

      <InputGroup>

        <NumericFormat

            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            placeholder="0,00"
            getInputRef={ref}
            decimalScale={2}
            fixedDecimalScale
            style={{ width: '100%', height: 45, paddingLeft: 15, color: '#771731', borderRadius: 5, fontSize: 16, fontWeight: 500, borderWidth: 1, borderColor: '#480027' }}
            onKeyUp={(e: any) => handleExtra(e)}

            {...rest}
        />

        </InputGroup>

      {(helperText != null) && (
        <FormHelperText color="gray.500">{helperText}</FormHelperText>
      )}
      {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const MoneyInput = forwardRef(BaseMoneyInput)
