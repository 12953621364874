import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { Can } from '../../Components/Can'

interface Provider {
  id: string
  name: string
  phone: string
  active: 0 | 1
}

const ProvidersList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Provider[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as Provider)
  const [deactivatedItem, setDeactivatedItem] = useState({} as Provider)
  const [activateItem, setActivateItem] = useState({} as Provider)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag
        }
      })

      console.log('data: ', data)

      const normalize = data?.data?.map((i: any) => {
        return {
          ...i,
          phone2: i.phone ? `${i?.phone}`.replace(/([^0-9]+)/g, '') : ''
        }
      })
      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar prestadores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/providers/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as Provider)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as Provider)
    }
  }, [selectedItem, toast, page, load])

  const hanleInactivate = useCallback(async () => {
    const { id } = deactivatedItem
    try {
      await api.patch(`/providers/inactivate?provider_id=${id}`)

      toast({
        title: 'Inativado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setDeactivatedItem({} as Provider)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setDeactivatedItem({} as Provider)
    }
  }, [deactivatedItem, toast, page, load])

  const handleActivate = useCallback(async () => {
    const { id } = activateItem
    try {
      await api.patch('/providers/activate', null, {
        params: {
          provider_id: id
        }
      })

      toast({
        title: 'Ativado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setActivateItem({} as Provider)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setActivateItem({} as Provider)
    }
  }, [activateItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as Provider)
  }, [])

  const closeDeactivationModal = useCallback(() => {
    setDeactivatedItem({} as Provider)
  }, [])

  const closeActivationModal = useCallback(() => {
    setActivateItem({} as Provider)
  }, [])

  const handleFilterList = useCallback(async (e: any) => {
    setLoading(true)
    console.log('e: ', e?.target?.value)
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          name: e?.target?.value
        }
      })

      setPage(1)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Can permissions={['providers.list', 'providers.create', 'providers.all']} page>
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Prestador"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

      {!!deactivatedItem?.id && (
        <AlertDialogCustom
          title="Desativar Prestador"
          isOpen={!!deactivatedItem?.id}
          cancelFunction={closeDeactivationModal}
          nextFunction={hanleInactivate}
        />
      )}

{!!activateItem?.id && (
        <AlertDialogCustom
          title="Ativar Prestador"
          isOpen={!!activateItem?.id}
          cancelFunction={closeActivationModal}
          nextFunction={handleActivate}
        />
)}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
              Prestadores
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            <SearchBox placeholder="Buscar por nome" onChangeFunction={handleFilterList}/>

            <Button
              as="a"
              href="/providers/create"
              size="sm"
              fontSize="sm"
              bg="wine.primary"
              _hover={{ bgColor: 'wine.200' }}
              color="white"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            >
              Criar novo prestador
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de prestadores</Text>
            </Flex>
                )
              : (
            <>

              <Table colorScheme="blue">

                <Thead>
                  <Tr>

                    <Th textAlign="center">Nome</Th>

                    <Th textAlign="center">Fone</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>

                <Tbody>

                  {data.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center">
                            <Text fontWeight="bold">{i.phone}</Text>
                          </Link>
                      </Td>

                      <Td>
                        <Flex>
                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              width={150}
                              colorScheme={i.active === 0 ? 'orange' : 'teal' }
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                              onClick={i.active === 0 ? () => setDeactivatedItem(i) : () => setActivateItem(i)}
                            >
                             {i.active === 0 ? 'Desativar' : 'Ativar'}
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="desativar"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                              )}

{isWideScreen
  ? (
                            <Button
                            href={`/providers/create/${i.id}`}
                              as="a"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="wine.200"
                              colorScheme="wine"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
    )
  : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
    )}

{isWideScreen
  ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
    )
  : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
    )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
                )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { ProvidersList }
