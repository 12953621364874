/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable multiline-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useToast,
  Image
} from '@chakra-ui/react'
import queryString from 'query-string'
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddLine } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Can } from '../../Components/Can'
import { Input } from '../../Components/Form/Input'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { NOTIFICATIONS_TYPE } from '../../Services/contants'
import { getCurrencyValue } from '../../utils/getCurrencyValue'
import { ProposalProps } from '../Proposal/create'
import { RefusePreProposalModal } from '../../Components/RefusePreProposalModal'
import { TextArea } from '../../Components/Form/TextArea'
import { Unity } from '../Unities'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { format } from 'date-fns'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { Document, Page, pdfjs } from 'react-pdf'
import { useAuth } from '../../Hooks/Auth'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
export interface OptionsProps {
  label: string
  value: string
}

interface ItemsSelected {
  id: string
  label: string
  value: string
}

interface ItemsForPaymentsOptions {
  id: string
  options: OptionsProps[]

}

interface ItemProposalProps {
  id: string
  executor_value: string
  executor_id: string
  service_id: string
  installments: string
  ticket_value: string
  expenses_value: string
  executor?: {
    id: string
    name: string
  }
  service?: {
    id: string
    name: string
  }
  executor_value_formatted?: string
  ticket_value_formatted?: string
  expenses_value_formatted?: string
  obs?: string
  release_date?: string
}

interface ItemClausesProps {
  id_control: string
  id: string
  resume: string
  clause: string
}

interface ServicesProps {
  id: string
  cod: string
  name: string
  service: string
  value: string
  installments: number
}

interface SumValuesProps {
  item_id: string
  total: string
}

const PreProposalCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigation = useNavigate()
  const [collaborators, setCollaborators] = useState<OptionsProps[]>([])
  const [technicalManager, setTechnicalManagerSelected] = useState<OptionsProps>()
  const [financialManager, setFinancialManagerSelected] = useState<OptionsProps>()
  const [nets, setNets] = useState<OptionsProps[]>([])
  const [responsibles, setResponsibles] = useState<OptionsProps[]>([])
  const [netSelected, setNetSelected] = useState<OptionsProps>()
  const [responsibleSelected, setResponsibleSelected] = useState<OptionsProps>()
  const [sumValues, setSumValues] = useState<SumValuesProps[]>([])
  const [units, setUnits] = useState<OptionsProps[]>([])
  const [allUnits, setAllUnits] = useState<Unity[]>([])
  const [unitSelected, setUnitSelected] = useState<OptionsProps>()

  const [providers, setProviders] = useState<OptionsProps[]>([])
  const [providerSelected, setProviderSelected] = useState<OptionsProps>()

  const [services, setServices] = useState<OptionsProps[]>([])
  const [serviceSelected, setServiceSelected] = useState<OptionsProps>()

  const [clausesOptions, setClausesOptions] = useState<OptionsProps[]>([])
  const [servicesWithPayments, setServicesWithPayments] = useState<ServicesProps[]>([])

  const [paymentsOptions, setPaymentsOptions] = useState<ItemsForPaymentsOptions[]>([])
  const [allClauses, setAllClauses] = useState<Array<Omit<ItemClausesProps, 'id_control'>>>([])

  // juncao de todos os dados
  const [providersSelected, setProvidersSelected] = useState<ItemsSelected[]>([])
  const [servicesSelected, setServicesSelected] = useState<ItemsSelected[]>([])
  const [paymentsSelected, setPaymentsSelected] = useState<ItemsSelected[]>([])

  const [items, setItems] = useState<ItemProposalProps[]>([])
  const [itemsClauses, setItemsClauses] = useState<ItemClausesProps[]>([])

  const [proposalData, setProposalData] = useState<ProposalProps>({} as ProposalProps)
  const [showValidateUpdateModal, setShowValidateUpdateModal] = useState(false)
  const [selectedApprovement, setSelectedApprovement] = useState(false)
  const [refuseApprovement, setRefuseApprovement] = useState(false)
  const ProposalProviderAttachment = useRef<DragAndDropExportedProps>(null)
  const [initialCoverProposalProviderAttachment, setInitialCoverProposalProviderAttachment] = useState<FilesProps>({} as FilesProps)
  const [files, setFiles] = useState<FilesProps[]>()
  const { user } = useAuth()

  const FormRef = useRef<any>(null)

  const companyOptions = [
    {
      label: 'Projecto',
      value: 'projecto'
    },
    {
      label: 'LDO',
      value: 'ldo'
    }
  ]

  const parsed = queryString.parse(location.search)

  const { register, handleSubmit, formState, setValue, unregister, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('values: ', values)
      console.log('items: ', items)

      const { company } = values

      if (!company) {
        toast({
          title: 'Selecione a empresa Executora',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!technicalManager?.value) {
        toast({
          title: 'Selecione o responsável técnico',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!responsibleSelected?.value) {
        toast({
          title: 'Selecione o responsável na rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!financialManager?.value) {
        toast({
          title: 'Selecione o responsável financeiro',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!netSelected?.value) {
        toast({
          title: 'Selecione a rede',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!unitSelected?.value) {
        toast({
          title: 'Selecione a unidade',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      if (!items?.length) {
        toast({
          title: 'Preencha pelo menos 1 item de proposta',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      const normalizeItems = items.map(i => {
        const existsValue = values[`name-item-executor_value-${i.id}`]
        const existsTicketValue = values[`name-item-ticket_value-${i.id}`]
        const existsExpensesValue = values[`name-item-expenses_value-${i.id}`]
        const obs = values[`name-item-obs-${i.id}`]
        const release_date = values[`name-item-release_date-${i.id}`]

        return {
          executor_value: existsValue ? getCurrencyValue(values[`name-item-executor_value-${i.id}`]) : '',
          executor_id: providersSelected?.find(item => item.id === i.id)?.value ?? '',
          service_id: servicesSelected?.find(item => item.id === i.id)?.value ?? '',
          installments: paymentsSelected?.find(item => item.id === i.id)?.value ?? '',
          ticket_value: existsTicketValue ? getCurrencyValue(values[`name-item-ticket_value-${i.id}`]) : '',
          expenses_value: existsExpensesValue ? getCurrencyValue(values[`name-item-expenses_value-${i.id}`]) : '',
          obs: obs || '',
          release_date: release_date || ''
        }
      })

      console.log('normalizeItems: ', normalizeItems)

      const allValuesFilledUp = normalizeItems.every(i => !!i.executor_value)

      if (!allValuesFilledUp) {
        toast({
          title: 'Preencha o valor do prestador em todos os itens de proposta.',
          position: 'top',
          isClosable: true,
          status: 'warning',
          variant: 'solid'
        })

        return
      }

      const payload = {
        technical_manager: technicalManager?.value,
        financial_manager: financialManager?.value,
        net_id: netSelected?.value,
        unit_id: unitSelected?.value,
        company,
        responsible: responsibleSelected?.value,
        items: normalizeItems,
        clauses: itemsClauses.map(i => i.id)
      }

      let id = ''

      try {
        if (params?.proposal_id) {
          await api.put(`/proposal/${params?.proposal_id}`, payload)
          id = params?.proposal_id
        } else {
          const { data } = await api.post('/proposal', payload)

          id = data?.proposal?.id
        }

        setLoading(true)

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [ProposalProviderAttachment]

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })

                setLoading(false)
                navigation('/pre-proposal')
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${id}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          setLoading(false)
          toast({
            title: `${id ? 'Editado' : 'Cadastrado'} com sucesso`,
            position: 'top',
            isClosable: true,
            status: 'success',
            variant: 'solid'
          })

          navigation('/pre-proposal')
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [toast, items, technicalManager, financialManager, netSelected, unitSelected, paymentsSelected, servicesSelected, providersSelected, navigation, params, responsibleSelected, itemsClauses]
  )

  const loadCollaborators = useCallback(async () => {
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setCollaborators(normalize)
      console.log('carregou loadCollaborators')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadNets = useCallback(async () => {
    try {
      const { data } = await api.get('/nets', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setNets(normalize)
      console.log('carregou nets')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadProviders = useCallback(async () => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          status: 0
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
      console.log('carregou providers')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadServices = useCallback(async () => {
    try {
      const { data } = await api.get('/services', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setServices(normalize)
      setServicesWithPayments(data?.data)
      console.log('carregou services')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectTechnicalManager = useCallback((collaborator_id: string) => {
    const collaborator = collaborators.find(i => i.value === collaborator_id)

    if (collaborator) {
      setTechnicalManagerSelected(collaborator)
    }
  }, [collaborators])

  const handleSelectFinancialManager = useCallback((collaborator_id: string) => {
    const collaborator = collaborators.find(i => i.value === collaborator_id)

    if (collaborator) {
      setFinancialManagerSelected(collaborator)
    }
  }, [collaborators])

  const loadUnits = useCallback(async (net_id: string) => {
    try {
      const { data } = await api.get('/units', {
        params: {
          pag: 1,
          all: true,
          net_id
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: `${i.name_approvation ? `Aprovação: ${i.name_approvation} /` : ''} ${i.name ? `Viabilidade: ${i.name}` : ''}`
        }
      })
      setAllUnits(data?.data)
      setUnits(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectResponsible = useCallback((responsible_id: string) => {
    const resp = responsibles.find(i => i.value === responsible_id)
    setResponsibleSelected({} as OptionsProps)
    if (resp) {
      setResponsibleSelected(resp)
    }
  }, [responsibles])

  const loadContacts = useCallback(async (net_id: string) => {
    try {
      const { data } = await api.get(`/contacts/${net_id}`)
      const normalize = data.map((i: any) => {
        return {
          label: i.name,
          value: i.id
        }
      })

      setResponsibles(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectNet = useCallback((net_id: string) => {
    const net = nets.find(i => i.value === net_id)
    setUnitSelected({} as OptionsProps)
    console.log('net: ', net)
    if (net) {
      setNetSelected(net)
      loadContacts(net.value)
    }
  }, [nets, loadContacts])

  // const handleSelectClause = useCallback((clause_id: string, control_id: string) => {
  //   const clause = allClauses.find(i => i.id === clause_id)

  //   if (clause) {
  //     const exists = itemsClauses.find(p => p.id === clause_id)

  //     if (exists) {
  //       toast({
  //         title: 'Você já cadastrou essa cláusula',
  //         position: 'top',
  //         isClosable: true,
  //         status: 'error',
  //         variant: 'solid'
  //       })
  //     } else {
  //       const filterItemsClauses = itemsClauses.filter(p => p.id_control !== control_id)

  //       const payload = {
  //         id_control: control_id,
  //         id: clause_id,
  //         resume: clause.resume,
  //         clause: clause.clause

  //       }
  //       setItemsClauses([...filterItemsClauses, payload])
  //     }
  //   }
  // }, [allClauses, itemsClauses, toast])

  const handleSelectUnit = useCallback((unit_id: string) => {
    const unit = units.find(i => i.value === unit_id)

    if (unit) {
      setUnitSelected(unit)
    }
  }, [units])

  useEffect(() => {
    if (netSelected?.value) {
      loadUnits(netSelected?.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netSelected])

  const handleSelectProvider = useCallback((provider_id: string, item_id: string) => {
    const provider = providers.find(i => i.value === provider_id)

    if (provider) {
      // setProviderSelected(provider)

      const filtered = providersSelected.filter(p => p.id !== item_id)

      const payload = {
        id: item_id,
        label: provider.label,
        value: provider.value

      }
      setProvidersSelected([...filtered, payload])
    }
  }, [providers, providersSelected])

  const handleSelectService = useCallback((service_id: string, item_id: string) => {
    const service = services.find(i => i.value === service_id)

    if (service) {
      // setServiceSelected(service)
      const filtered = servicesSelected.filter(p => p.id !== item_id)

      const payload = {
        id: item_id,
        label: service.label,
        value: service.value

      }
      setServicesSelected([...filtered, payload])
    }

    // alterando as opções de pagamentos

    // pegando todas as opcoes deste servico
    const serviceWithAllPayments = servicesWithPayments.find(i => i.id === service_id)

    if (serviceWithAllPayments) {
      // normatizando os dados para o select
      const normalize = []

      for (let i = 1; i <= serviceWithAllPayments.installments; i++) {
        normalize.push({
          value: String(i),
          label: String(i)
        })
      }

      const payload = {
        id: item_id,
        options: normalize
      }

      const filtered = paymentsOptions.filter(p => p.id !== item_id)

      setPaymentsOptions([...filtered, payload])
    }
  }, [services, servicesWithPayments, servicesSelected, paymentsOptions])

  const handleSelectPayment = useCallback((payment_id: string, item_id: string) => {
    const getPayments = paymentsOptions.find(i => i.id === item_id)

    if (getPayments) {
      // setProviderSelected(provider)
      const payment = getPayments.options.find(i => i.value === payment_id)

      if (payment) {
        const filtered = paymentsSelected.filter(p => p.id !== item_id)

        const payload = {
          id: item_id,
          label: payment.label,
          value: payment.value

        }
        setPaymentsSelected([...filtered, payload])
      }
    }
  }, [paymentsSelected, paymentsOptions])

  const handleNewItem = useCallback((initialValues: any) => {
    if (initialValues?.id) {
      const find = items.find(i => i.id === initialValues.id)
      console.log('find: ', find)
      if (find) {
        return
      }
    }
    const payload = {
      id: initialValues?.id || uuid(),
      executor_value: initialValues?.executor_value || '',
      executor_id: initialValues?.executor_id || '',
      service_id: initialValues?.service_id || '',
      installments: initialValues?.installments || '',
      ticket_value: initialValues?.ticket_value || '',
      expenses_value: initialValues?.expenses_value || '',
      obs: initialValues?.obs || '',
      release_date: initialValues?.release_date ? format(new Date(initialValues?.release_date), 'dd/MM/yyyy') : ''

    }
    // setItems(prev => [...prev, payload])

    setItems(prev => {
      const current = [...prev, payload].filter(item => item)

      const ids = new Set() // Conjunto para armazenar IDs únicos
      return current.filter((item) => {
        // Se o ID ainda não estiver no conjunto, adiciona e mantém o item
        if (!ids.has(item.id)) {
          ids.add(item.id)
          return true
        }
        // Se o ID já estiver no conjunto, descarta o item
        return false
      })
    })

    if (initialValues?.id) {
      const initialExecutorValue = Number(initialValues?.executor_value) || 0
      const initialTicketValue = Number(initialValues?.ticket_value) || 0
      const initialExpensesValue = Number(initialValues?.expenses_value) || 0
      const total = initialExecutorValue + initialTicketValue + initialExpensesValue
      setSumValues(prev => [...prev, { item_id: payload.id, total: String(total.toFixed(2)) }])
    } else {
      setSumValues(prev => [...prev, { item_id: payload.id, total: '0.00' }])
    }

    if (initialValues?.id) {
      handleSelectService(initialValues?.service_id, initialValues?.id)
    }
  }, [items, handleSelectService])

  const handleDeleteItemOption = useCallback((id: string) => {
    const newArray = items.filter(i => i.id !== id)
    unregister(`name-item-${id}`)
    setItems([...newArray])
  }, [items, unregister])

  const handleLoadProposalItems = useCallback(async (items: any) => {
    try {
      await Promise.all(items?.map(async (item: any) => {
        setProvidersSelected(prev => [...prev, {
          id: item?.id,
          label: item?.executor?.name,
          value: item?.executor?.id
        }])

        setServicesSelected(prev => [...prev, {
          id: item?.id,
          label: item?.service?.name,
          value: item?.service?.id
        }])

        setPaymentsSelected(prev => [...prev, {
          id: item?.id,
          label: item?.installments,
          value: item?.installments
        }])

        handleNewItem(item)
      }))
    } catch (error) {
      console.log('error handleLoadProposalItems: ', error)
    }
  }, [handleNewItem])

  const handleSubmitProposalAttachment = useCallback(async () => {
    try {
      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [ProposalProviderAttachment]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      setLoading(true)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Proposta anexada com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })

              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${params?.proposal_id}`, cb)
      }))
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })

      setLoading(false)
    }
  }, [toast, params])

  const loadItem = useCallback(async () => {
    if (!params?.proposal_id) return

    const proposal_id = params?.proposal_id
    try {
      const { data } = await api.get(`/proposal/${proposal_id}`)

      const findProposalProviderAttachment = data?.files?.find((i: FilesProps) => i.type === 'proposta-prestador')
      console.log('findProposalProviderAttachment: ', findProposalProviderAttachment)
      if (findProposalProviderAttachment) {
        setInitialCoverProposalProviderAttachment(findProposalProviderAttachment)
      }

      setFiles(data.files)

      const normalizeData = {
        ...data,
        items: data?.items?.map((i: any) => {
          return {
            ...i,
            release_date: i?.release_date ? format(new Date(i?.release_date), 'dd/MM/yyyy') : 'Não informado'
          }
        })
      }

      setProposalData(normalizeData)

      if (data?.technical?.id) {
        setTechnicalManagerSelected({ label: data?.technical?.name, value: data?.technical?.id })
      }

      if (data?.financial?.id) {
        setFinancialManagerSelected({ label: data?.financial?.name, value: data?.financial?.id })
      }

      if (data?.net?.id) {
        setNetSelected({ label: data?.net?.name, value: data?.net?.id })
      }

      if (data?.unit?.id) {
        setUnitSelected({
          value: data?.unit?.id,
          label: `${data?.unit?.name_approvation ? `Aprovação: ${data?.unit?.name_approvation} /` : ''} ${data?.unit?.name ? `Viabilidade: ${data?.unit?.name}` : ''}`
        })
      }

      if (data?.contact?.id) {
        setResponsibleSelected({
          value: data?.contact?.id,
          label: data?.contact?.name
        })
      }

      if (data?.company) {
        setValue('company', data.company)
      }

      handleLoadProposalItems(data?.items)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, handleLoadProposalItems, params])

  const loadClauses = useCallback(async () => {
    try {
      const { data } = await api.get('/clauses', {
        params: {
          pag: 1,
          all: true
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.clause
        }
      })

      setAllClauses(data?.data)
      setClausesOptions(normalize)
      console.log('carregou clauses')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const loadAllContent = useCallback(async () => {
    await Promise.all([loadCollaborators(), loadNets(), loadProviders(), loadServices(), loadClauses()]).then(() => {
      if (params.proposal_id) {
        loadItem()
      }
    })
    // await loadCollaborators()
    // await loadNets()
    // await loadProviders()
    // await loadServices()
    // await loadClauses()
  }, [loadCollaborators, loadNets, loadProviders, loadServices, loadClauses, params.proposal_id])

  useEffect(() => {
    loadAllContent()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   loadItem()
  // }, [])
  const handleSearchProviders = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/providers', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setProviders(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSearchCollaborator = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/collaborators', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setCollaborators(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSearchNets = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/nets', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setNets(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSearchContacts = useCallback(async (e: string) => {
    try {
      const { data } = await api.get(`/contacts/${String(netSelected?.value)}`, {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setResponsibles([...normalize])
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [netSelected, toast])

  const handleSearchUnits = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/units', {
        params: {
          pag: 1,
          all: true,
          net_id: netSelected?.value,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: `${i.name_approvation ? `Aprovação: ${i.name_approvation} /` : ''} ${i.name ? `Viabilidade: ${i.name}` : ''}`
        }
      })
      setAllUnits(data?.data)
      setUnits([...normalize])
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, netSelected])

  const handleSearchServices = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/services', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setServices(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleApproveProposal = useCallback(async () => {
    try {
      setLoading(true)
      await api.patch(`/proposal-approvement/${params?.proposal_id}`, {
        approved: 'yes'
      })

      toast({
        title: 'Pré-proposta aprovada',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })

      setSelectedApprovement(false)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, params])

  const updateNotificationStatus = useCallback(async (notification_id: string) => {
    try {
      await api.patch(`/notifications/${notification_id}`)
      console.log('notificação alterada')
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast])

  useEffect(() => {
    if (parsed?.notification_id) {
      updateNotificationStatus(String(parsed?.notification_id))
    }
  }, [parsed])

  const handleRefuseProposal = useCallback(async (message?: string) => {
    try {
      setLoading(true)
      await api.patch(`/proposal-approvement/${params?.proposal_id}`, {
        approved: 'no',
        message
      })

      toast({
        title: 'Pré-proposta recusada',
        position: 'top',
        isClosable: true,
        status: 'info',
        variant: 'solid'
      })

      setRefuseApprovement(false)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, params])

  const isApprovation = useMemo(() => {
    return !!(parsed?.action === NOTIFICATIONS_TYPE['pre-proposal-approvement-boss-sector'] || parsed?.action === NOTIFICATIONS_TYPE['pre-proposal-updated-boss-sector'])
  }, [parsed])

  const allDataUnitySelected = useMemo(() => {
    return allUnits?.find(i => i.id === unitSelected?.value) || {} as Unity
  }, [allUnits, unitSelected])

  const handleCalculateTotalItem = useCallback((item: any, type: 'executor_value' | 'ticket_value' | 'expenses_value', value: any) => {
    const values = getValues()
    const existsExecutorValue = values[`name-item-executor_value-${item.id}`]
    const existsTicketValue = values[`name-item-ticket_value-${item.id}`]
    const existsExpensesValue = values[`name-item-expenses_value-${item.id}`]
    const executorValue: number = existsExecutorValue ? Number(getCurrencyValue(values[`name-item-executor_value-${item.id}`])) : 0
    const ticketValue: number = existsTicketValue ? Number(getCurrencyValue(values[`name-item-ticket_value-${item.id}`])) : 0
    const expensesValue: number = existsExpensesValue ? Number(getCurrencyValue(values[`name-item-expenses_value-${item.id}`])) : 0
    const getValue = Number(value?.value)
    let normalizeSum = 0

    if (type === 'executor_value') {
      normalizeSum = getValue + ticketValue + expensesValue
    }

    if (type === 'ticket_value') {
      normalizeSum = getValue + executorValue + expensesValue
    }

    if (type === 'expenses_value') {
      normalizeSum = getValue + executorValue + ticketValue
    }

    console.log('normalizeSum: ', normalizeSum)

    const filteredWithoutItem = sumValues?.filter(i => i.item_id !== item.id)

    const newItems = [...filteredWithoutItem, { item_id: item.id, total: normalizeSum.toFixed(2) }]

    setSumValues([...newItems])
  }, [getValues, sumValues])

  const totalProposal = useMemo(() => {
    return sumValues?.length ? sumValues?.reduce((acc: number, current: SumValuesProps) => {
      return acc + Number(current.total)
    }, 0) : 0
  }, [sumValues])

  const TotalValue = () => (
    <Text color="wine.200" fontSize="19" fontWeight={700} align="left">{`Total R$ ${totalProposal.toFixed(2)}`}</Text>
  )

  const sameDepartment = useMemo(() => {
    return String(proposalData.collaborator_department) === String(user.department)
  }, [proposalData, user])

  const isBoss = useMemo(() => {
    return user.boss === 1
  }, [user])

  return (
    <Can permissions={['pre_proposal.create', 'pre_proposal.all']}>
    <Box>
      <Header />
      {loading && <Loader />}

      {!!selectedApprovement && (
        <AlertDialogCustom
          title="Você tem certeza?"
          isOpen={!!selectedApprovement}
          cancelFunction={() => setSelectedApprovement(false)}
          nextFunction={handleApproveProposal}

        />
      )}

    {!!refuseApprovement && (
        <RefusePreProposalModal
          title="Recusar Pré-proposta"
          isOpen={!!refuseApprovement}
          cancelFunction={() => setRefuseApprovement(false)}
          nextFunction={handleRefuseProposal}

        />
    )}

      {showValidateUpdateModal && (
        <AlertDialogCustom
          title="Atualizar Proposta"
          description='Você tem certeza que deseja alterar esta proposta? O chefe do seu setor precisará aprová-la novamente e o financeiro precisará revisar novamente os valores de todos os itens.'
          isOpen={showValidateUpdateModal}
          cancelFunction={() => setShowValidateUpdateModal(false)}
          nextFunction={async () => await handleSubmit(handleSubmitForm)()}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          ref={FormRef}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            {params?.proposal_id ? 'Atualizar Pré-proposta' : 'Criar nova Pré-Proposta' }
           {proposalData?.number && <Text fontSize="13px" fontWeight="bold">{proposalData?.number}</Text>}
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

            {!isApprovation && (
                  <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Select
                       label="Empresa Executora"
                       placeholder="Selecionar Empresa"
                       errors={errors.company}
                       {...register('company')}
                       options={companyOptions}
                       />
                  </SimpleGrid>
            )}

          <Divider />

            {isApprovation
              ? (
                <>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  {technicalManager?.value && (
                      <Box>
                        <Text color="wine.primary" fontWeight={500} align="center">Empresa Executora</Text>
                        <Text align="center">{proposalData?.company}</Text>
                      </Box>
                  )}

                  {technicalManager?.value && (
                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                      <Text align="center">{technicalManager.label}</Text>
                    </Box>
                  )}

                  {financialManager?.value && (
                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                      <Text align="center">{financialManager.label}</Text>
                    </Box>
                  )}

                  {netSelected?.value && (
                  <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                    <Text align="center">{netSelected.label}</Text>
                  </Box>
                  )}

                </SimpleGrid>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                {responsibleSelected?.value && (
                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável na Rede</Text>
                      <Text align="center">{responsibleSelected.label}</Text>
                    </Box>
                )}

                {unitSelected?.value && (
                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                  <Text align="center">{unitSelected.label}</Text>
                </Box>
                )}

                {allDataUnitySelected?.id && (
                                <>
                                  <Box>
                                    <Text color="wine.primary" fontWeight={500} align="center">Área Total</Text>
                                    <Text align="center">{allDataUnitySelected?.immobile?.area_ground || '--'}</Text>
                                  </Box>

                                  <Box>
                                    <Text color="wine.primary" fontWeight={500} align="center">Área Construída</Text>
                                    <Text align="center">{allDataUnitySelected?.immobile?.area_building || '--'}</Text>
                                  </Box>
                                </>
                )}

                </SimpleGrid>
                </>

                )
              : (
              <>
              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <InputModalSearch title="Responsável Técnico" searchFunction={handleSearchCollaborator} data={collaborators} extraFunction={handleSelectTechnicalManager} placeholderButton={technicalManager?.value ? 'Trocar Responsável' : 'Selecionar Responsável'} />

              {technicalManager?.value && (
                <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                <Text align="center">{technicalManager.label}</Text>
              </Box>
              )}

          </SimpleGrid>

          <Divider />

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <InputModalSearch title="Responsável Financeiro" searchFunction={handleSearchCollaborator} data={collaborators} extraFunction={handleSelectFinancialManager} placeholderButton={financialManager?.value ? 'Trocar Responsável' : 'Selecionar Responsável'} />

              {financialManager?.value && (
                <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                <Text align="center">{financialManager.label}</Text>
              </Box>
              )}

          </SimpleGrid>

          <Divider />

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
          <InputModalSearch title="Rede" searchFunction={handleSearchNets} data={nets} extraFunction={handleSelectNet} placeholderButton={netSelected?.value ? 'Trocar Rede' : 'Selecionar Rede'} />

              {netSelected?.value && (
                <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                <Text align="center">{netSelected.label}</Text>
              </Box>
              )}

          </SimpleGrid>

          <Divider />

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <InputModalSearch title="Responsável na Rede" searchFunction={handleSearchContacts} data={responsibles} extraFunction={handleSelectResponsible} placeholderButton={responsibleSelected?.value ? 'Trocar Responsável' : 'Selecionar Responsável'} />

              {responsibleSelected?.value && (
                <Box>
                <Text color="wine.primary" fontWeight={500} align="center">Responsável na Rede</Text>
                <Text align="center">{responsibleSelected.label}</Text>
              </Box>
              )}

          </SimpleGrid>

          <Divider />

          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
          <InputModalSearch title="Unidade" searchFunction={handleSearchUnits} data={units} extraFunction={handleSelectUnit} placeholderButton={unitSelected?.value ? 'Trocar Unidade' : 'Selecionar Unidade'} />
          {allDataUnitySelected?.id && (
                <>
                  <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Área Total</Text>
                    <Text align="center">{allDataUnitySelected?.immobile?.area_ground}</Text>
                  </Box>

                  <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Área Construída</Text>
                    <Text align="center">{allDataUnitySelected?.immobile?.area_building}</Text>
                  </Box>
                </>
          )}
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            {unitSelected?.value && (
            <Box >
            <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
            <Text align="center">{unitSelected.label}</Text>
          </Box>
            )}

          </SimpleGrid>

          <Divider />
              </>
                )}

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
          Itens da Proposta
          </Heading>

          {!items?.length && <Text mb="10" color="wine.primary">Você ainda não possui nenhum item de proposta cadastrado.</Text>}

          {isApprovation && proposalData?.items?.map(item => (
               <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
               <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

               <Box>
               <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
               <Text align="center" color="white.primary">{item?.executor?.name || 'Executante Removido'} </Text>
               </Box>

               <Box>
               <Text color="white.primary" fontWeight={500} align="center">Serviço</Text>
               <Text align="center" color="white.primary">{item?.service?.name || 'Serviço não encontrado'}</Text>
               </Box>

               <Box>
               <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
               <Text align="center" color="white.primary">{item?.installments}x</Text>
               </Box>

               </SimpleGrid>

               <Divider color="white" />

              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                  <Box>
                    <Text color="white.primary" fontWeight={500} align="center">Valor do Prestador</Text>
                    <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                  </Box>

                  <Box>
                    <Text color="white.primary" fontWeight={500} align="center">Locomoção/Passagem</Text>
                    <Text align="center" color="white.primary">{item?.ticket_value_formatted}</Text>
                  </Box>

                  <Box>
                    <Text color="white.primary" fontWeight={500} align="center">Despesas</Text>
                    <Text align="center" color="white.primary">{item?.expenses_value_formatted}</Text>
                  </Box>

                  <Box>
                    <Text color="white.primary" fontWeight={500} align="center">Previsão de Entrega</Text>
                    <Text align="center" color="white.primary">{item?.release_date}</Text>
                  </Box>
              </SimpleGrid>
                    <Divider />

                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                      <Box>
                            <Text color="white.primary" fontWeight={500} align="left">Observações</Text>
                            <Text align="left" color="white.primary">{item?.obs || '--'}</Text>
                          </Box>
                      </SimpleGrid>
                    <Divider />

                    <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                    <Text color="white.primary" fontSize="16" fontWeight={500} align="left">{`Valor total do item: R$ ${sumValues?.find(i => i.item_id === item.id)?.total}`}</Text>
                    </SimpleGrid>

               </VStack>
          ))}

          {!isApprovation && items.map((item) => (
           <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
           <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

           <InputModalSearch title="Executante" searchFunction={handleSearchProviders} data={providers} extraFunction={(e: any) => handleSelectProvider(e, item.id)} placeholderButton={providersSelected?.find(i => i.id === item.id) ? 'Trocar Executante' : 'Selecionar Executante'} labelStyle={{ color: 'white' }} btnStyle={{ backgroundColor: '#F765a3' }} />
           <Input {...register(`name-item-executor_id-${item.id}`)} value={providersSelected?.find(i => i.id === item.id)?.id} isReadOnly visibility="hidden" />

               {providersSelected?.find(i => i.id === item.id) && (
                 <Box>
                 <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                 <Text align="center" color="white.primary">{providersSelected?.find(i => i.id === item.id)?.label}</Text>
               </Box>
               )}

           </SimpleGrid>

           <Divider color="white" />

           <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
               <InputModalSearch title="Serviço" searchFunction={handleSearchServices} data={services} extraFunction={(e: any) => handleSelectService(e, item.id)} placeholderButton={servicesSelected?.find(i => i.id === item.id) ? 'Trocar Serviço' : 'Selecionar Serviço'} labelStyle={{ color: 'white' }} btnStyle={{ backgroundColor: '#F765a3' }} />
               <Input {...register(`name-item-service_id-${item.id}`)} value={servicesSelected?.find(i => i.id === item.id)?.id} isReadOnly visibility="hidden" />

               {servicesSelected?.find(i => i.id === item.id) && (
                 <Box>
                 <Text color="white.primary" fontWeight={500} align="center">Serviço</Text>
                 <Text align="center" color="white.primary">{servicesSelected?.find(i => i.id === item.id)?.label}</Text>
               </Box>
               )}

           </SimpleGrid>

           <Divider color="white" />

           <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
             <InputModalSearch title="Parcelamento do Serviço" data={paymentsOptions?.find(i => i.id ===
                    item.id)?.options ?? []} extraFunction={(e: any) => handleSelectPayment(e, item.id)} placeholderButton={paymentsSelected?.find(i => i.id === item.id) ? 'Trocar Parcelamento do Serviço' : 'Selecionar Parcelamento do Serviço'} labelStyle={{ color: 'white' }} btnStyle={{ backgroundColor: '#F765a3' }} />
               <Input {...register(`name-item-installments-${item.id}`)} value={paymentsSelected?.find(i => i.id === item.id)?.id} isReadOnly visibility="hidden" />

               {paymentsSelected?.find(i => i.id === item.id) && (
                 <Box>
                 <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                 <Text align="center" color="white.primary">{paymentsSelected?.find(i => i.id === item.id)?.label}x</Text>
               </Box>
               )}

           </SimpleGrid>

           <Divider color="white" />

           <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

           <MoneyInput
           labelStyle={{ color: '#fff' }}
           label="Valor do Prestador"
           placeholder="Preencha o valor"
           // errors={errors.name}
           defaultValue={item?.executor_value ? String(Number(item?.executor_value).toFixed(0)) : ''}
           onValueChange={(e: any) => handleCalculateTotalItem(item, 'executor_value', e)}
           {...register(`name-item-executor_value-${item.id}`)}

           />

           <MoneyInput
           labelStyle={{ color: '#fff' }}
           label="Locomoção/Passagem"
           placeholder="Preencha o valor"
           // errors={errors.name}
           onValueChange={(e: any) => handleCalculateTotalItem(item, 'ticket_value', e)}
           defaultValue={item?.ticket_value ? String(Number(item?.ticket_value).toFixed(0)) : ''}
           {...register(`name-item-ticket_value-${item.id}`)}
           />

           <MoneyInput
           labelStyle={{ color: '#fff' }}
           label="Despesas"
           placeholder="Preencha o valor"
           // errors={errors.name}
           onValueChange={(e: any) => handleCalculateTotalItem(item, 'expenses_value', e)}
           defaultValue={item?.expenses_value ? String(Number(item?.expenses_value).toFixed(0)) : ''}
           {...register(`name-item-expenses_value-${item.id}`)}
           />

            <InputMaskCustom
              label="Previsão de Entrega"
              type="text"
              labelStyle={{ color: '#fff' }}
                defaultValue={item?.release_date}
              // errors={errors.date_init}
              {...register(`name-item-release_date-${item.id}`)}
              placeholder="Digite a previsão de entrega"
              mask="99/99/9999"
            />

         </SimpleGrid>

         <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
            <TextArea label='Observações' {...register(`name-item-obs-${item.id}`)} labelStyle={{ color: '#fff' }} style={{ background: '#fff', color: 'wine.200', minHeight: 200 }} >{item?.obs}</TextArea>
          </SimpleGrid>

         <Divider />

         <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
         <Text color="white.primary" fontSize="21" fontWeight={500} align="left">{`Valor total do item: R$ ${sumValues?.find(i => i.item_id === item.id)?.total}`}</Text>

         <Button w="250px"

                   as="button"
                   size="sm"
                   fontSize="md"
                   ml="auto"
                   bg="teal"
                   height="47px"
                   _hover={{ bgColor: 'gray.500' }}
                   color="white"
                   leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                   onClick={() => handleDeleteItemOption(item.id)}
                   >
                     Excluir
                 </Button>

         </SimpleGrid>

       </VStack>
          ))}

          {!isApprovation && (
            <Button
            as="button"
            size="lg"
            fontSize="sm"
            bg="wine.primary"
            mb="10"
            _hover={{ bgColor: 'wine.200' }}
            color="white"
            leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            onClick={handleNewItem}
            >
            Adicionar Item
          </Button>
          )}

          <Divider />

           {parsed?.action !== NOTIFICATIONS_TYPE['pre-proposal-approvement-boss-sector'] && (
              <>
                  <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                    Cláusulas da Proposta
                  </Heading>

                  {!proposalData?.clauses?.length && <Text mb="10" color="wine.primary">Você não possui nenhuma cláusula cadastrada.</Text>}

                  {proposalData?.clauses?.map((item) => (
                      <VStack spacing={['9', '9']} mb="5" bg="wine.primary" p="10" key={item.id} borderRadius="8px">
                          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                                <Box>
                                <Text color="white.primary" fontWeight={500} align="left">Cláusula</Text>
                                <Text align="left" color="white.primary">{item?.clause || 'Cláusula não encontrado.'}</Text>
                              </Box>

                          </SimpleGrid>

                      </VStack>
                  ))}
              </>
           )}

          <Divider />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
          Anexos
          </Heading>

          <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

            {isApprovation ? (
              <>
                 {files?.find(i => i.type === 'proposta-prestador')?.extension === 'pdf'
                   ? (
                      <a href={initialCoverProposalProviderAttachment?.link} target="_blank" rel="noreferrer">
                        <Text color="wine.200" mb="10px">Proposta do Prestador</Text>
                        <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                          <Document file={initialCoverProposalProviderAttachment?.link}>
                            <Page pageNumber={1} height={200} width={200} />
                          </Document>
                        </div>
                      </a>
                     )
                   : <Box w={250}>
                    <Text color="wine.200" mb="10px">Proposta do Prestador</Text>
                        <a href={initialCoverProposalProviderAttachment?.link} target="_blank" rel="noreferrer">
                        <Image src={initialCoverProposalProviderAttachment?.link} width={250} mb={8}/></a>
                      </Box>}
              </>
            ) : (
              <DragAndDrop
                ref={ProposalProviderAttachment}
                label="Proposta do Prestador"

                payload={{
                  name: 'Proposta do Prestador',
                  type: 'proposta-prestador'
                }}
                defaultCover={initialCoverProposalProviderAttachment?.link}
                id={files?.length ? files.find(i => i.type === 'proposta-prestador')?.id : null}
                saveFunction={initialCoverProposalProviderAttachment?.id ? undefined : async () => await handleSubmitProposalAttachment()}
                extraCleanFunction={() => setInitialCoverProposalProviderAttachment({} as FilesProps)}
              />
            )}

        <Box />
          </SimpleGrid>

          <Divider style={{ marginTop: 20 }} />

          {isApprovation && (
              <Flex mt={['6', '8']} justify="space-between">
                <TotalValue />
                  <HStack>

                    <>
                      <Button
                        type="button"
                        bg="wine.primary"
                        _hover={{ bgColor: 'gray.500' }}
                        color="white"
                        isLoading={formState.isSubmitting}
                        onClick={() => setSelectedApprovement(true)}
                      >
                        Aprovar Pré-proposta
                      </Button>

                      <Button
                        type="button"
                        bg="teal"
                        _hover={{ bgColor: 'gray.500' }}
                        color="white"
                        isLoading={formState.isSubmitting}
                        onClick={() => setRefuseApprovement(true)}
                      >
                        Recusar Pré-proposta
                      </Button>
                    </>

                </HStack>
              </Flex>
          )}

          {!isApprovation && (
          <Flex mt={['6', '8']} justify="space-between">
             <TotalValue />
          <HStack>
            <Button
              as="a"
              bg="pink.500"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              href="/pre-proposal"
            >
              Cancelar
            </Button>

            <Button
              type="button"
              onClick={async () => params?.proposal_id ? setShowValidateUpdateModal(true) : await handleSubmit(handleSubmitForm)()}
              bg="wine.primary"
              _hover={{ bgColor: 'gray.500' }}
              color="white"
              isLoading={formState.isSubmitting}
            >
              {params?.proposal_id ? 'Editar' : 'Salvar'}
            </Button>
          </HStack>
          </Flex>
          )}

        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { PreProposalCreate }
